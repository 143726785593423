const PngIcons = {
    walletlyLoader     : '/images/walletly.loader.gif',
    example            : '/assets/img/example.png',
    ManyChat           : '/images/growthTool/manyChat.png',
    Silferbot          : '/images/growthTool/silferBot.png',
    Profile            : '/images/profile.png',
    Home               : '/images/Home.png',
    line1              : '/images/growthTool/line1.png',
    ActiveChat         : '/images/growthTool/activeChat.png',
    ChatFuel           : '/images/growthTool/chatfuel.png',
    QrCodeGrowth       : '/images/growthTool/qrImg.png',
    TwilioLarge        : '/images/growthTool/twilioL.png',
    TwilioLogo         : '/images/growthTool/twilioLogo.png',
    DottedLine         : '/images/growthTool/dottedline.png',
    Twilio             : '/images/growthTool/twilio.png',
    ManyChatLarge      : '/images/growthTool/manyChatL.png',
    Barcode            : '/images/walletscan/barcode.png',
    EmailVerify        : '/images/auth/emailVerify.png',
    Sidebarbackground  : '/images/auth/sidebarbackground',
    WalletlyShadedLogo : '/images/auth/Walletlyshadedlogo',
    ColorIcon          : '/images/auth/colorIcon',

    CardBuild      : '/images/Build.png',
    CardEmail      : '/images/Email.png',
    CardGrowth     : '/images/GrowthTool.png',
    CardRedemption : '/images/Redemption.png',
    SampleImg      : '/images/sampleImg.png',
    SampleImg2     : '/images/sampleImg2.png',
    SampleImg3     : '/images/sampleImg3.png',

    NotfoundImg    : '/images/notfound.png',

    //loyalty program icons
    spendingIcon   : '/images/loyalty/spending.png',
    starIcon       : '/images/loyalty/star.png',
    smileIcon      : '/images/loyalty/smile.png',
    keyIcon        : '/images/loyalty/key.png',
    birthdayIcon   : '/images/loyalty/birthday.png',
    dollarCashIcon : '/images/loyalty/dollarcash.png',
    hundredIcon    : '/images/loyalty/hundred.png',
    deilveryBoxIcon: '/images/loyalty/deliverybox.png',
    goldMedalIcon  : '/images/loyalty/goldmedal.png',
    silverMedalIcon  : '/images/loyalty/silvermedal.png',
    bronzeMedalIcon  : '/images/loyalty/bronzemedal.png',


    //loyalty sidebaricons for formik values beside accordians

    spendingsidebarIcon     : '/images/loyalty/spendingsidebar.png',
    smssidebarIcon          : '/images/loyalty/smssidebar.png',
    emailsidebarIcon        : '/images/loyalty/emailsidebar.png',
    notificationsidebarIcon : '/images/loyalty/notificationsidebar.png',
    chatFlowSidebarIcon     : '/images/loyalty/chatFlowSidebar.png',
    
    
    //Referral Create new program Icons i.e Reward Page 
    MornginBrewIcon : '/images/referral/morningbrew.png',
    AirBnbIcon      : '/images/referral/airbnb.png',
    CoinBaseIcon    : '/images/referral/coinbase.png',
    DropBoxIcon     : '/images/referral/dropbox.png',
    EventBriteIcon  : '/images/referral/eventbrite.png',
    GoogleIcon      : '/images/referral/google.png',
    UberIcon        : '/images/referral/uber.png',


    //designicons 
    StarBucksIcon      : '/images/design/starbuckslogo.png',
    StarBucksCoverIcon : '/images/design/starbuckcover.png',
    SeaFoodIcon        : '/images/design/seafoodlogo.png',
    SeaFoodCoverIcon   : '/images/design/seafoodcover.png',
    SmokeGrillLogo     : '/images/design/smokegrilllogo.png',
    SmokeGrillCoverIcon: '/images/design/smokegrillcover.png',
    DonutLogoIcon      : '/images/design/donutslogo.png',
    DonutCoverIcon     : '/images/design/donutscover.png',
    BurgerParkIcon     : '/images/design/burgerparklogo.png',
    BurgerParkCoverIcon: '/images/design/burgerparkcover.png',
    DesignQrCode       : '/images/design/qrcode.png',
    ApplePassIcon      : '/images/design/applepass.png',
    GooglePassIcon     : '/images/design/googlepass.png',
    BeastyPassIcon     : '/images/design/walletbank.png',

    //IntegrationIcons
    ShopifyIcon     : '/images/setting/shopify.png',
    WoocommerceIcon : '/images/setting/woocommerce.png',
    ActivechatIcon  : '/images/setting/activechat.png',
    ChatfuelIcon    : '/images/setting/chatfuel.png',


    //CommunicationIcons
    GraphIcon       : '/images/demograph.png',


    //tempraryIcons 
    WebsiteTemplateIcon: '/images/websiteTemplate.png',


    //growthtoolIcons
    SmallIcons: {
        SilferbotIcon  : '/images/growthTool/smallicons/silferboticon.png',
        QrcodeIcon     : '/images/growthTool/smallicons/qrcode.png',
        LandingpageIcon: '/images/growthTool/smallicons/landingpage.png',
        ApiIcon        : '/images/growthTool/smallicons/api.png',
        ActiveChatIcon : '/images/growthTool/smallicons/activechat.png',
        TwilioIcon     : '/images/growthTool/smallicons/twilio.png',
        WidgetIcon     : '/images/growthTool/smallicons/widget.png',
        DashboardIcon  : '/images/growthTool/smallicons/dashboard.png',
        ManyChatIcon   : '/images/growthTool/smallicons/manychat.png',
        ChatFuelIcon   : '/images/growthTool/smallicons/chatfuel.png',
        WalletlyIcon   : '/images/growthTool/smallicons/walletly.png',
    },
    largetIcons:{
        ApiIcon        : '/images/growthTool/api.png',
        DashboardIcon  : '/images/growthTool/dashboard.png',
        WidgetIcon     : '/images/growthTool/widget.png',
        LandingpageIcon: '/images/growthTool/landingpage.png',
        LandingpageIcon: '/images/growthTool/landingpage.png',
        ChatFuelIcon   : '/images/growthTool/chatfuelgrowth.png',
        QrCodeIcon     : '/images/growthTool/qrgrowth.png',
        ActiveChatIcon : '/images/growthTool/activechatgrowth.png',
        QrLargeIcon    : '/images/growthTool/QrBigImage.png',
        PreviewQrImage : '/images/growthTool/previewqrimage.png',
        DemoUserIcon   : '/images/growthTool/demouser.png',
        BlockIcon      : '/images/growthTool/blockIcon.png',
        ButtonIcon     : '/images/growthTool/buttonIcon.png',
        DividerIcon    : '/images/growthTool/dividerIcon.png',
        FormIcon       : '/images/growthTool/formIcon.png',
        HeadingIcon    : '/images/growthTool/headingIcon.png',
        ImageIcon      : '/images/growthTool/imageIcon.png',
        InputIcon      : '/images/growthTool/inputIcon.png',
        LinkIcon       : '/images/growthTool/linkIcon.png',
        ParagraphIcon  : '/images/growthTool/paragraphIcon.png',
    },
    Squares : '/images/growthTool/squares.png',




    Toaster:{
        error   : '/images/toaster/Error.png',
        warning : '/images/toaster/Warning.png',
        info    : '/images/toaster/Info.png',
        success : '/images/toaster/Success.png'
    },

    PaymentCard:{
        mastercard : '/images/paymentCard/master.png',
        visa       : '/images/paymentCard/visa.png',
        default    : '/images/paymentCard/default.png'
    }
}




export default PngIcons