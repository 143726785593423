export const Styles = {
  body: {
    width: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#fff',
    overflow: 'auto',
  },
  block: {
    display: 'flex',
    flexDirection: 'column',
    height: 'fit-content',
    backgroundRepeat: 'no-repeat',
    transition: 'all 0.1s ease',
    color: 'inherit',
  },
  element: {
    whiteSpace: 'pre-wrap',
  },
  divider: {
    width: '80%',
    height: '1px',
    background: '#cacaca',
    marginTop: '25px',
    marginBottom: '25px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  a: {
    display: 'inline-block',
  },
  button: {
    display: 'block',
  },
}

export default `
  @media (max-width: 767px) {
    .block{
      flex-wrap: wrap !important;
    }
    .block>div{
      width: 100% !important;
    }
  }
  `

// .body {
//   width: 100%;
//   background-repeat: no-repeat;
//   background-color: #fff;
//   overflow: auto;
//   height: 100%;
// }
// .body>.pb-block {
//   display: flex;
//   flex-direction: column;
//   height: fit-content;
//   background-repeat: no-repeat;
//   transition: all 0.1s ease;
//   color: inherit;

// }
// .body>.element {
//   white-space: pre-wrap;
// }
// .body>.divider {
//   width: 80%;
//   height: 1px;
//   background: #cacaca;
//   margin-top: 25px;
//   margin-bottom: 25px;
//   margin-left: auto;
//   margin-right: auto;
// }
// a{
//   display: inline-block
// }
// button{
//   display: block
// }
