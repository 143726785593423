import React, { useEffect, useState } from 'react'
import EditorPane from './EditorPane'
import LeftPane from './LeftPane'
import RightPane from './RightPane'
import { PngIcons, SvgIcons } from '../icons'
import Preview from './Preview'
import { ColorSchemeCode } from '../enums/ColorScheme'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useAppServices } from 'hook/services'
import Loader from 'examples/Loader'
import MDProgressCircular from 'components/MDProgressCircular'
import FitContentInput from 'components/FitContentInput'
import Swal from 'sweetalert2'

function TemplateDesign({ onChange, ...props }) {
  const initState = {
    json: {
      name: 'body',
      type: 'body',
      styles: {},
      childrens: [],
    },
    html: '',
  }

  const [preview, setPreview] = useState(false)
  const [focused, setFocused] = useState({})
  const [settings, setSettings] = useState('navigator')
  const [state, setState] = useState({ ...initState })
  const [processing, setProcessing] = useState(true)
  const { company_id, app_id } = useParams()
  const Service = useAppServices()
  const [design, setDesign] = useState(null)
  const { state: form } = useLocation()
  console.log(form, 'form')

  const getDesign = async () => {
    const { response } = await Service.servey.get_levelupstore({
      query: `company_id=${company_id}&app_id=${app_id}&form_id=${form}`,
    })

    if (response?.data) {
      setDesign(response.data)
    }

    if (response?.data?.design) {
      console.log(response?.data?.design, 'response?.data?.design')
      setState({ ...response.data?.design })
    }
    setProcessing(false)
  }

  useEffect(async () => {
    getDesign()
  }, [])

  return (
    <div id="TemplateDesign" className="w-100 pl-15 pr-15">
      {/* {focused.element && focused.top && <div className="element-referer" style={{top: parseInt(focused.top)-99 + 'px', left: parseInt(focused.left)-138 + 'px'}}>{focused.element.type}</div>} */}
      {processing ? (
        <div className="middle full-height">
          <MDProgressCircular />
        </div>
      ) : (
        <div className="row full-height">
          <Header
            preview={{ value: preview, setPreview }}
            focused={{ value: focused, setFocused }}
            state={{ values: state, setState }}
            design={design}
          />
          {!preview && (
            <LeftPane
              state={{ values: state, setState }}
              focused={{ value: focused, setFocused }}
              settings={{ value: settings, setSettings }}
            />
          )}
          {!preview && (
            <EditorPane
              preview={preview}
              state={{ values: state, setState }}
              focused={{ value: focused, setFocused }}
              settings={{ value: settings, setSettings }}
              onChange={onChange}
            />
          )}
          {!preview && (
            <RightPane
              state={{ values: state, setState }}
              focused={{ value: focused, setFocused }}
            />
          )}

          {preview && <Preview html={state.html} />}
        </div>
      )}
    </div>
  )
}

const Header = ({ preview, state, design }) => {
  const [title, setTitle] = useState('My Custom Form')
  const [open, setOpen] = useState(false)
  const [processing, setProcessing] = useState(false)
  const { company_id, app_id } = useParams()
  const Service = useAppServices()
  const navigate = useNavigate()

  const handlePreview = () => {
    const html = document.getElementById('EditorPane')
    state.setState({ ...state.values, html: html && html.innerHTML })
    preview.setPreview(!preview.value)
  }

  const downloadHtml = (e) => {
    e.preventDefault()
    var dataStr = 'data:text/html;charset=utf-8,' + encodeURIComponent(state.values.html)
    var dlAnchorElem = document.createElement('a')
    dlAnchorElem.setAttribute('href', dataStr)
    dlAnchorElem.setAttribute('download', 'sample.html')
    dlAnchorElem.click()
  }

  const handleSaveDesign = async () => {
    setProcessing(true)
    const payload = {
      design: state.values,
      company_id,
      app_id,
      url: `https://snapshotonboarding.levelupmarketplace.io/levelupstore/${company_id}/${app_id}/{{location.id}}/${design?._id}/preview`,
    }

    if (design?._id) payload._id = design._id

    const { response } = await Service.servey[design?._id ? 'update_levelupstore' : 'levelupstore'](
      { payload }
    )
    Swal.fire({
      icon: 'success',
      title: 'Design Saved Successfully',
      showConfirmButton: false,
      timer: 1500,
    })
    setProcessing(false)
  }

  const handleback = () => {
    navigate(-1)
  }
  console.log(
    window.location.origin +
      `/levelupstore/${company_id}/${app_id}/{{location.id}}/${design?._id}/preview`
  )

  const PreviewForm = async () => {
    window.open(`/levelupstore/${company_id}/${app_id}/{{location.id}}/${design?._id}/preview`, {
      target: '_blank',
    })
  }

  return (
    <div className="widgetHeader">
      <div className="viewandDownloadButtons ml-8">
        {!preview.value && (
          <>
            {/* <span className="ml-64 cp" onClick={() => navigate(-1)}>
              <SvgIcons.ArrowUnExpand
                height="14px"
                color={preview.value && ColorSchemeCode.themeColor}
                style={{ transform: 'rotate(90deg)' }}
              />
            </span> */}
            <div className="ml-16 cp d-inline-block" onClick={handleback}>
              {/* {processing ? (
                <MDProgressCircular size={16} />
              ) : ( */}
              <SvgIcons.BackIcon color={preview.value && ColorSchemeCode.themeColor} />
              {/* )} */}
            </div>
            <div className="ml-16 cp d-inline-block" onClick={handleSaveDesign}>
              {processing ? (
                <MDProgressCircular size={16} />
              ) : (
                <SvgIcons.SaveIcon color={preview.value && ColorSchemeCode.themeColor} />
              )}
            </div>
            {design?._id && (
              <div className="ml-16 cp d-inline-block" onClick={PreviewForm}>
                <SvgIcons.NewTabLinkIcon color={ColorSchemeCode.themeColor} />
              </div>
            )}
          </>
        )}
        {preview.value ? (
          <span className="ml-64 cp" onClick={handlePreview}>
            <SvgIcons.ArrowUnExpand
              height="14px"
              color={preview.value && ColorSchemeCode.themeColor}
              style={{ transform: 'rotate(90deg)' }}
            />
          </span>
        ) : (
          ''
          // <span className="ml-16 cp" onClick={handlePreview}>
          //   <SvgIcons.EyeIcon height="14px" color={preview.value && ColorSchemeCode.themeColor} />
          // </span>
        )}

        {/* <div className="ml-16 cp d-inline-block" onClick={downloadHtml}>
          <SvgIcons.DownloadIcon />
        </div> */}
      </div>

      <div className="widgetTitle d-flex align-items-center">
        {/* <FitContentInput
          icon={{ color: 'inherit', width: '12px', class: 'middle' }}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        /> */}
        {/* {design?._id && (
          <div className="ml-16 cp d-inline-block" onClick={PreviewForm}>
            <SvgIcons.NewTabLinkIcon color={ColorSchemeCode.themeColor} />
          </div>
        )} */}
      </div>
    </div>
  )
}

export default TemplateDesign
