import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import QueryString from 'qs'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import { ReqMethods, ghl } from 'enums'
import env from 'config'
import { useAppServices } from 'hook/services'
import MassagePage from './message'

function GHL() {
  const navigate = useNavigate()
  const { search } = useLocation()
  const { app_id } = useParams()
  const code = new URLSearchParams(search).get('code')
  const AppServices = useAppServices()
  const [Error, setError] = useState('')
  const [Message, setMessage] = useState('')
  const associate = async (creds, app) => {
    console.log(creds)
    console.log('credscredscredscredscredscredscreds')
    const payload = {
      location_id: creds.locationId,
      userId: creds.userId,
      userType: creds.userType,
      company_id: creds.companyId,
      access_token: creds.access_token,
      refresh_token: creds.refresh_token,
      app_id: app_id,
    }

    const { response } = await AppServices.app_token.snapshot_onboarding_token({ payload })

    if (!response) return setError('Something went wrong while integration, please try latter')
    // update(payload);
    window.location.href = app?.forward_url
    // return setError("App Connected Successfully");
  }
  const getApp = async () => {
    const { response } = await AppServices.snapshot_onboarding.filter({
      query: `app_id=${app_id}`,
    })
    if (!response) return setError('Something went wrong while integration, please try latter')

    return response && getCreds(response.data)
  }

  const getCreds = async (app) => {
    const payload = {
      method: ReqMethods.POST,
      path: ghl.APIs.oauth,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      payload: QueryString.stringify({
        client_id: app?.client_id,
        client_secret: app?.client_secret,
        grant_type: 'authorization_code',
        code,
        refresh_token: '',
      }),
    }

    const { response } = await AppServices.services.ghl.call_service({ payload })

    if (!response) return setError('Something went wrong while integration, please try latter')
    // if(location_id !== response.data.locationId) return setError('location ID mismatch')

    return response && associate(response.data, app)
  }
  let pageLoad = true
  const onLoad = () => {
    if (pageLoad) {
      getApp()
      pageLoad = false
    }
  }

  useEffect(onLoad, [])

  return (
    // <MDBox>
    //     {!Error && <MDBox>Integrating please wait...</MDBox>}
    //     {Error && <MDBox>
    //         {Error}
    //         <MDButton onClick={() => navigate(-2)}>Go Back</MDButton>
    //     </MDBox>}
    // </MDBox>
    <MassagePage Error={Error} Message={Error} />
  )
}

export default GHL
