/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is library for typechecking of props
import PropTypes from 'prop-types'

// @mui material components
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import Icon from '@mui/material/Icon'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDAvatar from 'components/MDAvatar'
import { Menu, MenuItem } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'

// Custom styles for ComplexProjectCard
function ComplexProjectCard({ color, image, title, dateTime, description, members, app }) {
  const navigate = useNavigate()
  const [slackBotMenu, setSlackBotMenu] = useState(null)
  const openSlackBotMenu = (event) => setSlackBotMenu(event.currentTarget)
  const closeSlackBotMenu = () => setSlackBotMenu(null)

  const handleNavigate = (id) => {
    navigate(`./${id}`)
  }
  const handleNavigateAppDashboard = (id) => {
    navigate(`./dashboard/${id}`)
  }
  const renderMembers = members.map((member, key) => {
    const memberKey = `member-${key}`

    return (
      <MDAvatar
        key={memberKey}
        src={member}
        alt="member profile"
        size="xs"
        sx={({ borders: { borderWidth }, palette: { white } }) => ({
          border: `${borderWidth[2]} solid ${white.main}`,
          cursor: 'pointer',
          position: 'relative',

          '&:not(:first-of-type)': {
            ml: -1.25,
          },

          '&:hover, &:focus': {
            zIndex: '10',
          },
        })}
      />
    )
  })

  return (
    <Card>
      <MDBox p={2}>
        <MDBox display="flex" alignItems="center">
          <MDAvatar
            src={image}
            alt={title}
            size="xl"
            variant="rounded"
            bgColor={color}
            sx={{ p: 1, mt: -6, borderRadius: ({ borders: { borderRadius } }) => borderRadius.xl }}
          />
          <MDBox ml={2} mt={-2} lineHeight={0}>
            <MDTypography variant="h6" textTransform="capitalize" fontWeight="medium">
              {title}
            </MDTypography>
            {/* {members.length > -1 ? <MDBox display="flex">{renderMembers}</MDBox> : null} */}
          </MDBox>
          <MDTypography
            color="secondary"
            onClick={openSlackBotMenu}
            sx={{
              ml: 'auto',
              mt: -1,
              alignSelf: 'flex-start',
              py: 1.25,
            }}
          >
            <Icon fontSize="default" sx={{ cursor: 'pointer', fontWeight: 'bold' }}>
              more_vert
            </Icon>
          </MDTypography>

          <Menu
            anchorEl={slackBotMenu}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={Boolean(slackBotMenu)}
            onClose={closeSlackBotMenu}
            keepMounted
          >
            <MenuItem onClick={() => handleNavigate(app?._id)}>View</MenuItem>
            <MenuItem onClick={() => handleNavigateAppDashboard(app?.app_id)}>Analytics</MenuItem>
            {/* <MenuItem onClick={close}>Another action</MenuItem>
      <MenuItem onClick={close}>Something else here</MenuItem> */}
          </Menu>
        </MDBox>
        <MDBox my={2} lineHeight={1}>
          <MDTypography variant="button" fontWeight="light" color="text">
            {description}
          </MDTypography>
        </MDBox>
        <Divider />
        <MDBox display="flex" justifyContent="space-between" alignItems="center">
          {members.length > -1 ? (
            <MDBox display="flex" flexDirection="column" lineHeight={0}>
              <MDTypography variant="button" fontWeight="medium">
                {members.length}
              </MDTypography>
              <MDTypography variant="button" fontWeight="regular" color="secondary">
                No of installs
              </MDTypography>
            </MDBox>
          ) : null}
          {dateTime ? (
            <MDBox display="flex" flexDirection="column" lineHeight={0}>
              <MDTypography variant="button" fontWeight="medium">
                {dateTime}
              </MDTypography>
              <MDTypography variant="button" fontWeight="regular" color="secondary">
                Due date
              </MDTypography>
            </MDBox>
          ) : null}
        </MDBox>
      </MDBox>
    </Card>
  )
}

// Setting default values for the props of ComplexProjectCard
ComplexProjectCard.defaultProps = {
  color: 'dark',
  dateTime: '',
  members: [],
}

// Typechecking props for the ProfileInfoCard
ComplexProjectCard.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'dark',
    'light',
  ]),
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  dateTime: PropTypes.string,
  description: PropTypes.node.isRequired,
  members: PropTypes.arrayOf(PropTypes.string),
}

export default ComplexProjectCard
