import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import Fade from 'react-reveal/Fade'

import { PngIcons } from '../icons'
import CircularProgressbarWithLabel from './CircularProgressBarWithLabel'
import * as enums from '../enums/enums'

function CustomToasters({ position, notifications, updateToast }) {
  const [list, setList] = useState(notifications)

  const updateToasters = () => setList(notifications)

  useEffect(updateToasters, [notifications])

  const deleteToaster = (idx) => {
    // list.splice(idx, 1)
    list[idx].progress = 100
    setList([...list])
    updateToast && updateToast([...list])
  }

  const handleMouseHover = (idx, pause) => {
    list[idx].pause = pause
    setList([...list])
  }

  useEffect(() => {
    const filteredList = list.filter((notification) => notification.progress < 100)
    if (!filteredList.length) return

    const timer = setInterval(() => {
      if (!list.length) return
      const updatedList = list.map((toast) => {
        if (toast.progress <= 100 && !toast.pause) toast.progress = toast.progress + 1
        return toast
      })
      setList([...updatedList])
    }, enums.TOASTER_DELAY / 100)

    return () => clearInterval(timer)
  }, [list])

  return (
    <div id="Toaster">
      <div className={`notification-container ${position}`}>
        {list &&
          list.map((notification, idx) => (
            <Fade
              key={idx}
              collapse
              right={position.includes('right')}
              left={position.includes('left')}
              collapse
              when={notification.progress < 90}
            >
              <div
                onMouseEnter={() => handleMouseHover(idx, true)}
                onMouseLeave={() => handleMouseHover(idx, false)}
                className={`notification toast ${position} ${notification.severity}`}
              >
                <div className="float-right">
                  <CircularProgressbarWithLabel
                    value={notification.progress}
                    label={<button onClick={() => deleteToaster(idx)}>x</button>}
                    size={26}
                  />
                </div>

                <div className="notification-image">
                  <img src={PngIcons.Toaster[notification.severity]} alt="" />
                </div>
                <div>
                  <p className="notification-title">{notification.title}</p>
                  <p className="notification-message">{notification.message}</p>
                </div>
              </div>
            </Fade>
          ))}
      </div>
    </div>
  )
}

CustomToasters.defaultProps = {
  position: 'bottom-right',
  severity: 'error',
}

CustomToasters.propTypes = {
  position: PropTypes.string,
  notifications: PropTypes.array.isRequired,
}

export default CustomToasters
