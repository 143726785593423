import * as ct from 'countries-and-timezones'

import { Moreoptions, PointExpiry, PurchaseTypes, PassVariables } from '../enums/enums'
import Campaign from '../enums/Campaign'
import * as CountryCode from '../enums/CountryCode'
import Currencies from '../enums/Currencies'
import * as enums from '../enums/enums'
import PassDesign from '../enums/PassDesign'

const getContryCodes = () => {
  return (
    <>
      <option value="">None</option>
      {CountryCode.map((value) => (
        <option value={value.dial_code}>{value.code + value.dial_code + ' ' + value.flag}</option>
      ))}
    </>
  )
}

const getCurrencyCodes = () => {
  return (
    <>
      <option value="">None</option>
      {Currencies.map((value) => (
        <option value={value.code}>{value.code + ' (' + value.symbol + ')'}</option>
      ))}
    </>
  )
}

const getTimezones = () => {
  return (
    <>
      <option value="">None</option>
      {Object.values(ct.getAllTimezones()).map((value) => (
        <option value={value.name}>
          {value.name + ' ' + value.country + ' ' + value.utcOffsetStr}
        </option>
      ))}
    </>
  )
}

const getProgramActiveStatus = () => {
  const Programstatus = [
    {
      status: 'Active',
      value: true,
    },
    {
      status: 'Inactive',
      value: false,
    },
  ]

  return (
    <>
      {Programstatus.map((value) => (
        <option value={value.value}>{value.status}</option>
      ))}
    </>
  )
}

const getMoreOptions = () => {
  return (
    <>
      {Moreoptions.map((value) => (
        <option value={value.value}>{value.name}</option>
      ))}
    </>
  )
}

const getProgramApprovaltypes = () => {
  const Approvaltypes = [
    {
      name: 'None',
      value: '',
    },
    {
      name: 'Immediatley',
      value: Campaign.ApprovalTypes.IMMEDIATELY,
    },
    {
      name: 'Manually',
      value: Campaign.ApprovalTypes.MANUALLY,
    },
    {
      name: 'After a fixed duration',
      value: Campaign.ApprovalTypes.AFTER_DURATION,
    },
  ]
  return (
    <>
      {Approvaltypes.map((approvalType) => (
        <option
          disabled={approvalType.value === Campaign.ApprovalTypes.MANUALLY}
          value={approvalType.value}
        >
          {approvalType.name}
        </option>
      ))}
    </>
  )
}

const getOperands = () => {
  const Operands = [
    {
      name: 'None',
      value: '',
    },
    {
      name: 'Greater Than',
      value: enums.Operands.GREATER,
    },
    {
      name: 'Lesser Than',
      value: enums.Operands.LESSER,
    },
    {
      name: 'Equals To',
      value: enums.Operands.EQUAL,
    },
    {
      name: 'Not Equals To',
      value: enums.Operands.NOT_EQUAL,
    },
  ]

  return (
    <>
      {Operands.map((value) => (
        <option value={value.value}>{value.name}</option>
      ))}
    </>
  )
}

const getRuleSelectType = () => {
  const RuleSelectType = [
    {
      name: 'None',
      value: '',
    },
    {
      name: 'Match Any',
      value: 'any',
    },
    {
      name: 'Match All',
      value: 'all',
    },
  ]

  return (
    <>
      {RuleSelectType.map((value) => (
        <option value={value.value}>{value.name}</option>
      ))}
    </>
  )
}

const getTemplateTypes = () => {
  const FieldTypes = [
    {
      name: 'widget',
      value: 'widget',
    },
    {
      name: 'landingPage',
      value: 'landingPage',
    },
    {
      name: 'Email',
      value: 'email',
    },
  ]

  return (
    <>
      <option value="">None</option>
      {FieldTypes.map((value) => (
        <option value={value.value}>{value.name}</option>
      ))}
    </>
  )
}

const getSaveToOptions = () => {
  const FieldTypes = [
    {
      name: 'dev',
      value: 'dev',
    },
    {
      name: 'live',
      value: 'live',
    },
  ]

  return (
    <>
      <option value="">None</option>
      {FieldTypes.map((value) => (
        <option value={value.value}>{value.name}</option>
      ))}
    </>
  )
}

const getPointExpiry = () => {
  return (
    <>
      {PointExpiry.map((value) => (
        <option value={value.value}>{value.name}</option>
      ))}
    </>
  )
}

const getPurchasesTypes = () => {
  return (
    <>
      {PurchaseTypes.map((value) => (
        <option value={value.value}>{value.name}</option>
      ))}
    </>
  )
}

const getPassVariables = () => {
  return (
    <>
      {PassVariables.map((value) => (
        <option value={value.value}>{value.name}</option>
      ))}
    </>
  )
}

const PassTypes = () => {
  const passTypes = [
    {
      name: 'None',
      value: '',
    },
    {
      name: 'Coupon',
      value: PassDesign.PassTypes.COUPON,
    },
    {
      name: 'Ticket',
      value: PassDesign.PassTypes.TICKET,
    },
    {
      name: 'Loyalty Card',
      value: PassDesign.PassTypes.LOYALTY_CARD,
    },
    {
      name: 'Stamp Card',
      value: PassDesign.PassTypes.STAMP_CARD,
    },
    {
      name: 'Membership Card',
      value: PassDesign.PassTypes.MEMBERSHIP_CARD,
    },
  ]

  return (
    <>
      {passTypes.map((type, idx) => (
        <option key={idx} value={type.value}>
          {type.name}
        </option>
      ))}
    </>
  )
}

const appleCodesFormat = () => {
  const formats = [
    {
      name: 'None',
      value: '',
    },
    {
      name: 'QR Code',
      value: 'PKBarcodeFormatQR',
    },
    {
      name: 'Barcode PDF417',
      value: 'PKBarcodeFormatPDF417',
      disabled: true,
    },
  ]

  return (
    <>
      {formats.map((type, idx) => (
        <option disabled={type.disabled} key={idx} value={type.value}>
          {type.name}
        </option>
      ))}
    </>
  )
}

const textAlignment = () => {
  return (
    <>
      <option value="">None</option>
      <option value="PKTextAlignmentRight">Right</option>
      <option value="PKTextAlignmentLeft">Left</option>
    </>
  )
}

const getQrModeTypes = () => {
  return (
    <>
      <option value="text">Text</option>
      <option value="number">Number</option>
    </>
  )
}

const getCampaignOptions = (campaigns) => (
  <>
    <option value="">None</option>
    {campaigns.map((campaign) => (
      <option key={campaign._id} value={campaign._id}>
        {campaign.campaignName}
      </option>
    ))}
  </>
)

const widgetBtnPositions = () => {
  // value will be clockwise [top, right, bottom, left] seperated with point
  const positions = [
    {
      name: 'Top Left',
      value: '20px...40px',
    },
    {
      name: 'Top Right',
      value: '20px.40px..',
    },
    {
      name: 'Bottom Left',
      value: '..20px.40px',
    },
    {
      name: 'Bottom Right',
      value: '.40px.20px.',
    },
  ]

  return (
    <>
      <option value="">None</option>
      {positions.map((position, idx) => (
        <option key={idx} value={position.value}>
          {position.name}
        </option>
      ))}
    </>
  )
}

const getFieldTypes = () => {
  const FieldTypes = [
    {
      name: 'text',
      value: 'text',
    },
    {
      name: 'number',
      value: 'number',
    },
    {
      name: 'date',
      value: 'date',
    },
    {
      name: 'boolean',
      value: 'boolean',
    },
  ]

  return (
    <>
      <option value="">None</option>
      {FieldTypes.map((value) => (
        <option value={value.value}>{value.name}</option>
      ))}
    </>
  )
}

const getBooleanType = () => {
  const BooleanTypes = [
    {
      name: 'true',
      value: true,
    },
    {
      name: 'false',
      value: false,
    },
  ]

  return (
    <>
      <option value="">None</option>
      {BooleanTypes.map((value) => (
        <option value={value.value}>{value.name}</option>
      ))}
    </>
  )
}

const FontFamily = () => {
  const fontFamilies = [
    { value: 'Roboto' },
    { value: 'Open Sans' },
    { value: 'Lato' },
    { value: 'Oswald' },
    { value: 'Raleway' },
    { value: 'Poppins' },
    { value: 'Ubuntu' },
    { value: 'Rubik' },
    { value: 'Inter' },
    { value: 'Playfair Display' },
    { value: 'Roboto Slab' },
    { value: 'Merriweather' },
    { value: 'Lora' },
    { value: 'Crimson Text' },
    { value: 'Nunito' },
    { value: 'Comfortaa' },
    { value: 'NanumGothic' },
    { value: 'Bebas Neue' },
    { value: 'Lobster' },
    { value: 'Fredoka One' },
    { value: 'Monoton' },
    { value: 'Fugaz One' },
  ]

  return (
    <>
      <option value="">None</option>
      {fontFamilies.map((item) => (
        <option value={item.value}>{item.value}</option>
      ))}
    </>
  )
}

const FontWeight = () => {
  const fontWeights = [
    {
      name: 'Thin',
      value: 100,
    },
    {
      name: 'Extra Light',
      value: 200,
    },
    {
      name: 'Light',
      value: 300,
    },
    {
      name: 'Regular',
      value: 400,
    },
    {
      name: 'Medium',
      value: 500,
    },
    {
      name: 'Semi Bold',
      value: 600,
    },
    {
      name: 'Bold',
      value: 700,
    },
    {
      name: 'Extra Bold',
      value: 800,
    },
    {
      name: 'Heavy',
      value: 900,
    },
  ]

  return (
    <>
      <option value="">None</option>
      {fontWeights.map((value) => (
        <option value={value.value}>{value.name}</option>
      ))}
    </>
  )
}

const BorderStyles = () => {
  const borderStyles = [
    {
      icon: '⎯⎯⎯⎯⎯⎯⎯',
      name: 'Solid',
      value: 'solid',
    },
    {
      icon: '‒ ‒ ‒',
      name: 'Dashed',
      value: 'dashed',
    },
  ]

  return (
    <>
      <option value="none">None</option>
      {borderStyles.map((item) => (
        <option value={item.value}>{item.icon + ' ' + item.name}</option>
      ))}
    </>
  )
}

const SubmitActions = () => {
  const actions = [{ name: 'Create Pass', value: 'createPass' }]
  return (
    <>
      <option value="none">None</option>
      {actions.map((item) => (
        <option value={item.value}>{item.name}</option>
      ))}
    </>
  )
}

const InputTypes = () => {
  const options = [
    { name: 'text', value: 'text' },
    { name: 'number', value: 'number' },
    { name: 'email', value: 'email' },
    { name: 'phone', value: 'tel' },
    { name: 'date', value: 'date' },
    { name: 'time', value: 'time' },
    { name: 'date & time', value: 'datetime-local' },
    { name: 'color', value: 'color' },
    { name: 'file', value: 'file' },
    { name: 'url', value: 'url' },
    { name: 'password', value: 'password' },
    { name: 'checkbox', value: 'checkbox' },
    { name: 'radio', value: 'radio' },
  ]

  return (
    <>
      <option value="">None</option>
      {options.map((item) => (
        <option value={item.value}>{item.name}</option>
      ))}
    </>
  )
}

export {
  getContryCodes,
  getCurrencyCodes,
  getTimezones,
  getProgramActiveStatus,
  getMoreOptions,
  getProgramApprovaltypes,
  getOperands,
  getRuleSelectType,
  getPointExpiry,
  getPurchasesTypes,
  getPassVariables,
  PassTypes,
  appleCodesFormat,
  textAlignment,
  getQrModeTypes,
  getCampaignOptions,
  widgetBtnPositions,
  getFieldTypes,
  getBooleanType,
  FontFamily,
  FontWeight,
  BorderStyles,
  SubmitActions,
  InputTypes,
  getTemplateTypes,
  getSaveToOptions,
}
