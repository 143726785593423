import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
});

export default function ContinuousSlider({title, startIcon, endIcon, onChange , value, min, max}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
     {title && 
     <Typography id="continuous-slider" gutterBottom>
        title
      </Typography>}
      <Grid container spacing={2}>
        {startIcon && 
        <Grid item>
          startIcon
        </Grid>}
        <Grid item xs>
          <Slider 
            value             = {value}
            onChange          = {onChange}
            min               = {min}
            max               = {max}
            valueLabelDisplay = {'auto'}
            aria-labelledby   = "continuous-slider"
          />
        </Grid>
        {endIcon && 
        <Grid item>
            endIcon
        </Grid>}
      </Grid>
    </div>
  );
}
