import PageLayout from 'examples/LayoutContainers/PageLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import React from 'react'
import { Grid } from '@mui/material'
import Loader from 'examples/Loader'
import MUIDataTable from 'mui-datatables'
// import "../Users/style.css";

import { useEffect, useState } from 'react'
import { useAgencyInfo } from 'context/agency'
import { useAppServices } from 'hook/services'
import MDButton from 'components/MDButton'
import { useNavigate, useParams } from 'react-router-dom'
import CreateModal from './components/Modals/Create'
import env from 'config'
function Servey() {
  const navigate = useNavigate()
  const { company_id, app_id } = useParams()
  // const [agency] = useAgencyInfo()
  const Service = useAppServices()
  const [Conjo, setConjo] = useState([])
  const [data, setdata] = useState([])
  const [agency, setAency] = useState({})
  const [processing, setProcessing] = useState(true)
  const [copy, setCopy] = useState(false)
  const copyToClipboard = (url) => {
    const text = url

    // Create a temporary textarea element to hold the text
    const textarea = document.createElement('textarea')
    textarea.value = text
    textarea.setAttribute('readonly', '')
    textarea.style.position = 'absolute'
    textarea.style.left = '-9999px'

    // Append the textarea to the body
    document.body.appendChild(textarea)

    // Select the text inside the textarea
    textarea.select()

    // Execute the copy command
    document.execCommand('copy')
    setCopy(true)
    // Remove the textarea from the DOM
    document.body.removeChild(textarea)

    setTimeout(() => {
      setCopy(false)
    }, 2000)
  }
  const columns = [
    {
      name: 'Name',
      options: {
        filter: false,
      },
    },
    {
      name: 'Location',
      options: {
        filter: false,
      },
    },
    {
      name: 'Created At',
      options: {
        filter: false,
      },
    },
    {
      name: 'Action',
      options: {
        filter: false,
      },
    },
  ]

  const options = {
    filter: false,
    download: false,
    print: false,
    viewColumns: false,
    selectableRows: 'none',
    filterType: 'multiselect',
    responsive: 'standard',
  }
  const getServey = async () => {
    const { response } = await Service.servey.filter({
      query: `company_id=${company_id}&app_id=${app_id}`,
    })
    console.log(response, 'getServey')
    if (response) {
      const temp_loc = []
      var tabel_data = []
      response.data.forEach((element) => {
        const temp = [
          element.name,
          element.location.label,
          new Date(element.createdAt).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          }),
          <>
            <MDButton
              variant="contained"
              color="info"
              size="small"
              sx={{ marginRight: '4px' }}
              onClick={() => navigate(`./${element._id}`)}
            >
              Configure
            </MDButton>
            <MDButton
              variant="contained"
              color="info"
              size="small"
              sx={{ marginRight: '4px' }}
              onClick={() => navigate(`./location_configure/${element._id}`)}
            >
              Locations
            </MDButton>

            {/* <CopyToClipboard
              text={`http://localhost:3001/survey/form/{{location.id}}/${element._id}/${response.agency._id}`}
              onCopy={handlecopy}
              ml={2}
            > */}
            <MDButton
              variant="contained"
              color="info"
              size="small"
              ml={2}
              onClick={() =>
                copyToClipboard(
                  `https://snapshotonboarding.levelupmarketplace.io/survey/form/${app_id}/{{location.id}}/${element._id}/${response.agency._id}`
                )
              }
            >
              {copy ? 'Copied' : 'Copy'}
            </MDButton>
            {/* </CopyToClipboard> */}
            <MDButton
              variant="contained"
              color="error"
              size="small"
              sx={{ marginLeft: '4px' }}
              onClick={() => hadleDelete(element._id)}
            >
              Delete
            </MDButton>
          </>,
        ]
        tabel_data.push(temp)
      })
      setConjo(tabel_data)
      for (let index = 0; index < response.locations.length; index++) {
        const element = response.locations[index]
        temp_loc.push({
          label: element.name,
          value: element.id,
        })
      }
      setAency(response.agency)
      setdata(temp_loc)
      setProcessing(false)
    } else {
      setProcessing(false)
    }
  }
  const onLoad = () => {
    getServey()
  }
  useEffect(async () => {
    onLoad()
  }, [])
  const hadleDelete = async (id) => {
    const { response } = await Service.servey.delete({
      query: `_id=${id}`,
    })
    if (response) {
      getServey()
    }
  }
  const auth_url = `https://marketplace.gohighlevel.com/oauth/chooselocation?response_type=code&redirect_uri=${env.GHL.REDIRECT.ASSOCIATE}&client_id=${env.GHL.CLIENT_ID}&scope=${env.GHL.SCOPE}&state=${agency?._id}`
  const hanldeConnect = () => {
    var windowFeatures = 'width=600,height=600,menubar=no,location=no,toolbar=no,scrollbars=yes'

    // Open the URL in a new window with the specified features
    window.open(auth_url, '_blank', windowFeatures)
  }
  return (
    <>
      {processing ? (
        <Loader />
      ) : (
        <PageLayout>
          <>
            {/* <DashboardNavbar type="servey_page" handleChange={onLoad} data={data} /> */}
            <Grid container>
              {agency?.agency_ghl ? (
                <Grid item xs={12} mb={3} display={'flex'} justifyContent={'flex-end'}>
                  <CreateModal data={data} handleChange={onLoad} />
                </Grid>
              ) : (
                <Grid item xs={12} mb={3} display={'flex'} justifyContent={'flex-end'}>
                  <MDButton variant="contained" color="info" onClick={hanldeConnect}>
                    Connect App
                  </MDButton>
                </Grid>
              )}
              <Grid item xs={12}>
                <MUIDataTable title={'Survey'} data={Conjo} columns={columns} options={options} />
              </Grid>
            </Grid>
          </>
        </PageLayout>
      )}
    </>
  )
}

export default Servey
