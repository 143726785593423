import env from 'config'
import { useAgencyInfo } from 'context/agency'
import { useAppServices } from 'hook/services'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Preview from './Preview'
import Loader from 'examples/Loader'

function TemplatePerformer() {
  const BASE_URL = `${env.API_URL}/v1`
  const Service = useAppServices()
  const params = useParams()
  const navigate = useNavigate()
  const [agency] = useAgencyInfo()
  const [servey_data, setservey_data] = useState({})
  const [loader, setloader] = useState(true)
  const [form, setForm] = useState({})
  const [currentStep, setCurrentStep] = useState(0)
  const [totalSteps, setTotalSteps] = useState(0)

  const getTotalSteps = (element) => {
    if (element.type === 'stepper') {
      return setTotalSteps(element.steps.length)
    } else {
      element.childrens &&
        element.childrens.map((child) => {
          getTotalSteps(child)
        })
    }
  }

  const getSurvey = async () => {
    const { response } = await Service.servey.get({
      query: `_id=${params.id}`,
    })

    if (response?.data?.customization?.length) {
      navigate(`/survey/form/${params.app_id}/${params.loc_id}/${params.id}/${params.agency_id}/v1`)
    }

    console.log(response, 'getSurvey')

    if (response?.data) {
      setForm(response.data)
      getTotalSteps(response.data.design?.json)
    }
    setloader(false)
  }
  const getSurveycheck = async (data) => {
    const { response } = await Service.servey.Check({
      query: `form_id=${params.id}&location_id=${params.loc_id}`,
    })

    console.log('SurveyCheck', response)

    if (!response) {
      return
    }

    getSurvey()
  }

  const onLoad = () => {
    getSurveycheck()
  }
  useEffect(async () => {
    onLoad()
  }, [])
  {
    console.log(totalSteps, 'totalSteps')
  }
  return (
    <div>
      {loader ? (
        <div>
          <Loader />
        </div>
      ) : (
        <Preview state={{ values: form.design }} survey={form} />
      )}
    </div>
  )
}

export default TemplatePerformer
