import React, { useEffect, useState } from 'react'

import localForage from 'localforage'

import SvgIcons from '../icons/svg.icon'
import { ColorSchemeCode } from '../enums/ColorScheme'
import Fields from '../enums/Fields'
import { useParams } from 'react-router-dom'
import { useAppServices } from 'hook/services'

export default function CustomFieldsDropDown({ title, selectValue, className, style }) {
  const [filteredFields, setFielteredFields] = useState()
  const [fields, setFields] = useState(Fields.FieldVariables)
  const [customFields, setCustomFields] = useState([])
  const [processing, setprocessing] = useState(true)
  const params = useParams()
  const Service = useAppServices()

  const searchField = (e) => {
    // Fields.FieldVariables
    const filter = fields.filter((category) =>
      category.label.toLowerCase().includes(e.target.value.toLowerCase())
    )
    console.log(filter)
    filter && setFielteredFields([...filter])
  }

  const handleSelectedValue = async (category) => {
    const selectedCategory = { ...category }

    if (category.reference === 'admin') {
      const user = await localForage.getItem('user')
      selectedCategory.value = user[category.key]
    }

    selectValue && selectValue(selectedCategory)
  }

  const getCustomValues = async (locationId) => {
    const { response } = await Service.servey.config_data({
      query: `locationId=${locationId}&company_id=${params.company_id}&app_id=${params.app_id}`,
    })
    if (response) {
      setCustomFields(response.data)
      const customFields = response.data.map((field) => {
        return {
          key: field.fieldKey,
          label: field.name,
          description: field.description,
          value: field.id,
          reference: Fields.FieldReference.CUSTOM,
          ...field,
        }
      })
      setFields([...Fields.FieldVariables, ...customFields])
    }
    return
  }

  const getSurvey = async () => {
    const { response } = await Service.servey.get({
      query: `_id=${params.id}`,
    })
    if (response) {
      // if (response.data?.customization.length) {
      //   setSlides(response.data?.customization)
      //   setlogo_value(response.data?.logo_value?.text)
      //   setlogo_image(response.data?.logo_value?.image)
      //   setformSettings(response.data?.form_settings)
      //   setlogo_type(response.data?.logo_type)
      // }
      await getCustomValues(response.data.location.value)
      setprocessing(false)
    } else {
      setprocessing(false)
    }
  }

  useEffect(() => {
    getSurvey()
  }, [])

  return (
    <div id="Designdropdown">
      <div className={`category-dropdown ${className}`} style={style}>
        <div className="d-flex justify-content-between cp pl-17 pt-12 pr-9 pb-12">
          <div className="color-c4F4F4F fs-14">{title}</div>
        </div>
        <div className="d-flex category-search">
          <div>
            <SvgIcons.SearchIcon color={ColorSchemeCode.c828282} />
          </div>
          <input placeholder="Search" onChange={searchField} />
        </div>

        {!processing ? (
          <div className="fixed-block">
            {/* <div className="category-search text-left fs-14">Admin</div> */}
            {/* {(filteredFields || fields).map(
            (category, idx) =>
              category.reference === Fields.FieldReference.ADMIN && (
                <div key={idx} className={`category-list`}>
                  <div
                    className="d-flex flex-column text-left categoryName cp"
                    onClick={() => handleSelectedValue(category)}
                  >
                    <div className="color-textfieldColor text-capitalize">{category.label}</div>
                    <div className="fs-10 color-caption">{category.description}</div>
                  </div>
                </div>
              )
          )} */}

            {/* <div className="category-search text-left fs-14">Brand</div>
          {(filteredFields || fields).map(
            (category, idx) =>
              category.reference === Fields.FieldReference.BRAND && (
                <div key={idx} className={`category-list`}>
                  <div
                    className="d-flex flex-column text-left categoryName cp"
                    onClick={() => handleSelectedValue(category)}
                  >
                    <div className="color-textfieldColor text-capitalize">{category.label}</div>
                    <div className="fs-10 color-caption">{category.description}</div>
                  </div>
                </div>
              )
          )} */}

            <div className="category-search text-left fs-14">Custom Values</div>
            {(filteredFields || fields).map(
              (category, idx) =>
                category.reference === Fields.FieldReference.CUSTOM && (
                  <div key={idx} className={`category-list`}>
                    <div
                      className="d-flex flex-column text-left categoryName cp"
                      onClick={() => handleSelectedValue(category)}
                    >
                      <div className="color-textfieldColor text-capitalize">{category.label}</div>
                      <div className="fs-10 color-caption">{category.description}</div>
                    </div>
                  </div>
                )
            )}
          </div>
        ) : (
          <div>
            <div className="category-search text-left fs-14">Loading...</div>
          </div>
        )}
      </div>
    </div>
  )
}
