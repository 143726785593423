import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import { useAppServices } from 'hook/services'
import '../assets/css/pages/templates/table.scss'

const DataTable = () => {
  const Service = useAppServices()
  const { company_id, app_id } = useParams()
  const navigate = useNavigate()

  const [tableData, setTableData] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [newName, setNewName] = useState('')
  const [isLoading, setIsLoading] = useState(true)

  const getForms = async () => {
    setIsLoading(true)
    try {
      const { response } = await Service.servey.get_levelupstore_forms({
        query: `company_id=${company_id}&app_id=${app_id}`,
      })

      if (response?.data) {
        setTableData(response.data)
      }
    } catch (error) {
      console.error('Error fetching forms:', error)
      Swal.fire({
        icon: 'error',
        title: 'Failed to fetch data',
        text: 'Please try again later',
        showConfirmButton: true,
      })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getForms()
  }, [])

  const handleEdit = (id) => {
    navigate(`./${id}`, { state: id })
  }

  const handleDelete = async (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#dc3545',
      cancelButtonColor: '#6c757d',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const { response } = await Service.servey.delete_form({
            query: `_id=${id}`,
          })

          if (response.data) {
            Swal.fire({
              icon: 'success',
              title: 'Deleted Successfully',
              showConfirmButton: false,
              timer: 1500,
            })
            getForms()
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Failed to Delete',
              showConfirmButton: false,
              timer: 1500,
            })
          }
        } catch (error) {
          console.error('Error deleting form:', error)
          Swal.fire({
            icon: 'error',
            title: 'Failed to Delete',
            text: 'Please try again later',
            showConfirmButton: true,
          })
        }
      }
    })
  }

  const handleCreate = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
    setNewName('')
  }

  const handleSave = async () => {
    if (!newName.trim()) {
      Swal.fire({
        icon: 'warning',
        title: 'Name is required',
        showConfirmButton: false,
        timer: 1500,
      })
      return
    }

    try {
      const payload = {
        name: newName,
        company_id,
        app_id,
      }

      const { response } = await Service.servey.levelupstore({ payload })

      if (response) {
        Swal.fire({
          icon: 'success',
          title: 'Form Created Successfully',
          showConfirmButton: false,
          timer: 1500,
        })
        getForms()
        handleCloseModal()
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Failed to Create Form',
          showConfirmButton: false,
          timer: 1500,
        })
      }
    } catch (error) {
      console.error('Error creating form:', error)
      Swal.fire({
        icon: 'error',
        title: 'Failed to Create Form',
        text: 'Please try again later',
        showConfirmButton: true,
      })
    }
  }

  return (
    <div id="DataTable" className="data-table-container">
      <div className="data-table-content">
        <div className="header-section">
          <h1 className="page-title">Forms Management</h1>
          <button onClick={handleCreate} className="create-btn">
            <span className="btn-icon">+</span>
            Create New Form
          </button>
        </div>

        <div className="table-container">
          {isLoading ? (
            <div className="loading-state">
              <div className="spinner"></div>
              <p>Loading data...</p>
            </div>
          ) : tableData.length === 0 ? (
            <div className="empty-state">
              <p>No forms found. Create your first form to get started.</p>
            </div>
          ) : (
            <table className="data-table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th className="actions-column">Actions</th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((row) => (
                  <tr key={row._id}>
                    <td className="id-cell" title={row._id}>
                      {row._id}
                    </td>
                    <td className="name-cell">{row.name}</td>
                    <td className="actions-cell">
                      <div className="action-buttons">
                        <button
                          onClick={() => handleEdit(row._id)}
                          className="edit-btn"
                          title="Edit form"
                        >
                          Edit
                        </button>
                        <button
                          onClick={() => handleDelete(row._id)}
                          className="delete-btn"
                          title="Delete form"
                        >
                          Delete
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="modal-overlay" onClick={handleCloseModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <div className="modal-header">
              <h3>Create New Form</h3>
              <button onClick={handleCloseModal} className="modal-close-btn">
                ×
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <label htmlFor="name-input">Form Name</label>
                <input
                  id="name-input"
                  type="text"
                  value={newName}
                  onChange={(e) => setNewName(e.target.value)}
                  placeholder="Enter form name"
                  className="name-input"
                  autoFocus
                />
              </div>
            </div>
            <div className="modal-footer">
              <button onClick={handleCloseModal} className="cancel-btn">
                Cancel
              </button>
              <button onClick={handleSave} className="save-btn">
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default DataTable
