import React, { useState } from 'react'
import { ColorSchemeCode } from '../enums/ColorScheme'

import { SvgIcons, PngIcons } from '../icons'
import { designs } from './design'
import step from 'assets/theme/components/stepper/step'

function LeftPane({ state, focused, settings }) {
  const elementSelected = () => settings.setSettings('navigator')

  const handleDropdown = ({ e, element }) => {
    e.stopPropagation()

    element.dropDown = !element.dropDown
    state.setState({ ...state.values })
  }

  const handleActiveElement = ({ e, element }) => {
    e.stopPropagation()

    const prevElement = focused.value.element
    if (prevElement) prevElement.active = false

    element.active = true
    state.setState({ ...state.values })
    focused.setFocused({ element })
  }

  const onDragStart = ({ e, child, childIndex, parent, parentIndex }) => {
    e.stopPropagation()
    e.dataTransfer.setData('drag', JSON.stringify({ child, childIndex, parent, parentIndex }))
  }

  const onDragOver = (e) => e.preventDefault()

  const handleIntraDrag = ({ drag, drop }) => {
    // within block
    drop.parent.childrens.splice(drag.childIndex, 1)
    drop.parent.childrens.splice(drop.childIndex, 0, drag.child)
    state.setState({ ...state.values })
  }

  const handleInterDrag = ({ drag, drop }) => {
    // outside block
    const handleStepper = (child) => {
      return child.steps.find((step) => {
        if (step.childrens) return getUpdatedStateDragElement(step)
      })
    }

    const getUpdatedStateDragElement = (parent) => {
      const updatedElement = parent.childrens.map((child) => {
        console.log(child, drag.parent)
        if (child.type === 'stepper') return handleStepper(child)
        if (JSON.stringify(child) === JSON.stringify(drag.child)) return parent
        if (child.childrens) return getUpdatedStateDragElement(child)
      })
      return updatedElement.find((value) => value)
    }

    const updatedStateDragElement = getUpdatedStateDragElement(state.values.json)

    console.log('UpdatedState', updatedStateDragElement)

    updatedStateDragElement.childrens.splice(drag.childIndex, 1)
    drop.parent.childrens.splice(drop.childIndex, 0, drag.child)
    state.setState({ ...state.values })
  }

  const onDrop = ({ e, drop }) => {
    e.preventDefault()
    e.stopPropagation()
    const drag = JSON.parse(e.dataTransfer.getData('drag'))
    if (JSON.stringify(drop.parent) === JSON.stringify(drag.parent))
      return handleIntraDrag({ drag, drop })
    return handleInterDrag({ drag, drop })
  }

  const copyElement = ({ e, element, elementIndex, parent }) => {
    e.stopPropagation()
    const copiedElement = JSON.parse(JSON.stringify(element))
    const updateChildrens = (parent) => {
      parent.childrens.forEach((element) => {
        if (element.active) element.active = false
        element.editable = true
        if (element.childrens) updateChildrens(element)
      })
    }
    copiedElement.childrens && updateChildrens(copiedElement)

    parent.childrens[elementIndex] = { ...element, active: false }
    parent.childrens.splice(elementIndex + 1, 0, { ...copiedElement, editable: true, active: true })
    state.setState({ ...state.values })
    focused.setFocused({ element: parent.childrens[elementIndex + 1] })
  }

  const GetChildrenElements = ({ childrens, parent, parentIndex, arrayCount }) => {
    return childrens.map((child, childIndex) => (
      <div
        key={childIndex}
        onDragStart={(e) => onDragStart({ e, child, childIndex, parent, parentIndex })}
        onDragOver={onDragOver}
        onDrop={(e) => onDrop({ e, drop: { child, childIndex, parent, parentIndex } })}
        draggable
      >
        <div
          onClick={(e) => handleActiveElement({ e, element: child })}
          style={{ paddingLeft: arrayCount * 20 + 'px', paddingRight: arrayCount * 5 + 'px' }}
          className={`d-flex block ${child.active && 'focused'}`}
          onMouseEnter={(e) => e.target.classList.add('hover')}
          onMouseLeave={(e) => e.target.classList.remove('hover')}
        >
          {child.childrens && child.childrens.length > 0 && child.type != 'stepper' && (
            <div
              onClick={(e) => handleDropdown({ e, element: child })}
              style={{ transition: 'all 3ms ease' }}
              className={child.dropDown ? ' rotate-bottom mr-8' : ' rotate-right mr-8'}
            >
              <SvgIcons.DropDownTriangleIcon
                height="12px"
                width="12px"
                color={child.active ? ColorSchemeCode.cffffff : ColorSchemeCode.c828282}
              />
            </div>
          )}
          {child.steps && child.steps.length > 0 && (
            <div
              onClick={(e) => handleDropdown({ e, element: child })}
              style={{ transition: 'all 3ms ease' }}
              className={child.dropDown ? ' rotate-bottom mr-8' : ' rotate-right mr-8'}
            >
              <SvgIcons.DropDownTriangleIcon
                height="12px"
                width="12px"
                color={child.active ? ColorSchemeCode.cffffff : ColorSchemeCode.c828282}
              />
            </div>
          )}
          <div className="text-capitalize pt-1">{child.name || child.type}</div>
          <div
            onClick={(e) => copyElement({ e, element: child, elementIndex: childIndex, parent })}
            className="ml-auto copy"
          >
            <SvgIcons.CopyIcon color={ColorSchemeCode.cffffff} />
          </div>
        </div>
        {child.dropDown && child.type != 'stepper' && (
          <GetChildrenElements
            childrens={child.childrens}
            parent={child}
            parentIndex={childIndex}
            arrayCount={arrayCount + 1}
          />
        )}
        {child.dropDown && child.type == 'stepper' && (
          <div>
            <GetChildrenElements
              childrens={child.steps}
              parent={child}
              parentIndex={childIndex}
              arrayCount={arrayCount + 1}
            />
          </div>
        )}
      </div>
    ))
  }

  const settingElements = {
    navigator: (
      <Navigator
        state={state}
        focused={focused}
        handleActiveElement={handleActiveElement}
        GetChildrenElements={GetChildrenElements}
        onDrop={onDrop}
      />
    ),
    addElement: <AddElement state={state} focused={focused} elementSelected={elementSelected} />,
  }

  return (
    <div id="LeftPane">
      <div className="properties">
        <div
          onClick={() => settings.setSettings('navigator')}
          className={`properties-options middle ${settings.value === 'navigator' && 'active'}`}
        >
          Navigator
        </div>
        <div
          onClick={() => settings.setSettings('addElement')}
          className={`properties-options middle ${settings.value === 'addElement' && 'active'}`}
        >
          Add Element
        </div>
      </div>
      <div className="navigatorText">Navigator</div>
      {settingElements[settings.value]}
    </div>
  )
}

function Navigator({ state, focused, GetChildrenElements, handleActiveElement, onDrop }) {
  return (
    <div className={`elements`}>
      <div
        onClick={(e) => handleActiveElement({ e, element: state.values.json })}
        className={`d-flex body ${
          focused.value.element && focused.value.element.type == state.values.json.type && 'focused'
        }`}
      >
        {/* {console.log('body value', )} */}
        <div className="text-capitalize ml-5">
          {/* <SvgIcons.DropDownTriangleIcon height="12px" width="12px"/> */}
          <span className="ml-8">{state.values.json.name || state.values.json.type}</span>
        </div>
      </div>
      {
        <GetChildrenElements
          childrens={state.values.json.childrens}
          parent={state.values.json}
          parentIndex={state.values.json.type}
          arrayCount={1}
        />
      }
    </div>
  )
}

function AddElement({ state, focused, elementSelected }) {
  const [customTemplate, setCustomTemplate] = useState([])

  const getForm = (parent) => {
    const form = parent.childrens.map((element) => {
      if (element.type === 'form') return element
      if (element.type === 'stepper') return element
      if (element.childrens) return getForm(element)
    })

    return form.find((value) => value)
  }

  const Components = [
    {
      name: 'heading',
      icon: SvgIcons.WidgetHeadingIcon,
      disable: false,
      props: { type: 'heading', active: true, editable: true, innerText: 'Heading', styles: {} },
    },
    {
      name: 'paragraph',
      icon: SvgIcons.WidgetParagraphIcon,
      disable: false,
      props: {
        type: 'paragraph',
        active: true,
        editable: true,
        innerText: 'paragraph',
        styles: {},
      },
    },
    {
      name: 'link',
      icon: SvgIcons.WidgetLinkIcon,
      disable: false,
      props: {
        type: 'link',
        href: 'https://google.com',
        editable: true,
        innerText: 'Link',
        active: true,
        styles: { cursor: 'pointer' },
      },
    },
    {
      name: 'image',
      icon: SvgIcons.WidgetImageIcon,
      disable: false,
      props: {
        type: 'image',
        src: 'https://placehold.co/150x80',
        editable: true,
        active: true,
        styles: { width: '100%' },
      },
    },
    {
      name: 'input',
      icon: SvgIcons.WidgetInputIcon,
      disable: !getForm(state.values.json),
      props: {
        type: 'block',
        active: true,
        editable: true,
        styles: {},
        childrens: [
          {
            type: 'label',
            active: false,
            editable: true,
            innerText: 'label',
            htmlFor: 'email',
            styles: {},
          },
          {
            type: 'input',
            active: false,
            editable: true,
            value: '',
            required: true,
            styles: {},
          },
        ],
      },
    },
    {
      name: 'button',
      icon: SvgIcons.WidgetButtonIcon,
      disable: false,
      props: { type: 'button', innerText: 'Button', editable: true, active: true, styles: {} },
    },
    {
      name: 'block',
      icon: SvgIcons.WidgetBlockIcon,
      disable: false,
      props: { type: 'block', active: true, editable: true, styles: {}, childrens: [] },
    },
    {
      name: 'form',
      icon: SvgIcons.WidgetFormIcon,
      disable: getForm(state.values.json),
      props: {
        type: 'form',
        active: true,
        editable: true,
        styles: {},
        submitAction: 'createPass',
        childrens: [
          {
            type: 'block',
            active: false,
            editable: true,
            styles: {},
            childrens: [
              {
                type: 'label',
                active: false,
                editable: true,
                innerText: 'label',
                htmlFor: 'email',
                styles: {},
              },
              {
                type: 'input',
                active: false,
                editable: true,
                value: '',
                inputName: 'email',
                id: 'email',
                name: 'email',
                inputType: 'email',
                placeholder: 'Place your email',
                required: true,
                styles: {},
              },
            ],
          },
          {
            type: 'button',
            active: false,
            editable: true,
            styles: {},
            elementType: 'submit',
            innerText: 'Submit',
          },
        ],
      },
    },
    {
      name: 'divider',
      icon: SvgIcons.WidgetDividerIcon,
      disable: false,
      props: { type: 'divider', active: true, editable: true, styles: {} },
    },
    {
      name: 'select',
      icon: SvgIcons.WidgetSelectIcon,
      disable: !getForm(state.values.json),
      props: {
        type: 'block',
        active: true,
        editable: true,
        styles: {},
        childrens: [
          {
            type: 'label',
            active: false,
            editable: true,
            innerText: 'label',
            htmlFor: 'email',
            styles: {},
          },
          {
            type: 'select',
            active: false,
            editable: true,
            value: '',
            required: true,
            styles: {},
            options: [],
          },
        ],
      },
    },
    {
      name: 'textarea',
      icon: SvgIcons.WidgetTextAreaIcon,
      disable: !getForm(state.values.json),
      props: {
        type: 'block',
        active: true,
        editable: true,
        styles: {},
        childrens: [
          {
            type: 'label',
            active: false,
            editable: true,
            innerText: 'label',
            htmlFor: 'email',
            styles: {},
          },
          {
            type: 'textarea',
            active: false,
            editable: true,
            value: '',
            required: true,
            styles: {},
            options: [],
          },
        ],
      },
    },
    {
      name: 'stepper',
      icon: SvgIcons.WidgetFormIcon,
      disable: getForm(state.values.json),
      props: {
        type: 'stepper',
        active: false,
        editable: true,
        styles: {},
        bgCompleteColor: '#3b82f6',
        bgActiveColor: '#bfdbfe',
        bgStepColor: '#e5e7eb',
        textCompleteColor: '#ffffff',
        textActiveColor: '#1d4ed8',
        textStepColor: '#6b7280',

        buttonContainerProps: {
          type: 'block',
          active: false,
          editable: true,
          styles: {
            flexDirection: 'row',
            marginTop: '32px',
            paddingTop: '',
            paddingLeft: '16px',
            paddingRight: '16px',
          },
        },
        nextButtonProps: {
          type: 'button',
          innerText: 'Next',
          editable: true,
          active: false,
          styles: {
            width: '',
            marginRight: '',
            marginLeft: 'auto',
            borderRadius: '12px',
            paddingTop: '',
            paddingLeft: '18px',
            paddingRight: '18px',
            backgroundColor: 'rgba(191,177,255,1)',
            color: 'rgba(255,255,255,1)',
          },
        },
        prevButtonProps: {
          type: 'button',
          innerText: 'Back',
          editable: true,
          active: false,
          styles: {
            paddingLeft: '18px',
            paddingRight: '18px',
            borderRadius: '12px',
            backgroundColor: 'rgba(247,246,251,1)',
          },
        },
        steps: [
          {
            type: 'block',
            active: false,
            editable: true,
            stepLabel: 'Step 1',
            styles: { padding: '16px' },
            childrens: [
              {
                type: 'heading',
                active: false,
                editable: true,
                innerText: 'Heading in step 1',
                styles: {},
              },
              {
                type: 'paragraph',
                active: false,
                editable: true,
                innerText: 'paragraph in step 1',
                styles: {},
              },
            ],
          },
          {
            type: 'block',
            active: false,
            editable: true,
            stepLabel: 'Step 2',
            styles: { padding: '16px' },
            childrens: [
              {
                type: 'heading',
                active: false,
                editable: true,
                innerText: 'Heading in step 2',
                styles: {},
              },
              {
                type: 'paragraph',
                active: false,
                editable: true,
                innerText: 'paragraph in step 2',
                styles: {},
              },
            ],
          },
          {
            type: 'block',
            active: false,
            editable: true,
            stepLabel: 'Step 3',
            styles: { padding: '16px' },
            childrens: [
              {
                type: 'heading',
                active: false,
                editable: true,
                innerText: 'Heading in step 3',
                styles: {},
              },
              {
                type: 'paragraph',
                active: false,
                editable: true,
                innerText: 'paragraph in step 3',
                styles: {},
              },
            ],
          },
        ],
      },
    },
    {
      name: 'video',
      icon: SvgIcons.WidgetVideoIcon,
      disable: false,
      props: {
        type: 'video',
        src: 'https://videos.pexels.com/video-files/3755797/3755797-uhd_2732_1440_25fps.mp4',
        editable: true,
        active: true,
        styles: { width: '100%' },
        controls: true,
        autoPlay: true,
        loop: true,
        muted: true,
        playsInline: true,
      },
    },
  ]

  const templates = [
    {
      name: 'design',
      icon: SvgIcons.WidgetDesignIcon,
      disable: false,
      children: [
        {
          name: 'Registeration form',
          icon: () => (
            <img
              src={'/images/designs/d001.png'}
              alt=""
              className="w-full h-100"
              style={{ objectFit: 'cover' }}
            />
          ),
          disable: false,
          props: designs[0],
        },
        {
          name: 'Stepper form',
          icon: () => (
            <img
              src={'/images/designs/d002.png'}
              alt=""
              className="w-full h-100"
              style={{ objectFit: 'cover' }}
            />
          ),
          disable: false,
          props: designs[1],
        },
        {
          name: 'form with cover',
          icon: () => (
            <img
              src={'/images/designs/d003.png'}
              alt=""
              className="w-full h-100"
              style={{ objectFit: 'cover' }}
            />
          ),
          disable: false,
          props: designs[2],
        },
      ],
    },
  ]

  const updateDropDown = (activeElement) => {
    const updateInheritance = (parent) => {
      const found = parent.childrens.find((element) => {
        if (JSON.stringify(element) === JSON.stringify(activeElement)) return true
        if (element.childrens) {
          const found = updateInheritance(element)
          if (found) element.dropDown = true
          return found
        }
      })
      if (found) return parent
    }

    const parent = updateInheritance(state.values.json)

    if (parent) parent.dropDown = true
    state.setState({ ...state.values })
  }

  const handleAddInputField = (newElement) => {
    const form = getForm(state.values.json)
    form.childrens.splice(form.childrens.length - 1, 0, { ...newElement.props })
    const element = form.childrens[form.childrens.length - 2]
    focused.setFocused({ element })
    updateDropDown(element)
    elementSelected()
    return state.setState({ ...state.values })
  }

  const handleNewElement = (newElement) => {
    if (newElement.name === 'input') return handleAddInputField(newElement)

    const activeElement = focused.value.element

    // if no active element then add new element to body
    if (!activeElement) {
      state.values.json.childrens.push({ ...newElement.props })
      const element = state.values.json.childrens[state.values.json.childrens.length - 1]

      focused.setFocused({ element })
      updateDropDown(element)
      elementSelected()
      return state.setState({ ...state.values })
    }

    // if active element found and has children then add new element to active element
    if (activeElement.childrens) {
      activeElement.active = false
      activeElement.childrens.push({ ...newElement.props })
      const element = activeElement.childrens[activeElement.childrens.length - 1]

      focused.setFocused({ element })
      updateDropDown(element)
      elementSelected()
      return state.setState({ ...state.values })
    }

    const findParent = (parent) => {
      const found = parent.childrens.map((element) => {
        if (JSON.stringify(element) === JSON.stringify(activeElement)) return parent
        if (element.childrens) {
          const found = findParent(element)
          if (found) {
            element.dropDown = true
            return found
          }
        }
      })
      if (found) return found.find((value) => value)
    }
    const parent = findParent(state.values.json)

    if (parent) {
      parent.childrens.forEach((child) => (child.active = false))
      parent.childrens.push({ ...newElement.props })
      const element = parent.childrens[parent.childrens.length - 1]

      focused.setFocused({ element })
      updateDropDown(element)
      elementSelected()
      return state.setState({ ...state.values })
    }
  }

  const onDragStart = (event, newElement) =>
    event.dataTransfer.setData('newElement', JSON.stringify(newElement))
  const onDragOver = (event) => event.preventDefault()

  const showDetail = (e) => {
    console.log('show')
    e.currentTarget.nextElementSibling?.setAttribute(
      'style',
      'top:' + (e.currentTarget.getBoundingClientRect().top - 20) + 'px'
    )
    e.currentTarget.nextElementSibling?.classList.remove('hide')
  }

  const hideDetail = (e) => {
    console.log('hide')
    document.getElementById('grid_box')?.classList.add('hide')
    document.getElementById('design_box')?.classList.add('hide')
  }

  const handleSelectedDesign = (element) => {
    state.setState({ ...element.props })
  }

  return (
    <div id="NavigationComp">
      <div className="contentBox">
        {Components.map((element, idx) => (
          <div
            onClick={() => handleNewElement(element)}
            key={idx}
            className={`singleBox ${element.disable && 'disabled'}`}
            onDragStart={(e) => onDragStart(e, element)}
            onDragOver={onDragOver}
            draggable
          >
            {/* <img src={element.icon} height="40" width="54px"/> */}
            <element.icon />
            <span className="mt-8 body2 fs-12 color-c4F4F4F">{element.name}</span>
          </div>
        ))}
      </div>
      <div className="navigatorText mt-5">Premade designs</div>

      {templates.map((premade, idx) => (
        <div onMouseLeave={hideDetail} className="contentBox">
          <div
            key={idx}
            className={`singleBox ${premade.disable && 'disabled'}`}
            onMouseEnter={showDetail}
          >
            <premade.icon />
            <span className="mt-8 body2 fs-12 color-c4F4F4F">{premade.name}</span>
          </div>
          <div id="design_box" onMouseLeave={hideDetail} className="card moreOptionBox hide">
            <div className="contentBox">
              {premade.children.map((element, idx) => (
                <div
                  key={idx}
                  onClick={() => handleSelectedDesign(element)}
                  className={`singleBox p-4 ${false && 'disabled'}`}
                  draggable
                >
                  <element.icon />
                  <span className="mt-8 body2 fs-12 color-c4F4F4F text-center text-truncate w-full">
                    {element.name}
                  </span>
                </div>
              ))}
              {customTemplate.map((element, idx) => (
                <div
                  key={idx}
                  onClick={() => handleSelectedDesign(element)}
                  className={`singleBox p-4 ${false && 'disabled'}`}
                  draggable
                >
                  <element.icon />
                  <span className="mt-8 body2 fs-12 color-c4F4F4F text-center">{element.name}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default LeftPane
