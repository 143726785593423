import axios from 'axios'
import env from 'config'
import { setNotification, useNotifcation } from 'context/notification'
import { ReqMethods, ResponseStatus } from 'enums'
import { useAuth, useLogout } from './auth'

const BASE_URL = `${env.API_URL}/agency_app`
const SUPERADMIN_BASE_URL = `${env.API_URL}/v1`

function useServiceHandler() {
  const [, dispatch] = useNotifcation()
  const Logout = useLogout()

  return (fn) =>
    async (method, path, { query, payload, token, apiKey, toaster, message, error } = {}) => {
      try {
        const res = await fn(method, path, { query, payload, token, apiKey })
        console.log('API - RESPONSE', res, toaster, message, error)
        toaster &&
          setNotification(dispatch, {
            open: true,
            message: message || res.data.message,
            title: 'Success',
          })

        return { response: res.data }
      } catch (err) {
        console.log('API- ERROR', err.response?.data || err)

        // expire error : jwt expired
        if (err.response && err.response.status === ResponseStatus.UNAUTHORIZED) {
          setNotification(dispatch, {
            open: true,
            message: error || 'session expired',
            title: 'Error',
            severity: 'error',
          })
          setTimeout(Logout, 4000)
          return { error: err.response?.data || err }
        }

        const customError = err.response?.data?.error
          ? `${err.response?.data?.message} \n${err.response?.data?.error}`
          : err.response?.data?.message

        toaster &&
          setNotification(dispatch, {
            open: true,
            message: error || customError || err.message,
            severity: 'error',
            title: 'Error',
          })
        return { error: err.response ? err.response.data : err }
      }
    }
}

function useCallService() {
  const authToken = useAuth()
  const serviceHandler = useServiceHandler()

  const CallService = (method, path, { query, payload, token = true, apiKey = null }) => {
    const pathname = query ? `${path}?${query}` : path
    const config = {}

    if (token) config.headers = { 'x-auth-token': `Bearer ${authToken || token}` }
    if (apiKey) config.headers = { apiKey }

    const details = {}

    if (payload) details.payload = payload
    details.config = config

    return axios[method](pathname, ...Object.values(details))
  }

  return serviceHandler(CallService)
}

function useAppServices() {
  const { GET, POST, PUT, DELETE } = ReqMethods
  const CallService = useCallService()

  /**
    Option for service is the object that could has the following properties
    query, payload, token, apiKey
  */

  const APIs = {
    auth: {
      login: (options) => CallService(POST, `${BASE_URL}/auth/login`, options),
    },
    user: {
      get: (options) => CallService(GET, `${BASE_URL}/user`, options),
    },
    apps: {
      get: (options) => CallService(GET, `${SUPERADMIN_BASE_URL}/app`, options),
      update: (options) => CallService(PUT, `${SUPERADMIN_BASE_URL}/app`, options),
      create: (options) => CallService(POST, `${SUPERADMIN_BASE_URL}/app`, options),
      delete: (options) => CallService(DELETE, `${SUPERADMIN_BASE_URL}/app`, options),
      getTrackingId: (options) =>
        CallService(GET, `${SUPERADMIN_BASE_URL}/app/tracking-id`, options),
      filter: (options) => CallService(GET, `${SUPERADMIN_BASE_URL}/app/filter`, options),
      get_single_app: (options) =>
        CallService(GET, `${SUPERADMIN_BASE_URL}/app/get_single_app`, options),
    },
    app_token: {
      get: (options) => CallService(GET, `${SUPERADMIN_BASE_URL}/app_token`, options),
      update: (options) => CallService(PUT, `${SUPERADMIN_BASE_URL}/app_token`, options),
      create: (options) => CallService(POST, `${SUPERADMIN_BASE_URL}/app_token`, options),
      save_credentials: (options) =>
        CallService(POST, `${SUPERADMIN_BASE_URL}/app_token/save_credentials`, options),
      snapshot_onboarding_token: (options) =>
        CallService(POST, `${SUPERADMIN_BASE_URL}/app_token/snapshot_onboarding_token`, options),
      delete: (options) => CallService(DELETE, `${SUPERADMIN_BASE_URL}/app_token`, options),
      googleAccessToken: (options) =>
        CallService(GET, `${SUPERADMIN_BASE_URL}/app_token/google/access-token`, options),
      filter: (options) => CallService(GET, `${SUPERADMIN_BASE_URL}/app_token/filter`, options),
      get_credentials: (options) =>
        CallService(GET, `${SUPERADMIN_BASE_URL}/app_token/get_credentials`, options),
    },
    brand: {
      get: (options) => CallService(GET, `${BASE_URL}/brand`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/brand`, options),
      filter: (options) => CallService(GET, `${BASE_URL}/brand/filter`, options),
    },
    snapshot_onboarding: {
      get: (options) => CallService(GET, `${BASE_URL}/snapshot_onboarding`, options),
      app_token_snapshot: (options) =>
        CallService(GET, `${BASE_URL}/snapshot_onboarding/app_token`, options),
      create: (options) => CallService(POST, `${BASE_URL}/snapshot_onboarding`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/snapshot_onboarding`, options),
      filter: (options) => CallService(GET, `${BASE_URL}/snapshot_onboarding/filter`, options),
      get_app_dashboard: (options) =>
        CallService(GET, `${BASE_URL}/snapshot_onboarding/get_app_dashboard`, options),
      get_app_locations: (options) =>
        CallService(GET, `${BASE_URL}/snapshot_onboarding/get_app_locations`, options),
      get_app_companies: (options) =>
        CallService(GET, `${BASE_URL}/snapshot_onboarding/get_app_companies`, options),
      get_app_users: (options) =>
        CallService(GET, `${BASE_URL}/snapshot_onboarding/get_app_users`, options),
      get_main_dashboard_data: (options) =>
        CallService(GET, `${BASE_URL}/snapshot_onboarding/get_main_dashboard_data`, options),
      delete: (options) => CallService(DELETE, `${BASE_URL}/snapshot_onboarding/delete`, options),
    },

    agency: {
      get: (options) => CallService(GET, `${BASE_URL}/agency`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/agency`, options),
    },
    servey: {
      get: (options) => CallService(GET, `${BASE_URL}/servey`, options),
      Active: (options) => CallService(POST, `${BASE_URL}/servey/active`, options),
      disable: (options) => CallService(DELETE, `${BASE_URL}/servey/disable`, options),
      Check: (options) => CallService(GET, `${BASE_URL}/servey/check`, options),
      getstatus: (options) => CallService(GET, `${BASE_URL}/servey/getstatus`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/servey`, options),
      create: (options) => CallService(POST, `${BASE_URL}/servey`, options),
      delete: (options) => CallService(DELETE, `${BASE_URL}/servey`, options),
      filter: (options) => CallService(GET, `${BASE_URL}/servey/filter`, options),
      config_data: (options) => CallService(GET, `${BASE_URL}/servey/config_data`, options),
      update_customvalue: (options) =>
        CallService(POST, `${BASE_URL}/servey/update_customvalue`, options),
      levelupstore: (options) => CallService(POST, `${BASE_URL}/servey/levelupstore`, options),
      delete_form: (options) => CallService(DELETE, `${BASE_URL}/servey/levelupstore`, options),
      // create_form: (options) => CallService(POST, `${BASE_URL}/servey/levelupstore`, options),
      get_levelupstore: (options) => CallService(GET, `${BASE_URL}/servey/levelupstore`, options),
      get_levelupstore_forms: (options) =>
        CallService(GET, `${BASE_URL}/servey/levelupstore/all`, options),
      update_levelupstore: (options) =>
        CallService(PUT, `${BASE_URL}/servey/levelupstore`, options),
      update_cv_levelupstore: (options) =>
        CallService(POST, `${BASE_URL}/servey/levelupstore/custom_value`, options),
    },
    calendars: {
      get: (options) => CallService(GET, `${BASE_URL}/calendar`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/calendar`, options),
      create: (options) => CallService(POST, `${BASE_URL}/calendar`, options),
      delete: (options) => CallService(DELETE, `${BASE_URL}/calendar`, options),
      filter: (options) => CallService(GET, `${BASE_URL}/calendar/filter`, options),
      GetCalendars: (options) => CallService(GET, `${BASE_URL}/calendar/GetCalendars`, options),
      GetCalendarsData: (options) =>
        CallService(GET, `${BASE_URL}/calendar/GetCalendarsData`, options),
    },
    services: {
      ghl: {
        call_service: (options) => CallService(POST, `${BASE_URL}/services/ghl/`, options),
      },
    },
  }

  return APIs
}

export { useAppServices, useCallService }
