import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import InputBase from '@material-ui/core/InputBase';
import { FormControl, FormHelperText } from '@material-ui/core';
import { NativeSelect } from '@material-ui/core';

import { ColorSchemeCode } from '../enums/ColorScheme';

const BootstrapInput = withStyles((theme) => ({
  input: {
    position       : 'relative',
    borderRadius   : props => props.borderRadius || 8,
    position       : 'relative',
    color          : props => props.color,
    backgroundColor: props => props.backgroundColor ||  ColorSchemeCode.cffffff,
    border         : props => props.border || `1px solid ${ColorSchemeCode.textFieldBorderColor}`,
    fontSize       : props => props.fontSize || 14,
    padding        : props => props.padding || '10px 26px 10px 12px',
    height         : props => props.height || 24,
    transition     : theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: 'Poppins',
    '&:focus': {
        borderRadius   : 8,
        backgroundColor: props => props.backgroundColor || ColorSchemeCode.cffffff,
        border         : props => props.border || `1px solid ${ColorSchemeCode.themeColor}`
    },
  },
}))(InputBase);

const useStyleInputLabelElement = makeStyles(() => ({
    root: {
      fontFamily: 'Poppins',
      fontWeight: 600,
      paddingBottom: 4,
      paddingLeft: 8,
      color : ColorSchemeCode.c4F4F4F,
  
    }
}))

const useStyleHelperTextElement = makeStyles(() => ({
    root: {
      fontSize: '1.1rem',
      padding: '0px 26px 0px 12px'
    },
    contained: {
      marginLeft: '20px',
    },
}))

export default function CustomSelect({options, helperText, error, label, value, onChange, ...props}) {
    const inputLabel        = useStyleInputLabelElement()
    const helperTextClasses = useStyleHelperTextElement()
  
    const MyNativeSelect = withStyles({
      icon: {
        color: props => props.color || 'black',
        fontSize: '25px'
      }
    })(NativeSelect);

  return (
    <>
        <InputLabel classes={inputLabel} shrink htmlFor="bootstrap-input" >{label}</InputLabel>
        <FormControl className="w-100 position-relative" error>
            <MyNativeSelect
                {...props}
                value      = {value}
                onChange   = {onChange}
                input      = {<BootstrapInput props={props}/>}
                inputProps = {props.inputProps}
                className  = "w-inherit"
            >
            {options}
            </MyNativeSelect> 
             {error && <FormHelperText classes={helperTextClasses}>{helperText}</FormHelperText>}
        </FormControl>
    </>
  );
}
