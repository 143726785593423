import React, { useEffect, useState } from 'react'
import stylesheet, { Styles } from '../../../../../templates/stylesheet'
import { useParams } from 'react-router-dom'
import MDProgressCircular from 'components/MDProgressCircular'
import axios from 'axios'
import env from 'config'
import { useAppServices } from 'hook/services'

const convertCSSToReactStyle = (cssText) => {
  if (!cssText) return {}
  if (!cssText.trim()) return {}

  // Split the CSS into individual rules
  const rules = cssText.split(';').filter((rule) => rule.trim())

  const reactStyle = {}

  rules.forEach((rule) => {
    if (!rule.includes(':')) return

    // Split into property and value
    let [property, value] = rule.split(':').map((part) => part.trim())

    // Convert kebab-case to camelCase
    property = property.replace(/-([a-z])/g, (g) => g[1].toUpperCase())

    // Remove px unit if it's a number with px
    if (value.endsWith('px')) {
      const numericValue = parseFloat(value)
      if (!isNaN(numericValue)) {
        value = numericValue
      }
    }

    reactStyle[property] = value
  })

  return reactStyle
}

const handleStepperActiveElement = (element) => {
  element.active = false
  element.steps.forEach((step) => {
    step.active = false
    if (step.childrens)
      step.childrens.forEach((child) => {
        if (child.type == 'stepper') handleStepperActiveElement(child)
        disableActiveElement(child)
      })
  })
  disableActiveElement(element.buttonContainerProps)
  disableActiveElement(element.prevButtonProps)
  disableActiveElement(element.nextButtonProps)
}

const disableActiveElement = (element) => {
  element.active = false
  if (element.childrens)
    element.childrens.forEach((child) => {
      if (child.type == 'stepper') handleStepperActiveElement(child)
      disableActiveElement(child)
    })
}

const getFormJSON = (parent) => {
  const form = parent.childrens.filter((element) => {
    if (element.type === 'form') return true
    if (element.childrens) return getFormJSON(element)
  })

  return form.length === 0 ? false : form
}

const getBlock = ({ element, childIndex, survey }) => (
  <div
    key={childIndex}
    className={`block cd`}
    style={{ ...Styles.block, ...element.styles, ...convertCSSToReactStyle(element.css) }}
  >
    {element.childrens && <GetChildrenElements parent={element} survey={survey} />}
  </div>
)

const getImage = ({ element, childIndex }) => (
  <img
    key={childIndex}
    className={`element cd`}
    src={element.src}
    style={{ ...element.styles, ...convertCSSToReactStyle(element.css) }}
    alt={childIndex}
  />
)

const GetTextBlock = ({ element, childIndex }) => (
  <div
    key={childIndex}
    className={`element cd`}
    style={{ ...element.styles, ...convertCSSToReactStyle(element.css) }}
  >
    {element.innerText}
  </div>
)

const getLink = ({ element, childIndex }) => (
  <a
    key={childIndex}
    className={`element cd`}
    href={element.href}
    style={{ ...element.styles, ...Styles.a, ...convertCSSToReactStyle(element.css) }}
  >
    {element.innerText}
  </a>
)

const getButton = ({ element, childIndex, onClick, loading, disabled }) => (
  <button
    key={childIndex}
    onClick={(e) => {
      onClick && onClick(e)
    }}
    className={`element cd ${disabled && 'disabled'}`}
    style={{ ...element.styles, ...Styles.button, ...convertCSSToReactStyle(element.css) }}
    type={element.elementType}
    disabled={loading}
  >
    {loading ? <MDProgressCircular size={14} /> : element.innerText}
  </button>
)
const getDivider = ({ element, childIndex }) => (
  <div
    key={childIndex}
    className={`element divider cd`}
    style={{ ...element.styles, ...Styles.divider, ...convertCSSToReactStyle(element.css) }}
  ></div>
)

const getLabel = ({ element, childIndex }) => (
  <label
    key={childIndex}
    className={`element cd`}
    htmlFor={element.inputName}
    style={{ ...element.styles, ...convertCSSToReactStyle(element.css) }}
  >
    {element.innerText}
  </label>
)

const getInput = ({ element, childIndex }) => {
  const [value, setValue] = useState(element.value)
  const [file, setFile] = useState(null)

  const handleChange = (e) => {
    console.log(e.target.type, e.target.value)
    if (e.target.type === 'file') {
      setFile(e.target.files[0])
      setValue(e.target.files[0].name)
      return
    }
    if (e.target.type === 'checkbox') {
      setValue(e.target.checked)
      return
    }

    setValue(e.target.value)
  }

  return (
    <input
      key={childIndex}
      className={`element cd`}
      name={element.inputName}
      id={element.inputName}
      // file={file}
      // value={value}
      onChange={handleChange}
      style={{ ...element.styles, ...convertCSSToReactStyle(element.css) }}
      placeholder={element.placeholder}
      type={element.inputType}
      required={element.required}
      data-field-id={element.inputName}
    />
  )
}

const getForm = ({ element, childIndex, survey }) => {
  const [loading, setLoading] = useState(false)
  const params = useParams()
  const BASE_URL = `${env.API_URL}/v1`
  const Service = useAppServices()

  const uploadFile = async (file) => {
    const form_data = new FormData()
    form_data.append('image', file, file.name)
    return await axios
      .post(
        // `${BASE_URL}/services/ghl/file_upload?agency_id=${params.agency_id}&location_id=${params.loc_id}&form_id=${params.id}`,
        `${BASE_URL}/utils/upload/image`,
        form_data
      )
      .then((response) => {
        console.log(response.data.data.data, 'response')
        return response.data.data
      })
  }

  const handleSubmit = async (e) => {
    try {
      setLoading(true)
      e.stopPropagation()
      e.preventDefault()
      console.log(e.target)
      const form = e.target
      const formData = new FormData(form)
      const formDataObject = Object.fromEntries(formData.entries())

      const checkBoxes = Object.keys(formDataObject).filter((key) => {
        return form.querySelector(`[name="${key}"]`).type === 'checkbox'
      })
      checkBoxes.forEach((key) => {
        formDataObject[key] = form.querySelector(`[name="${key}"]`).checked
      })

      const fileKey = Object.keys(formDataObject).find((key) => {
        return form.querySelector(`[name="${key}"]`).type === 'file'
      })
      if (fileKey && formDataObject[fileKey]) {
        await uploadFile(formDataObject[fileKey])
      }
      delete formDataObject[fileKey]

      const payload = {
        formValues: { ...formDataObject },
        form_id: params.id,
        location_id: survey.location.value,
        agency_id: params.agency_id,
      }
      console.log(payload, 'payload')
      const { response } = await Service.servey.update_customvalue({
        payload,
      })

      console.log()

      setLoading(false)
    } catch (error) {
      alert('error in form submission')
      console.log(error)
      setLoading(false)
    }
  }

  return (
    <form
      id={element.submitAction}
      key={childIndex}
      className={`element cd`}
      style={{ ...element.styles, ...convertCSSToReactStyle(element.css) }}
      onSubmit={handleSubmit}
    >
      {element.childrens && GetChildrenElements({ parent: element, survey, loading })}
    </form>
  )
}

const getStepper = ({ element, childIndex, survey }) => {
  const [currentStep, setCurrentStep] = useState(0)
  const [loading, setLoading] = useState(false)
  const params = useParams()
  const BASE_URL = `${env.API_URL}/v1`
  const Service = useAppServices()

  const handleNext = () => {
    if (currentStep < element.steps.length - 1) {
      setCurrentStep((prev) => prev + 1)
    }
  }

  const uploadFile = async (file) => {
    const form_data = new FormData()
    form_data.append('image', file, file.name)
    return await axios
      .post(
        // `${BASE_URL}/services/ghl/file_upload?agency_id=${params.agency_id}&location_id=${params.loc_id}&form_id=${params.id}`,
        `${BASE_URL}/utils/upload/image`,
        form_data
      )
      .then((response) => {
        console.log(response.data.data.data, 'response')
        return response.data.data
      })
  }

  const handleSubmit = async (e) => {
    try {
      setLoading(true)
      e.stopPropagation()
      e.preventDefault()
      console.log(e.target)
      const form = e.target
      const formData = new FormData(form)
      const formDataObject = Object.fromEntries(formData.entries())

      const checkBoxes = Object.keys(formDataObject).filter((key) => {
        return form.querySelector(`[name="${key}"]`).type === 'checkbox'
      })
      checkBoxes.forEach((key) => {
        formDataObject[key] = form.querySelector(`[name="${key}"]`).checked
      })

      const fileKey = Object.keys(formDataObject).find((key) => {
        return form.querySelector(`[name="${key}"]`).type === 'file'
      })
      if (fileKey && formDataObject[fileKey]) {
        await uploadFile(formDataObject[fileKey])
      }
      delete formDataObject[fileKey]

      const payload = {
        formValues: { ...formDataObject },
        form_id: params.id,
        location_id: survey.location.value,
        agency_id: params.agency_id,
      }
      console.log(payload, 'payload')
      const { response } = await Service.servey.update_customvalue({
        payload,
      })

      console.log()

      setLoading(false)
      handleNext()
    } catch (error) {
      alert('error in form submission')
      console.log(error)
      setLoading(false)
    }
  }

  const handleBack = (e) => {
    e.stopPropagation()
    e.preventDefault()
    if (currentStep > 0) {
      setCurrentStep((prev) => prev - 1)
    }
  }

  return (
    <form
      id={element.submitAction}
      key={childIndex}
      className={`element cd`}
      style={{ ...element.styles, ...convertCSSToReactStyle(element.css) }}
      onSubmit={handleSubmit}
    >
      <div className={`stepper cd`}>
        {element.steps.map((step, index) => {
          const isCompleted = index < currentStep
          const isCurrent = index === currentStep

          return (
            <div key={index} className="stepWrapper">
              <div
                onClick={() => onStepClick(index)}
                className="stepCircle"
                style={{
                  backgroundColor: isCompleted
                    ? element.bgCompleteColor
                    : isCurrent
                    ? element.bgActiveColor
                    : element.bgStepColor,
                  color: isCompleted
                    ? element.textCompleteColor
                    : isCurrent
                    ? element.textActiveColor
                    : element.textStepColor,
                }}
              >
                {isCompleted ? (
                  <svg className="checkIcon" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                ) : (
                  index + 1
                )}
              </div>

              {index < element.steps.length - 1 && (
                <div
                  className="connector"
                  style={{
                    backgroundColor: isCompleted ? element.bgCompleteColor : element.bgStepColor,
                  }}
                />
              )}

              <div
                className="stepLabel"
                style={{
                  color: isCompleted || isCurrent ? element.bgCompleteColor : element.bgStepColor,
                }}
              >
                {step.stepLabel}
              </div>
            </div>
          )
        })}
      </div>
      <div className="stepContent">
        <div
          className={`block cd ${element.steps[currentStep]?.active && 'focused'}`}
          style={{ ...Styles.block, ...element.steps[currentStep].styles }}
        >
          {element.steps[currentStep]?.childrens &&
            GetChildrenElements({
              parent: element.steps[currentStep],
              survey,
            })}
        </div>
      </div>

      <div
        className={`block cd `}
        style={{ ...Styles.block, ...element.buttonContainerProps.styles }}
      >
        {getButton({
          element: element.prevButtonProps,
          childIndex: 0,
          onClick: handleBack,
        })}
        {getButton({
          element: element.nextButtonProps,
          childIndex: 1,
          loading,
        })}
      </div>
    </form>
  )
}

const getSelect = ({ element, childIndex }) => (
  <>
    <select
      key={childIndex}
      className={`element cd`}
      name={element.name}
      id={element.id}
      style={{ ...element.styles, ...convertCSSToReactStyle(element.css) }}
    >
      {element.options.map((option, index) => (
        <option key={index} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  </>
)

const getTextarea = ({ element, childIndex }) => (
  <textarea
    key={childIndex}
    className={`element cd`}
    name={element.inputName}
    id={element.inputName}
    style={{ ...element.styles, ...convertCSSToReactStyle(element.css) }}
    placeholder={element.placeholder}
    required={element.required}
    data-field-id={element.inputName}
  />
)

const getVideo = ({ element, childIndex }) => (
  <video
    key={childIndex}
    className={`element cd`}
    style={{ ...element.styles, ...convertCSSToReactStyle(element.css) }}
    controls={element.controls}
    autoPlay={element.autoPlay}
    loop={element.loop}
    muted={element.muted}
    playsInline={element.playsInline}
    src={element.src}
  ></video>
)

const elements = {
  block: getBlock,
  image: getImage,
  heading: GetTextBlock,
  paragraph: GetTextBlock,
  link: getLink,
  button: getButton,
  divider: getDivider,
  label: getLabel,
  input: getInput,
  form: getForm,
  stepper: getStepper,
  select: getSelect,
  textarea: getTextarea,
  video: getVideo,
}

const GetChildrenElements = ({ parent, survey, loading }) =>
  parent.childrens.map((child, childIndex) =>
    elements[child.type]({
      element: child,
      childIndex,
      survey,
      loading,
    })
  )

function EditorPane({ state, survey }) {
  const getFonts = (element) => {
    const fonts = []
    element.styles.fontFamily && fonts.push(element.styles.fontFamily)

    const filterFont = (childrens) =>
      childrens.map((element) => {
        element.styles?.fontFamily && fonts.push(element.styles.fontFamily)
        if (element.childrens) filterFont(element.childrens)
      })

    element.childrens && filterFont(element.childrens)

    return fonts
  }

  return (
    <>
      <div id="Preview" className="w-100 p-0">
        {getFonts(state.values.json).map((font) => (
          <link
            href={`https://fonts.googleapis.com/css2?family=${font.replace(
              ' ',
              '+'
            )}:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,500;1,700&display=swap`}
            rel="stylesheet"
          ></link>
        ))}
        <style>{stylesheet}</style>
        <div className={`body`} style={{ ...Styles.body, ...state.values.json?.styles }}>
          {state.values.json.childrens && (
            <GetChildrenElements parent={state.values.json} survey={survey} />
          )}
        </div>
      </div>
    </>
  )
}

export default EditorPane
