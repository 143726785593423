import React, { useEffect } from 'react'

function Preview({html}) {
    const onLoad = () => document.getElementById('Preview').innerHTML = html

    useEffect(onLoad, [])

    return (
       <div id="Preview" className="w-100 preview"></div>
    )
}

export default Preview
