import React, { useEffect, useState } from 'react'
import EditorPane from './EditorPane'
import LeftPane from './LeftPane'
import RightPane from './RightPane'
import { PngIcons, SvgIcons } from '../icons'
import Preview from './Preview'
import { ColorSchemeCode } from '../enums/ColorScheme'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppServices } from 'hook/services'
import Loader from 'examples/Loader'
import MDProgressCircular from 'components/MDProgressCircular'
import FitContentInput from 'components/FitContentInput'

function TemplateDesign({ onChange, ...props }) {
  const initState = {
    json: {
      name: 'body',
      type: 'body',
      styles: {},
      childrens: [],
    },
    html: '',
  }

  const [preview, setPreview] = useState(false)
  const [focused, setFocused] = useState({})
  const [settings, setSettings] = useState('navigator')
  const [state, setState] = useState({ ...initState })
  const [processing, setProcessing] = useState(true)
  const params = useParams()
  const Service = useAppServices()
  const navigate = useNavigate()

  const getSurvey = async () => {
    const { response } = await Service.servey.get({
      query: `_id=${params.id}`,
    })

    if (response?.data?.customization?.length) {
      navigate(`/${params.company_id}/${params.app_id}/survey/${params.id}/v1`)
    }

    if (response?.data?.design) {
      setState({ ...response.data?.design })
    }
    setProcessing(false)
  }

  useEffect(async () => {
    getSurvey()
  }, [])

  return (
    <div id="TemplateDesign" className="w-100 pl-15 pr-15">
      {/* {focused.element && focused.top && <div className="element-referer" style={{top: parseInt(focused.top)-99 + 'px', left: parseInt(focused.left)-138 + 'px'}}>{focused.element.type}</div>} */}
      {processing ? (
        <div className="middle full-height">
          <MDProgressCircular />
        </div>
      ) : (
        <div className="row full-height">
          <Header
            preview={{ value: preview, setPreview }}
            focused={{ value: focused, setFocused }}
            state={{ values: state, setState }}
          />
          {!preview && (
            <LeftPane
              state={{ values: state, setState }}
              focused={{ value: focused, setFocused }}
              settings={{ value: settings, setSettings }}
            />
          )}
          {!preview && (
            <EditorPane
              preview={preview}
              state={{ values: state, setState }}
              focused={{ value: focused, setFocused }}
              settings={{ value: settings, setSettings }}
              onChange={onChange}
            />
          )}
          {!preview && (
            <RightPane
              state={{ values: state, setState }}
              focused={{ value: focused, setFocused }}
            />
          )}

          {preview && <Preview html={state.html} />}
        </div>
      )}
    </div>
  )
}

const Header = ({ preview, state }) => {
  const [title, setTitle] = useState('My Custom Form')
  const [open, setOpen] = useState(false)
  const [processing, setProcessing] = useState(false)
  const params = useParams()
  const Service = useAppServices()
  const navigate = useNavigate()

  const handlePreview = () => {
    const html = document.getElementById('EditorPane')
    state.setState({ ...state.values, html: html && html.innerHTML })
    preview.setPreview(!preview.value)
  }

  const downloadHtml = (e) => {
    e.preventDefault()
    var dataStr = 'data:text/html;charset=utf-8,' + encodeURIComponent(state.values.html)
    var dlAnchorElem = document.createElement('a')
    dlAnchorElem.setAttribute('href', dataStr)
    dlAnchorElem.setAttribute('download', 'sample.html')
    dlAnchorElem.click()
  }

  const handleSaveDesign = async () => {
    setProcessing(true)
    const payload = {
      _id: params.id,
      design: state.values,
    }
    const { response } = await Service.servey.update({ payload })
    setProcessing(false)

    if (response) navigate(-1)
  }

  return (
    <div className="widgetHeader">
      <div className="viewandDownloadButtons ml-8">
        {!preview.value && (
          <>
            <span className="ml-64 cp" onClick={() => navigate(-1)}>
              <SvgIcons.ArrowUnExpand
                height="14px"
                color={preview.value && ColorSchemeCode.themeColor}
                style={{ transform: 'rotate(90deg)' }}
              />
            </span>
            <div className="ml-16 cp d-inline-block" onClick={handleSaveDesign}>
              {processing ? (
                <MDProgressCircular size={16} />
              ) : (
                <SvgIcons.SaveIcon color={preview.value && ColorSchemeCode.themeColor} />
              )}
            </div>
          </>
        )}
        {preview.value ? (
          <span className="ml-64 cp" onClick={handlePreview}>
            <SvgIcons.ArrowUnExpand
              height="14px"
              color={preview.value && ColorSchemeCode.themeColor}
              style={{ transform: 'rotate(90deg)' }}
            />
          </span>
        ) : (
          <span className="ml-16 cp" onClick={handlePreview}>
            <SvgIcons.EyeIcon height="14px" color={preview.value && ColorSchemeCode.themeColor} />
          </span>
        )}

        {/* <div className="ml-16 cp d-inline-block" onClick={downloadHtml}>
          <SvgIcons.DownloadIcon />
        </div> */}
      </div>

      <div className="widgetTitle">
        <FitContentInput
          icon={{ color: 'inherit', width: '12px', class: 'middle' }}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>
    </div>
  )
}

export default TemplateDesign
