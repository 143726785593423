import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';

import { ColorSchemeCode } from '../enums/ColorScheme';

const useStylesElement = makeStyles((props) => ({
  root: {
    overflow: 'hidden',
    border: props => props.border || '1px solid ' + ColorSchemeCode.textFieldBorderColor,
    height: props => props.height || '46px',
    color: props => props.color || ColorSchemeCode.c828282,
    borderRadius: props => props.borderRadius || 8,
    fontWeight: 400,
    backgroundColor: props=> props.backgroundColor || ColorSchemeCode.cffffff,
    fontSize: props => props.fontSize || '15px',
    fontFamily: 'Poppins',
    '&:hover': {
      backgroundColor: ColorSchemeCode.cffffff,
      border: '1px solid ' + ColorSchemeCode.c2F80ED + '!important',
    },
    '&$focused': {
      backgroundColor: ColorSchemeCode.cffffff,
      border: '1px solid ' + ColorSchemeCode.c2F80ED + '!important',
      borderColor: ColorSchemeCode.c2F80ED + '!important',
      color: ColorSchemeCode.textfieldColor
    },
  },
  error: {
    border: '1px solid #f44336 !important',
  },
  focused: {},
}));

const useStylesInputElement = makeStyles((props) => ({
  root: {
    padding: "10px 16px",
    paddingLeft : props => props.paddingLeft + ' !important' || '16px',
    '&[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': props => props.hideArrow ? '' : 'none',
      margin: 0,
    },
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': props => props.hideArrow ? '' : 'none',
      margin: 0,
    },
  }
  
}));

const useStylesLabelElement = makeStyles(() => ({
  root: {
    // paddingLeft: '8px',
  }
}));

const useStyleHelperTextElement = makeStyles(() => ({
  root: {
    fontSize: '1.1rem'
  },
  contained: {
    marginLeft: '20px'
  }
}))

const useStyleInputLabelElement = makeStyles(() => ({
  root: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    paddingBottom: 4,
    paddingLeft: 8,
    color : ColorSchemeCode.c4F4F4F,
  }
}))

const InputAdorment = (props) => {
  return (
    <InputAdornment position={props.position}>
      {props.icon}
    </InputAdornment>
  )
}

export default function CustomTextField({label, ...props}) {
  const classes      = useStylesElement(props);
  const inputClasses = useStylesInputElement(props);
  const labelClasses = useStylesLabelElement();
  const helperText   = useStyleHelperTextElement()
  const inputLabel   = useStyleInputLabelElement()

  const InputProps = {
    classes,
    disableUnderline : true,
    startAdornment   : props.icon && props.position === 'start' ? <InputAdorment position={props.position} icon={props.icon}/>                : '',
    endAdornment     : props.icon && props.position === 'end' ? <InputAdorment position={props.position} icon={props.icon} className="copy"/> : ''
  }

  return <>
    <InputLabel classes = {inputLabel} shrink htmlFor = "bootstrap-input">{label}</InputLabel>
    <TextField
      {...props}
      variant             = "filled"
      type                = {props.type}
      defaultValue        = {props.value}
      placeholder         = {props.placeholder}
      onClick             = {props.onClick}
      onChange            = {props.onChange}
      inputProps          = {{ className: inputClasses.root, ...props.inputProps }}
      InputLabelProps     = {{ className: labelClasses.root }}
      InputProps          = {InputProps}
      FormHelperTextProps = {{classes: helperText}}
    />
  </>;
}



