import React, { useState } from 'react'
import { SvgIcons } from '../icons'

import { ClickOutside, utils } from '../utils'

function FitContentInput({className, onFocus, onChange, value, icon = {}}) {
    const [edit, setEdit] = useState(false)

    const handleChange = (e) => {
        utils.setCarretPosition(e.target)
        onChange(e.target.innerText)
    }

    const EditStatus = () => {
        setEdit(true)
        utils.setCarretPosition(document.getElementById('edit-content'))
    }

    return (
        <div id="FitContentInput">
            <ClickOutside onClick={()=>setEdit(false)}>
                <div className="d-flex justify-content-center fs-11">
                    <div 
                        id                             = {'edit-content'}
                        className                      = {`mt-1 ${className} ${edit && 'bottom-border'}`}
                        suppressContentEditableWarning = {true}
                        contentEditable                = {edit}
                        tabIndex                       = "-1"
                        onInput                        = {handleChange}
                        onFocus                        = {onFocus}
                    >{value}</div>
                    
                    <div className={icon.class} onClick={EditStatus}>
                        <SvgIcons.EditIcon 
                            height    = {icon.width}
                            width     = {icon.heigth}
                            color     = {icon.color}
                        />
                    </div>
                </div>
            </ClickOutside>
        </div>
    )
}

export default FitContentInput
