import env from 'config'
import { useAgencyInfo } from 'context/agency'
import { useAppServices } from 'hook/services'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Preview from './Preview-levelupstore'
import Loader from 'examples/Loader'

function TemplatePerformer() {
  const Service = useAppServices()
  const { company_id, app_id, form_id } = useParams()
  const [loader, setloader] = useState(true)
  const [form, setForm] = useState({})
  const [totalSteps, setTotalSteps] = useState(0)
  console.log(form.design, 'form desighn')
  const getTotalSteps = (element) => {
    if (element.type === 'stepper') {
      return setTotalSteps(element.steps.length)
    } else {
      element.childrens &&
        element.childrens.map((child) => {
          getTotalSteps(child)
        })
    }
  }

  const getDesign = async () => {
    const { response } = await Service.servey.get_levelupstore({
      query: `company_id=${company_id}&app_id=${app_id}&form_id=${form_id}`,
    })

    if (response?.data) {
      setForm(response.data)
    }

    setloader(false)
  }

  const onLoad = () => {
    getDesign()
  }
  useEffect(async () => {
    onLoad()
  }, [])

  return (
    <div>
      {loader ? (
        <div>
          <Loader />
        </div>
      ) : (
        <Preview state={{ values: form.design }} survey={form} />
      )}
    </div>
  )
}

export default TemplatePerformer
