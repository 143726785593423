import axios from 'axios'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'
import env from 'config'
import { useAgencyInfo } from 'context/agency'
import Loader from 'examples/Loader'
import { useAppServices } from 'hook/services'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

const Default = () => {
  const BASE_URL = `${env.API_URL}/v1`
  const Service = useAppServices()
  const params = useParams()
  const [agency] = useAgencyInfo()
  const [servey_data, setservey_data] = useState({})
  const [loader, setloader] = useState(true)
  const [index, setindex] = useState(0)
  const getSurvey = async () => {
    const { response } = await Service.servey.get({
      query: `_id=${params.id}`,
    })
    console.log(response, 'getSurvey')
    if (response) {
      getSurveycheck(response.data)
      setservey_data(response.data)
    } else {
      setloader(false)
    }
  }
  const getSurveycheck = async (data) => {
    const { response } = await Service.servey.Check({
      query: `form_id=${params.id}&location_id=${params.loc_id}`,
    })
    console.log(response, 'getSurveyChecks')
    if (response) {
      // setservey_data(response.data);
      setloader(false)
    } else {
      // window.location.href = data?.form_settings?.default_url;
      // setloader(false);
    }
  }
  const onLoad = () => {
    getSurvey()
  }
  useEffect(async () => {
    onLoad()
  }, [])
  const handleSubmit = async (e) => {
    e.preventDefault()

    const formData = new FormData(e.target)

    const formValues = {}
    for (let [name, value] of formData.entries()) {
      const inputField = e.target.querySelector(`[name="${name}"]`)
      const field_key = inputField.getAttribute('field-key')
      if (inputField && inputField.type === 'file') {
        console.log(inputField.getAttribute('field-key'), 'getAttribute')
        const file = e.target.querySelector(`[name="${name}"]`).files[0]
        const form_data = new FormData()
        form_data.append('image', file, file.name)
        console.log(form_data.get('image'), 'fferg')
        await axios
          .post(
            `${BASE_URL}/services/ghl/file_upload?agency_id=${params.agency_id}&location_id=${params.loc_id}&form_id=${params.id}`,
            form_data
          )
          .then((response) => {
            formValues[name] = {
              field_key: field_key,
              value: response.data.data.data,
            }
            return response.data.data
          })
      } else {
        formValues[name] = {
          field_key: field_key,
          value: value,
        }
      }
    }
    const payload = {
      formValues: formValues,
      form_id: params.id,
      location_id: servey_data.location.value,
      agency_id: params.agency_id,
    }
    console.log(payload, 'payload')
    const { response } = await Service.servey.update_customvalue({
      payload,
    })
    console.log(response, 'response')
  }
  return (
    <MDBox
      sx={{
        background: servey_data?.form_settings?.page_bg || 'inheriat',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: '100vh',
        height: '100%',
        width: '100%',
      }}
    >
      {loader ? (
        <Loader />
      ) : (
        <MDBox
          component="form"
          onSubmit={handleSubmit}
          id="main_form"
          sx={{
            width: 500,
            height: 'auto',
            // maxHeight: "90vh",
            overflow: 'auto',
            bgcolor: servey_data?.form_settings?.form_bg || 'background.paper',
            boxShadow: 1,
            p: 4,
            mb: 2,
          }}
        >
          {servey_data?.customization?.map((item, slideindex) => (
            <MDBox
              sx={{
                minHeight: index == slideindex ? 'auto' : '0px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <div>
                {slideindex == 0 ? (
                  <>
                    {servey_data?.form_settings?.logo_text != '' &&
                    servey_data?.form_settings?.logo_text != undefined ? (
                      <MDTypography
                        variant="h5"
                        textAlign="center"
                        display="block"
                        gutterBottom
                        mb={2}
                      >
                        <span
                          style={{
                            fontSize: ` ${servey_data?.form_settings?.logo_font_size}px`,
                          }}
                        >
                          {servey_data?.form_settings?.logo_text}
                        </span>
                      </MDTypography>
                    ) : (
                      <></>
                    )}
                    {servey_data?.form_settings?.logo_image != '' &&
                    servey_data?.form_settings?.logo_image != undefined ? (
                      <MDBox width="100%" display="flex" justifyContent="center">
                        <img
                          src={servey_data?.form_settings?.logo_image}
                          width={servey_data?.form_settings?.image_width || 200}
                          height={servey_data?.form_settings?.image_height || 150}
                          alt="logo"
                        />
                      </MDBox>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
                {item.fields.map((field, fieldindex) => (
                  <>
                    {field?.type == 'image' ? (
                      <MDBox
                        sx={{
                          display: index == slideindex ? 'flex' : 'none',
                        }}
                        width="100%"
                        display="flex"
                        justifyContent="center"
                      >
                        <img
                          src={field?.file}
                          width={field?.settings?.width || 250}
                          height={field?.settings?.height || 200}
                        />
                      </MDBox>
                    ) : field?.type == 'video' ? (
                      <MDBox
                        sx={{
                          display: index == slideindex ? 'flex' : 'none',
                        }}
                        mt={1}
                        width="100%"
                        display="flex"
                        justifyContent="center"
                      >
                        <video
                          controls={true}
                          src={field?.file}
                          width={field?.settings?.width || 250}
                          height={field?.settings?.height || 200}
                        ></video>
                      </MDBox>
                    ) : field?.type == 'simple_text' ? (
                      <MDBox
                        sx={{
                          display: index == slideindex ? 'flex' : 'none',
                        }}
                      >
                        <MDTypography
                          textAlign="center"
                          display="block"
                          gutterBottom
                          sx={{
                            fontSize: field?.settings?.font_size,
                            color: field?.settings?.font_color,
                          }}
                        >
                          {field?.text}
                        </MDTypography>
                      </MDBox>
                    ) : field?.type == 'text-area' ? (
                      <MDBox
                        sx={{
                          display: index == slideindex ? 'block' : 'none',
                        }}
                      >
                        <MDTypography
                          variant="button"
                          display="block"
                          gutterBottom
                          sx={{
                            color: field?.settings?.label_color
                              ? field?.settings.label_color
                              : 'inheriat',
                            fontSize: field?.settings?.font_size
                              ? `${field?.settings.font_size}px`
                              : 'inheriat',
                          }}
                        >
                          <b> {field?.label}</b>
                        </MDTypography>
                        <textarea
                          field-key={field?.custom_value_field?.fieldKey}
                          name={field?.fieldKey}
                          id=""
                          placeholder={field?.placeholder}
                          class="form-control"
                          cols="30"
                          rows="10"
                        ></textarea>
                      </MDBox>
                    ) : field?.type == 'select' ? (
                      <MDBox
                        sx={{
                          display: index == slideindex ? 'block' : 'none',
                        }}
                      >
                        <MDTypography
                          variant="button"
                          display="block"
                          gutterBottom
                          sx={{
                            color: field?.settings?.label_color
                              ? field?.settings.label_color
                              : 'inheriat',
                            fontSize: field?.settings?.font_size
                              ? `${field?.settings.font_size}px`
                              : 'inheriat',
                          }}
                        >
                          <b> {field?.label}</b>
                        </MDTypography>
                        <select
                          field-key={field?.custom_value_field?.fieldKey}
                          className="form-control"
                          name={field?.fieldKey}
                          id=""
                        >
                          {field?.options.map((option) => (
                            <option value={option?.value}>{option?.label}</option>
                          ))}
                        </select>
                      </MDBox>
                    ) : (
                      <div
                        style={{
                          display: index == slideindex ? 'block' : 'none',
                        }}
                        class={`form-group mb-4 w-100`}
                      >
                        <MDTypography
                          variant="button"
                          display="block"
                          sx={{
                            color: field?.settings?.label_color || 'inheriat',
                            fontSize: field?.settings?.font_size
                              ? `${field?.settings.font_size}px`
                              : 'inheriat',
                          }}
                          gutterBottom
                        >
                          <b> {field?.label}</b>
                        </MDTypography>
                        <input
                          type={field?.type == 'custom_value' ? 'text' : field?.type}
                          field-key={field?.custom_value_field?.fieldKey}
                          class="form-control"
                          placeholder={field?.placeholder}
                          name={field?.fieldKey}
                        />
                      </div>
                    )}
                  </>
                ))}
              </div>
              <MDBox>
                {index == slideindex ? (
                  slideindex == servey_data?.customization?.length - 1 ? (
                    <MDBox
                      display="flex"
                      justifyContent={slideindex != 0 ? 'space-between' : 'flex-end'}
                      mt={2}
                    >
                      {slideindex != 0 ? (
                        <MDButton
                          variant="gradient"
                          color="dark"
                          onClick={() => setindex(index - 1)}
                        >
                          back
                        </MDButton>
                      ) : (
                        <></>
                      )}

                      <MDButton variant="gradient" color="dark" type="submit">
                        Save
                      </MDButton>
                    </MDBox>
                  ) : (
                    <MDBox
                      display="flex"
                      justifyContent={slideindex != 0 ? 'space-between' : 'flex-end'}
                      mt={2}
                    >
                      {slideindex != 0 ? (
                        <MDButton
                          variant="gradient"
                          color="dark"
                          onClick={() => setindex(index - 1)}
                        >
                          back
                        </MDButton>
                      ) : (
                        <></>
                      )}

                      <MDButton variant="gradient" color="dark" onClick={() => setindex(index + 1)}>
                        next
                      </MDButton>
                    </MDBox>
                  )
                ) : (
                  <></>
                )}
              </MDBox>
            </MDBox>
          ))}
        </MDBox>
      )}
    </MDBox>
  )
}

export default Default
