import React, { useEffect, useState } from 'react'
import env from '../config'
import { uploadImage } from '../utils/utils'
import stylesheet, { Styles } from './stylesheet'
import stepper from 'assets/theme/components/stepper'

const convertCSSToReactStyle = (cssText) => {
  if (!cssText) return {}
  if (!cssText.trim()) return {}

  // Split the CSS into individual rules
  const rules = cssText.split(';').filter((rule) => rule.trim())

  const reactStyle = {}

  rules.forEach((rule) => {
    if (!rule.includes(':')) return

    // Split into property and value
    let [property, value] = rule.split(':').map((part) => part.trim())

    // Convert kebab-case to camelCase
    property = property.replace(/-([a-z])/g, (g) => g[1].toUpperCase())

    // Remove px unit if it's a number with px
    if (value.endsWith('px')) {
      const numericValue = parseFloat(value)
      if (!isNaN(numericValue)) {
        value = numericValue
      }
    }

    reactStyle[property] = value
  })

  return reactStyle
}

const updateDropDown = ({ state, activeElement }) => {
  const updateInheritance = (parent) => {
    const found = parent.childrens.find((element) => {
      if (JSON.stringify(element) === JSON.stringify(activeElement)) return true
      if (element.childrens) {
        const found = updateInheritance(element)
        if (found) element.dropDown = true
        return found
      }
    })
    if (found) return parent
  }

  const parent = updateInheritance(state.values.json)

  if (parent) parent.dropDown = true
  state.setState({ ...state.values })
}

const handleStepperActiveElement = (element) => {
  element.active = false
  element.steps.forEach((step) => {
    step.active = false
    if (step.childrens)
      step.childrens.forEach((child) => {
        if (child.type == 'stepper') handleStepperActiveElement(child)
        disableActiveElement(child)
      })
  })
  disableActiveElement(element.buttonContainerProps)
  disableActiveElement(element.prevButtonProps)
  disableActiveElement(element.nextButtonProps)
}

const disableActiveElement = (element) => {
  element.active = false
  if (element.childrens)
    element.childrens.forEach((child) => {
      if (child.type == 'stepper') handleStepperActiveElement(child)
      disableActiveElement(child)
    })
}

const handleActiveElement = ({ e, element, state, focused }) => {
  e.preventDefault()
  e.stopPropagation()
  element.type === 'input' && e.target.blur()
  const pos = e.target.getBoundingClientRect()

  // const prevElement = focused.value.element
  // if (prevElement) prevElement.active = false

  disableActiveElement(state.values.json)

  element.active = true
  focused.setFocused({ element, top: pos.top, left: pos.left })
  updateDropDown({ activeElement: element, state })
}

const getFormJSON = (parent) => {
  const form = parent.childrens.filter((element) => {
    if (element.type === 'form') return true
    if (element.childrens) return getFormJSON(element)
  })

  return form.length === 0 ? false : form[0]
}

const updateState = (state) => {
  state.setState({ ...state.values })
  settings.setSettings('navigator')
  document.querySelectorAll('.dragover').forEach((item) => item.classList.remove('dragover'))
}

const handleAddInputField = ({ newElement, state, focused }) => {
  const form = getFormJSON(state.values.json)
  form.childrens.splice(form.childrens.length - 1, 0, { ...newElement.props })
  const element = form.childrens[form.childrens.length - 2]
  focused.setFocused({ element })
  updateDropDown(element)
  updateState(state)
}

const onDrop = ({ event, parent, index, state, focused, settings }) => {
  event.stopPropagation()
  const prevActiveElement = focused.value.element
  if (prevActiveElement) prevActiveElement.active = false
  let newElement = JSON.parse(event.dataTransfer.getData('newElement'))
  if (newElement.name === 'input' && getFormJSON(parent))
    return handleAddInputField({
      newElement,
      state,
      focused,
    })
  parent.childrens.splice(index, 0, { ...newElement.props })
  focused.setFocused({ element: parent.childrens[index] })
  updateDropDown({ activeElement: parent.childrens[index], state })
  updateState(state)
}

const getBlock = ({ element, childIndex, parent, parentIndex, state, focused, settings }) => (
  <div
    key={childIndex}
    onClick={(e) => handleActiveElement({ e, element, state, focused })}
    className={`block cd ${element.childrens.length === 0 && 'emptyBlock'} ${
      element.active && 'focused'
    }`}
    onDragOver={(e) => e.preventDefault()}
    onDrop={(e) =>
      onDrop({
        event: e,
        parent: element,
        index: element.childrens.length,
        state,
        focused,
        settings,
      })
    }
    style={{ ...Styles.block, ...element.styles, ...convertCSSToReactStyle(element.css) }}
  >
    {element.childrens && (
      <GetChildrenElements
        parent={element}
        parentIndex={childIndex}
        state={state}
        focused={focused}
        settings={settings}
      />
    )}
  </div>
)

const getImage = ({ element, childIndex, parent, parentIndex, state, focused, settings }) => (
  <img
    key={childIndex}
    onClick={(e) => handleActiveElement({ e, element, state, focused })}
    className={`element cd ${element.active && 'focused'}`}
    onDragOver={(e) => e.preventDefault()}
    onDrop={(e) => onDrop({ event: e, parent, index: childIndex, state, focused, settings })}
    src={element.src}
    style={{ ...element.styles, ...convertCSSToReactStyle(element.css) }}
    alt=""
  />
)

const GetTextBlock = ({ element, childIndex, parent, parentIndex, state, focused, settings }) => (
  <div
    key={childIndex}
    onClick={(e) => handleActiveElement({ e, element, state, focused })}
    className={`element cd ${element.active && 'focused'}`}
    onDragOver={(e) => e.preventDefault()}
    onDrop={(e) => onDrop({ event: e, parent, index: childIndex, state, focused, settings })}
    contentEditable={element.active}
    suppressContentEditableWarning={true}
    onInput={(e) => (element.innerText = e.target.innerText)}
    style={{ ...element.styles, ...convertCSSToReactStyle(element.css) }}
  >
    {element.innerText}
  </div>
)

const getLink = ({ element, childIndex, parent, parentIndex, state, focused, settings }) => (
  <a
    key={childIndex}
    onClick={(e) => handleActiveElement({ e, element, state, focused })}
    className={`element cd ${element.active && 'focused'}`}
    onDragOver={(e) => e.preventDefault()}
    onDrop={(e) => onDrop({ event: e, parent, index: childIndex, state, focused, settings })}
    href={element.href}
    style={{ ...element.styles, ...Styles.a, ...convertCSSToReactStyle(element.css) }}
  >
    {element.innerText}
  </a>
)

const getButton = ({
  element,
  childIndex,
  parent,
  parentIndex,
  state,
  focused,
  settings,
  onClick,
  id,
}) => (
  <button
    key={childIndex}
    id={id}
    onClick={(e) => {
      handleActiveElement({ e, element, state, focused })
      onClick && onClick(e)
    }}
    className={`element cd ${element.active && 'focused'}`}
    onDragOver={(e) => e.preventDefault()}
    onDrop={(e) => onDrop({ event: e, parent, index: childIndex, state, focused, settings })}
    contentEditable={element.active}
    suppressContentEditableWarning={true}
    onInput={(e) => (element.innerText = e.target.innerText)}
    style={{ ...element.styles, ...Styles.button, ...convertCSSToReactStyle(element.css) }}
    type={element.elementType}
  >
    {element.innerText}
  </button>
)
const getDivider = ({ element, childIndex, parent, parentIndex, state, focused, settings }) => (
  <div
    key={childIndex}
    onClick={(e) => handleActiveElement({ e, element, state, focused })}
    className={`element divider cd ${element.active && 'focused'}`}
    onDragOver={(e) => e.preventDefault()}
    onDrop={(e) => onDrop({ event: e, parent, index: childIndex, state, focused, settings })}
    style={{ ...element.styles, ...Styles.divider, ...convertCSSToReactStyle(element.css) }}
  ></div>
)

const getLabel = ({ element, childIndex, parent, parentIndex, state, focused, settings }) => {
  const inputElement = parent.childrens.find((child) => child.type === 'input')
  useEffect(() => {
    element.inputName = inputElement.inputName
    state.setState({ ...state.values })
  }, [inputElement.inputName])
  return (
    <label
      key={childIndex}
      onClick={(e) => handleActiveElement({ e, element, state, focused })}
      className={`element cd ${element.active && 'focused'}`}
      onInput={(e) => (element.innerText = e.target.innerText)}
      onDragOver={(e) => e.preventDefault()}
      onDrop={(e) => onDrop({ event: e, parent, index: childIndex, state, focused, settings })}
      contentEditable={element.active}
      suppressContentEditableWarning={true}
      htmlFor={element.inputName}
      style={{ ...element.styles, ...convertCSSToReactStyle(element.css) }}
    >
      {element.innerText}
    </label>
  )
}

const getInput = ({ element, childIndex, parent, parentIndex, state, focused, settings }) => (
  <input
    key={childIndex}
    onClick={(e) => handleActiveElement({ e, element, state, focused })}
    className={`element cd ${element.active && 'focused'}`}
    onDragOver={(e) => e.preventDefault()}
    onDrop={(e) => onDrop({ event: e, parent, index: childIndex, state, focused, settings })}
    name={element.inputName}
    id={element.inputName}
    value={''} // element.value
    style={{ ...element.styles, ...convertCSSToReactStyle(element.css) }}
    placeholder={element.placeholder}
    type={element.inputType}
    required={element.required}
    data-field-id={element.inputName}
  />
)

const getForm = ({ element, childIndex, parent, parentIndex, state, focused, settings }) => (
  <form
    id={element.submitAction}
    key={childIndex}
    onClick={(e) => handleActiveElement({ e, element, state, focused })}
    className={`element cd ${element.active && 'focused'}`}
    onDragOver={(e) => e.preventDefault()}
    onDrop={(e) => onDrop({ event: e, parent, index: childIndex, state, focused, settings })}
    style={{ ...element.styles, ...convertCSSToReactStyle(element.css) }}
  >
    {element.childrens &&
      GetChildrenElements({ parent: element, parentIndex: childIndex, state, focused, settings })}
  </form>
)

const getStepper = ({ element, childIndex, parent, parentIndex, state, focused, settings }) => {
  const [currentStep, setCurrentStep] = useState(0)

  const handleNext = () => {
    if (currentStep < element.steps.length - 1) {
      setCurrentStep((prev) => prev + 1)
    }
  }

  const handleBack = (e) => {
    e.stopPropagation()
    e.preventDefault()
    if (currentStep > 0) {
      setCurrentStep((prev) => prev - 1)
    }
  }

  return (
    <form
      id={element.submitAction}
      key={childIndex}
      onClick={(e) => handleActiveElement({ e, element, state, focused })}
      className={`element cd ${element.active && 'focused'}`}
      onDragOver={(e) => e.preventDefault()}
      onDrop={(e) => onDrop({ event: e, parent, index: childIndex, state, focused, settings })}
      style={{ ...element.styles, ...convertCSSToReactStyle(element.css) }}
    >
      <div className={`stepper cd`}>
        {element.steps.map((step, index) => {
          const isCompleted = index < currentStep
          const isCurrent = index === currentStep

          return (
            <div key={index} className="stepWrapper">
              <div
                onClick={() => onStepClick(index)}
                className="stepCircle"
                style={{
                  backgroundColor: isCompleted
                    ? element.bgCompleteColor
                    : isCurrent
                    ? element.bgActiveColor
                    : element.bgStepColor,
                  color: isCompleted
                    ? element.textCompleteColor
                    : isCurrent
                    ? element.textActiveColor
                    : element.textStepColor,
                }}
              >
                {isCompleted ? (
                  <svg className="checkIcon" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                ) : (
                  index + 1
                )}
              </div>

              {index < element.steps.length - 1 && (
                <div
                  className="connector"
                  style={{
                    backgroundColor: isCompleted ? element.bgCompleteColor : element.bgStepColor,
                  }}
                />
              )}

              <div
                className="stepLabel"
                style={{
                  color: isCompleted || isCurrent ? element.bgCompleteColor : element.bgStepColor,
                }}
              >
                {step.stepLabel}
              </div>
            </div>
          )
        })}
      </div>
      <div className="stepContent">
        <div
          onClick={(e) =>
            handleActiveElement({ e, element: element.steps[currentStep], state, focused })
          }
          className={`block cd ${element.steps[currentStep]?.active && 'focused'}`}
          style={{ ...Styles.block, ...element.steps[currentStep].styles }}
        >
          {element.steps[currentStep]?.childrens &&
            GetChildrenElements({
              parent: element.steps[currentStep],
              parentIndex: childIndex,
              state,
              focused,
              settings,
            })}
        </div>
      </div>

      <div
        onClick={(e) =>
          handleActiveElement({ e, element: element.buttonContainerProps, state, focused })
        }
        className={`block cd ${element.buttonContainerProps?.active && 'focused'}`}
        style={{ ...Styles.block, ...element.buttonContainerProps.styles }}
      >
        {getButton({
          element: element.prevButtonProps,
          parent: element,
          childIndex: 0,
          parentIndex: childIndex,
          state,
          focused,
          settings,
          onClick: handleBack,
          id: 'prevButton',
        })}
        {getButton({
          element: element.nextButtonProps,
          parent: element,
          childIndex: 1,
          parentIndex: childIndex,
          state,
          focused,
          settings,
          onClick: handleNext,
          id: 'nextButton',
        })}
      </div>
    </form>
  )
}

const getSelect = ({ element, childIndex, parent, parentIndex, state, focused, settings }) => (
  <>
    <select
      key={childIndex}
      onClick={(e) => handleActiveElement({ e, element, state, focused })}
      className={`element cd ${element.active && 'focused'}`}
      onDragOver={(e) => e.preventDefault()}
      onDrop={(e) => onDrop({ event: e, parent, index: childIndex, state, focused, settings })}
      name={element.name}
      id={element.id}
      style={{ ...element.styles, ...convertCSSToReactStyle(element.css) }}
    >
      {element.options.map((option, index) => (
        <option key={index} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  </>
)

const getTextarea = ({ element, childIndex, parent, parentIndex, state, focused, settings }) => (
  <textarea
    key={childIndex}
    onClick={(e) => handleActiveElement({ e, element, state, focused })}
    className={`element cd ${element.active && 'focused'}`}
    onDragOver={(e) => e.preventDefault()}
    onDrop={(e) => onDrop({ event: e, parent, index: childIndex, state, focused, settings })}
    name={element.inputName}
    id={element.inputName}
    style={{ ...element.styles, ...convertCSSToReactStyle(element.css) }}
    placeholder={element.placeholder}
    required={element.required}
    data-field-id={element.inputName}
  />
)

const getVideo = ({ element, childIndex, parent, parentIndex, state, focused, settings }) => (
  <video
    key={childIndex}
    onClick={(e) => handleActiveElement({ e, element, state, focused })}
    className={`element cd ${element.active && 'focused'}`}
    onDragOver={(e) => e.preventDefault()}
    onDrop={(e) => onDrop({ event: e, parent, index: childIndex, state, focused, settings })}
    style={{ ...element.styles, ...convertCSSToReactStyle(element.css) }}
    controls={element.controls}
    autoPlay={element.autoPlay}
    loop={element.loop}
    muted={element.muted}
    playsInline={element.playsInline}
    src={element.src}
  ></video>
)

const elements = {
  block: getBlock,
  image: getImage,
  heading: GetTextBlock,
  paragraph: GetTextBlock,
  link: getLink,
  button: getButton,
  divider: getDivider,
  label: getLabel,
  input: getInput,
  form: getForm,
  stepper: getStepper,
  select: getSelect,
  textarea: getTextarea,
  video: getVideo,
}

const GetChildrenElements = ({ parent, parentIndex, state, focused, settings }) =>
  parent.childrens.map((child, childIndex) =>
    elements[child.type]({
      element: child,
      childIndex,
      parent,
      parentIndex,
      state,
      focused,
      settings,
    })
  )

function EditorPane({ state, focused, settings, onChange, preview }) {
  const templateUpdate = () => {
    const html = document.getElementById('EditorPane').innerHTML
    state.values.html = html
    state.setState({ ...state.values })
    const design = { html, json: state.values.json }
    onChange && onChange(design)
  }

  useEffect(templateUpdate, [JSON.stringify(state.values.json)])

  const getFonts = (element) => {
    const fonts = []
    element?.styles?.fontFamily && fonts.push(element?.styles?.fontFamily)

    const filterFont = (childrens) =>
      childrens.map((element) => {
        element.styles?.fontFamily && fonts.push(element.styles.fontFamily)
        if (element.childrens) filterFont(element.childrens)
      })

    element.childrens && filterFont(element.childrens)

    return fonts
  }

  const handleClickOutsideBody = () => {
    const removeActiveStateFromAll = (parent) => {
      parent.childrens.forEach((child) => {
        child.active = false
        if (child.childrens) removeActiveStateFromAll(child)
      })
    }

    removeActiveStateFromAll(state.values.json)
    state.setState({ ...state.values })
    focused.setFocused({})
  }

  // const SaveJSON = (e) => {
  //     e.preventDefault()
  //     var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(state.values.json));
  //     var dlAnchorElem = document.getElementById('downloadAnchorElem');
  //     dlAnchorElem.setAttribute("href",     dataStr     );
  //     dlAnchorElem.setAttribute("download", "sample.json");
  //     dlAnchorElem.click();
  // }

  // const SaveHTML = (e) => {
  //     e.preventDefault()
  //     var dataStr = "data:text/html;charset=utf-8," + encodeURIComponent(state.values.html);
  //     var dlAnchorElem = document.getElementById('downloadAnchorElem');
  //     dlAnchorElem.setAttribute("href",     dataStr     );
  //     dlAnchorElem.setAttribute("download", "sample.html");
  //     dlAnchorElem.click();
  // }

  // const SaveImage = async (e) => {
  //     const canvas = await html2canvas(document.getElementById('EditorPane'), {proxy: env.API_URL+'/html2canvas'})
  //     const handleBlob = async (blob)=>{
  //         const upload = await uploadImage({file: blob, desiredPath: 'test/html'})
  //         state.setState({...state.values, htmlCanvas: upload.response.data})
  //     }
  //     canvas.toBlob(handleBlob, 'image/png')
  // }

  console.log('STATE', state.values)

  return (
    <>
      {/* <div className="position-absolute">
            <a id="downloadAnchorElem"></a>
            <button onClick={SaveJSON}>Save JSON</button>
            <button onClick={SaveHTML}>Save HTML</button>
            <button onClick={SaveImage}>Save Image</button>
        </div> */}
      <div id="EditorPane" className={preview && 'preview'} onClick={handleClickOutsideBody}>
        {getFonts(state.values.json).map((font) => (
          <link
            href={`https://fonts.googleapis.com/css2?family=${font.replace(
              ' ',
              '+'
            )}:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,500;1,700&display=swap`}
            rel="stylesheet"
          ></link>
        ))}
        <style>{stylesheet}</style>
        <div
          onDragOver={(e) => e.preventDefault()}
          onDrop={(e) =>
            onDrop({
              event: e,
              parent: state.values.json,
              index: state.values.json.childrens.length,
              state,
              focused,
              settings,
            })
          }
          onDragEnter={(e) => {
            e.stopPropagation()
            e.target.classList.add('dragover')
          }}
          onDragLeave={(e) => {
            e.stopPropagation()
            e.target.classList.remove('dragover')
          }}
          onMouseOver={(e) => {
            e.stopPropagation()
            e.target.classList.add('hover')
          }}
          onMouseOut={(e) => {
            e.stopPropagation()
            e.target.classList.remove('hover')
          }}
          onClick={(e) => handleActiveElement({ e, element: state.values.json, state, focused })}
          className={`body ${!preview && state.values.json.active && 'focused'}`}
          style={{ ...Styles.body, ...state.values.json?.styles }}
        >
          {state.values.json.childrens && (
            <GetChildrenElements
              parent={state.values.json}
              parentIndex={state.values.json.type}
              state={state}
              focused={focused}
              settings={settings}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default EditorPane
