import env from '../config'

const FieldReference = {
  USER: 'V2Users',
  BRAND: 'V2Brands',
  CUSTOM: 'V2CustomFields',
}

const FieldTypes = {
  TEXT: 'text',
  NUMBER: 'number',
  DATE: 'date',
  BOOLEAN: 'boolean',
}

const CustomFiedlTypes = {
  SUBSCRIBER_FIELD: 'subscriber',
  BRAND_FIELD: 'brand',
}

/* 
 - The reference in the FieldVariables are the collection name in database.
**/

const FieldVariables = [
  {
    key: 'firstName',
    label: 'first name',
    description: 'the first name of your customer',
    value: '',
    reference: FieldReference.USER,
  },
  {
    key: 'lastName',
    label: 'last name',
    description: 'the last name of your customer',
    value: '',
    reference: FieldReference.USER,
  },
  {
    key: 'email',
    label: 'email',
    description: 'the email of your customer',
    value: '',
    reference: FieldReference.USER,
  },
  {
    key: 'phone',
    label: 'phone',
    description: 'the phone of your customer',
    value: '',
    reference: FieldReference.USER,
  },
  {
    key: 'brandName',
    label: 'brand name',
    description: 'the name of your brand',
    value: '',
    reference: FieldReference.BRAND,
  },
  {
    key: 'email',
    label: 'email',
    description: 'the email of your brand',
    value: '',
    reference: FieldReference.BRAND,
  },
  {
    key: 'phone',
    label: 'phone',
    description: 'the phone of your brand',
    value: '',
    reference: FieldReference.BRAND,
  },
  {
    key: 'description',
    label: 'description',
    description: 'the description of your brand',
    value: '',
    reference: FieldReference.BRAND,
  },
  {
    key: 'location',
    label: 'location',
    description: 'the location of your brand',
    value: '',
    reference: FieldReference.BRAND,
  },
  {
    key: 'about',
    label: 'about',
    description: 'details about your brand',
    value: '',
    reference: FieldReference.BRAND,
  },
  {
    key: 'website',
    label: 'website',
    description: 'the website of your brand',
    value: '',
    reference: FieldReference.BRAND,
  },
]

const MergeTags = {
  [FieldReference.BRAND]: {
    name: 'Brand Fields',
    mergeTags: {
      brandName: {
        name: 'Brand Name',
        value: `{{${FieldReference.BRAND}.brandName}}`,
        sample: 'Walletly',
        key: 'brandName',
      },
    },
  },
  [FieldReference.USER]: {
    name: 'User Fields',
    mergeTags: {
      email: {
        name: 'Email',
        value: `{{${FieldReference.USER}.email}}`,
        sample: 'Walletly',
        key: 'email',
      },
    },
  },
  [FieldReference.CUSTOM]: {
    name: 'Custom Fields',
    mergeTags: {
      email: {
        name: 'Email',
        value: `{{${FieldReference.CUSTOM}.email}}`,
        sample: 'Walletly',
        key: 'email',
      },
    },
  },
}

const FormProperties = {
  enable: true,
  usageLimit: 1,
  properties: {
    fields: {
      value: [
        {
          name: 'first_name',
          type: 'text',
          label: 'First Name',
          placeholder_text: 'Enter first name here',
          show_label: true,
          required: true,
        },
        {
          name: 'last_name',
          type: 'text',
          label: 'Last Name',
          placeholder_text: 'Enter last name here',
          show_label: true,
          required: false,
        },
        {
          name: 'email',
          type: 'email',
          label: 'Email',
          placeholder_text: 'Enter email here',
          show_label: true,
          required: true,
        },
        {
          name: 'phone',
          type: 'text',
          label: 'Phone',
          placeholder_text: 'Enter phone here',
          show_label: true,
          required: true,
        },
      ],
      editor: {
        data: {
          defaultFields: [
            { name: 'birthday', label: 'Birthday', type: 'date' },
            { name: 'gender', label: 'Gender', type: 'text' },
            { name: 'member', label: 'Members', type: 'number' },
            { name: 'eventData', label: 'Event Data', type: 'date' },
          ],
          allowAddNewField: false,
        },
      },
    },
    action: {
      editor: {
        data: {
          allowCustomUrl: true,
          actions: [
            {
              label: 'Create Pass',
              method: 'POST',
              url: `${env.API_URL}/v4/pass`,
            },
          ],
        },
      },
    },
  },
}

const Fields = {
  FieldReference,
  FieldVariables,
  MergeTags,
  FormProperties,
  FieldTypes,
  CustomFiedlTypes,
}

export default Fields
