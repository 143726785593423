import React, { useContext, useState } from 'react'

import { ChromePicker } from 'react-color'
import localForage from 'localforage'
import { CircularProgress } from '@material-ui/core'

import { SvgIcons } from '../icons'
import { ClickOutside, utils } from '../utils'
import * as Options from '../utils/Options'
import { ColorSchemeCode } from '../enums/ColorScheme'
import CustomSlider from '../components/CustomSlider'
import CustomCheckBox from '../components/CustomCheckBox'
import CustomTextField from '../components/CustomTextField'
import CustomSelect from '../components/CustomSelect'
import FitContent from '../components/FitContentInput'
import CustomFieldsDropDown from '../components/CustomFieldsDropDown'
import { element } from 'prop-types'

const handleStyleChange = ({ state, element, key, value }) => {
  if (element.styles) element.styles[key] = value
  else element.styles = { [key]: value }
  state.setState({ ...state.values })
}

const handleChange = ({ state, element, key, value }) => {
  element[key] = value
  state.setState({ ...state.values })
}

const PickColor = ({ fieldClass, color, onChange, label }) => {
  const [pallette, setPallete] = useState(false)

  return (
    <div id="PickColor" className="d-flex align-items-center ">
      <div className="body2 fw-5 opacity-10 cp w-35 mr-8 fs-12">{label || 'Color'}</div>
      <ClickOutside onClick={() => pallette && setPallete(false)} className={fieldClass}>
        <div onClick={() => setPallete(!pallette)} className="backgroundColorBox ">
          <div className="colorCircle" style={{ backgroundColor: color }}></div>
          <div className="colorCodeText body2 fs-12">{color ? color : 'rgba(255,255,255,1)'}</div>
        </div>
        {pallette && (
          <div className="position-relative">
            <ChromePicker
              className="pallette"
              color={color}
              onChange={(changedColor) =>
                onChange && onChange('rgba(' + Object.values(changedColor.rgb).join(',') + ')')
              }
            />
          </div>
        )}
      </ClickOutside>
    </div>
  )
}

const UploadImage = ({ imageUrl, fieldClass, onChange, deleteImage }) => {
  const [state, setState] = useState({
    processing: false,
    apiError: '',
  })

  const handleChange = async (e) => {
    e.preventDefault()
    setState({ ...state, processing: true })
    const user = await localForage.getItem('user')
    // const desiredPath = `${user._id}/${'master_admin'}`
    const file = e.dataTransfer ? e.dataTransfer.files : e.target.files
    const { error, response } = await utils.uploadImage({
      toaster: true,
      file: file[0],
      // desiredPath,
    })
    if (error) setState({ ...state, processing: false, apiError: error.message })

    if (response) setState({ ...state, processing: false })

    onChange && onChange(response.data)
  }

  return (
    <div id="UploadImage">
      <div className="d-flex align-items-center ">
        <div className="body2 fw-5 w-35 mr-8 fs-12">Image</div>
        <div className={fieldClass}>
          <div className="upload-block bg-color-widgetNavBackgroundColor">
            {imageUrl && (
              <div className="d-flex align-items-center">
                <img width="85%" src={imageUrl} alt="" />
                <div onClick={() => deleteImage && deleteImage()} className="ml-10">
                  <SvgIcons.DeleteIcon width={'10'} color={ColorSchemeCode.selectButtonColor} />
                </div>
              </div>
            )}
            {!imageUrl && !state.processing && (
              <>
                <label
                  className="fs-12 fw-4 align-bottom color-unselectTextColor cp fw-5 mb-10"
                  htmlFor="image"
                >
                  Upload Image
                </label>
                <input id="image" style={{ display: 'none' }} type="file" onChange={handleChange} />
              </>
            )}
            {state.processing && <CircularProgress size={20} color="inherit" />}
          </div>
        </div>
      </div>
    </div>
  )
}

const BackgrounProperties = ({ state, element }) => {
  return (
    <div id="BackgroundProperties">
      <div className="d-flex">
        <div className="body2 fw-5 pt-8 w-35 mr-8 fs-12">Size</div>
        <div className="w-75 ml-auto ">
          <div className="d-flex justify-content-between background-size-options bg-color-widgetNavBackgroundColor pl-2 pr-2 pb-2 pt-2 borderRadius-4">
            <div
              onClick={() =>
                handleStyleChange({ state, element, key: 'backgroundSize', value: 'auto' })
              }
              className={`color-unselectTextColor borderRadius-4 ${
                element.styles?.backgroundSize === 'auto' && 'active'
              }`}
            >
              Auto
            </div>
            <div
              onClick={() =>
                handleStyleChange({ state, element, key: 'backgroundSize', value: 'cover' })
              }
              className={`color-unselectTextColor borderRadius-4  ${
                element.styles?.backgroundSize === 'cover' && 'active'
              }`}
            >
              Cover
            </div>
            <div
              onClick={() =>
                handleStyleChange({ state, element, key: 'backgroundSize', value: 'contain' })
              }
              className={`color-unselectTextColor borderRadius-4 ${
                element.styles?.backgroundSize === 'contain' && 'active'
              }`}
            >
              Contain
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex align-items-center ">
        <div class="body2 fw-5 w-35 mr-8 fs-12"></div>
        <div className="w-75 ml-auto">
          <div className="d-flex justify-content-between pt-16">
            <div className="pr-5">
              <CustomTextField
                placeholder={'Auto'}
                padding={'8px'}
                className={'color-selectButtonColor fs-12'}
                height={'27px'}
                fontSize={'12px'}
                placeholderColor={ColorSchemeCode.unselectTextColor}
                backgroundColor={ColorSchemeCode.widgetNavBackgroundColor}
                borderRadius={'4px'}
                inputProps={{ type: 'number' }}
                value={
                  element.styles?.backgroundPositionX
                    ? parseInt(element.styles?.backgroundPositionX)
                    : ''
                }
                onChange={(e) =>
                  handleStyleChange({
                    state,
                    element,
                    key: 'backgroundPositionX',
                    value: e.target.value ? e.target.value + 'px' : '',
                  })
                }
              />
              <div className="fs-12 text-center color-selectButtonColor">Width</div>
            </div>
            <div className="pl-5">
              <CustomTextField
                placeholder={'Auto'}
                className={'color-selectButtonColor fs-12'}
                height={'27px'}
                padding={'8px'}
                fontSize={'12px'}
                placeholderColor={ColorSchemeCode.unselectTextColor}
                backgroundColor={ColorSchemeCode.widgetNavBackgroundColor}
                borderRadius={'4px'}
                inputProps={{ type: 'number' }}
                value={
                  element.styles?.backgroundPositionY
                    ? parseInt(element.styles?.backgroundPositionY)
                    : ''
                }
                onChange={(e) =>
                  handleStyleChange({
                    state,
                    element,
                    key: 'backgroundPositionY',
                    value: e.target.value ? e.target.value + 'px' : '',
                  })
                }
              />
              <div className="fs-12 text-center color-selectButtonColor">Height</div>
            </div>
          </div>
        </div>
      </div>
      {/* 
            <div className="divider"></div> */}
      {/* NewFields */}
      {/* <div className="d-flex">
                <div className="body2 fw-5 pt-8 w-35 mr-8">Position</div>
                <div className="w-75 ml-auto ">
                        <div className="d-flex justify-content-between">
                            <div className="pr-5">
                                <div className="fs-12 text-left color-selectButtonColor">Left</div>
                                <CustomTextField 
                                    placeholder      = {'Auto'}
                                    className        = {'color-selectButtonColor'}
                                    height           = {'32px'}
                                    borderRadius     = {'4px'}
                                    padding          = {'8px'}
                                    placeholderColor = {ColorSchemeCode.unselectTextColor}
                                    backgroundColor  = {ColorSchemeCode.textFieldBackground}
                                    inputProps       = {{type: 'number'}}
                                    value            = {element.styles?.backgroundPositionX ? parseInt(element.styles?.backgroundPositionX) : ''}
                                    onChange         = {(e)=>handleStyleChange({state, element, key: 'backgroundPositionX', value: e.target.value ? e.target.value+'px': ''})}
                                />
                            </div>
                            <div className="pl-5">
                                <div className="fs-12 text-left color-selectButtonColor">Right</div>
                                <CustomTextField 
                                    placeholder      = {'Auto'}
                                    className        = {'color-selectButtonColor'}
                                    height           = {'32px'}
                                    borderRadius     = {'4px'}
                                    padding          = {'8px'}
                                    placeholderColor = {ColorSchemeCode.unselectTextColor}
                                    backgroundColor  = {ColorSchemeCode.textFieldBackground}
                                    inputProps       = {{type: 'number'}}
                                    value            = {element.styles?.backgroundPositionY ? parseInt(element.styles?.backgroundPositionY) : ''}
                                    onChange         = {(e)=>handleStyleChange({state, element, key: 'backgroundPositionY', value: e.target.value ? e.target.value+'px': ''})}
                                />
                            </div>
                        </div>
                    </div>
                </div> */}
    </div>
  )
}

const Padding = ({ state, element }) => {
  const [padding, setPadding] = useState(false)

  const handlePaddingCheck = () => {
    delete element.styles?.padding
    setPadding(!padding)
    state.setState({ ...state.values })
  }

  return (
    <div id="Padding" className="">
      <div className="d-flex align-items-center ">
        <div className="body2 fw-5 mr-25 pb-10 fs-12">Padding</div>
        <div className={`d-flex align-items-center w-100 ${padding && 'disabled'}`}>
          <div className="w-100 mr-10">
            <CustomSlider
              value={element.styles?.padding ? parseInt(element.styles?.padding) : 0}
              onChange={(e, value) =>
                handleStyleChange({
                  state,
                  element,
                  key: 'padding',
                  value: value ? value + 'px' : '',
                })
              }
            />
          </div>
          <div className="pb-10 position-relative">
            <CustomTextField
              value={element.styles?.padding ? parseInt(element.styles?.padding) : ''}
              height={'27px'}
              borderRadius={'4px'}
              fontSize={'12px'}
              placeholderColor={ColorSchemeCode.unselectTextColor}
              padding={'8px'}
              backgroundColor={ColorSchemeCode.widgetNavBackgroundColor}
              inputProps={{ type: 'number' }}
              onChange={(e) =>
                handleStyleChange({
                  state,
                  element,
                  key: 'padding',
                  value: e.target.value ? e.target.value + 'px' : '',
                })
              }
            />
            <div className="Tag">px</div>
          </div>
        </div>
      </div>

      <div className="ml-auto">
        <CustomCheckBox
          label={'Individual Padding'}
          className="checkboxLabel color-textfieldColor"
          name="padding"
          fontSize={'12px'}
          fontWeight={'500'}
          marginTop={'2px'}
          color={ColorSchemeCode.selectButtonColor}
          value={padding}
          onChange={handlePaddingCheck}
        />
      </div>
      {padding && (
        <>
          <div className="d-flex align-items-center ">
            <div className="col-6 px-0 pr-8 d-flex align-items-center">
              <div className="indSpacing">
                <div className="top active"></div>
                <div className="right"></div>
                <div className="bottom"></div>
                <div className="left"></div>
              </div>
              <CustomTextField
                placeholder={'Top'}
                height={'32px'}
                fontSize={'12px'}
                className={'w-80'}
                placeholderColor={ColorSchemeCode.unselectTextColor}
                padding={'8px'}
                borderRadius={'4px'}
                inputProps={{ type: 'number' }}
                backgroundColor={ColorSchemeCode.widgetNavBackgroundColor}
                value={element.styles?.paddingTop ? parseInt(element.styles?.paddingTop) : ''}
                onChange={(e) =>
                  handleStyleChange({
                    state,
                    element,
                    key: 'paddingTop',
                    value: e.target.value ? e.target.value + 'px' : '',
                  })
                }
              />
            </div>
            <div className="col-6 px-0 pl-8 d-flex align-items-center pl-15">
              <div className="indSpacing">
                <div className="top"></div>
                <div className="right"></div>
                <div className="bottom active"></div>
                <div className="left"></div>
              </div>
              <CustomTextField
                placeholder={'Bottom'}
                height={'32px'}
                className={'w-80'}
                fontSize={'12px'}
                padding={'8px'}
                placeholderColor={ColorSchemeCode.unselectTextColor}
                borderRadius={'4px'}
                backgroundColor={ColorSchemeCode.widgetNavBackgroundColor}
                inputProps={{ type: 'number' }}
                value={element.styles?.paddingBottom ? parseInt(element.styles?.paddingBottom) : ''}
                onChange={(e) =>
                  handleStyleChange({
                    state,
                    element,
                    key: 'paddingBottom',
                    value: e.target.value ? e.target.value + 'px' : '',
                  })
                }
              />
            </div>
          </div>

          <div className="d-flex align-items-center mt-18 ">
            <div className="col-6 px-0 pr-8 d-flex align-items-center">
              <div className="indSpacing">
                <div className="top"></div>
                <div className="right"></div>
                <div className="bottom"></div>
                <div className="left active"></div>
              </div>
              <CustomTextField
                placeholder={'Left'}
                height={'32px'}
                className={'w-80'}
                fontSize={'12px'}
                padding={'8px'}
                borderRadius={'4px'}
                placeholderColor={ColorSchemeCode.unselectTextColor}
                inputProps={{ type: 'number' }}
                backgroundColor={ColorSchemeCode.widgetNavBackgroundColor}
                value={element.styles?.paddingLeft ? parseInt(element.styles?.paddingLeft) : ''}
                onChange={(e) =>
                  handleStyleChange({
                    state,
                    element,
                    key: 'paddingLeft',
                    value: e.target.value ? e.target.value + 'px' : '',
                  })
                }
              />
            </div>
            <div className="col-6 px-0 pl-8 d-flex align-items-center pl-15">
              <div className="indSpacing">
                <div className="top"></div>
                <div className="right active"></div>
                <div className="bottom"></div>
                <div className="left"></div>
              </div>
              <CustomTextField
                placeholder={'Right'}
                height={'32px'}
                padding={'8px'}
                fontSize={'12px'}
                className={'w-80'}
                placeholderColor={ColorSchemeCode.unselectTextColor}
                backgroundColor={ColorSchemeCode.widgetNavBackgroundColor}
                borderRadius={'4px'}
                inputProps={{ type: 'number' }}
                value={element.styles?.paddingRight ? parseInt(element.styles?.paddingRight) : ''}
                onChange={(e) =>
                  handleStyleChange({
                    state,
                    element,
                    key: 'paddingRight',
                    value: e.target.value ? e.target.value + 'px' : '',
                  })
                }
              />
            </div>
          </div>
        </>
      )}
    </div>
  )
}

const Margin = ({ state, element }) => {
  const [margin, setMargin] = useState(false)

  const handleMarginCheck = () => {
    delete element.styles?.margin
    setMargin(!margin)
    state.setState({ ...state.values })
  }

  return (
    <div id="Margin">
      <div className="d-flex align-items-center ">
        <div className="body2 fw-5 mr-25 pb-10 fs-12">Margin</div>
        <div className={`d-flex align-items-center w-100 ${margin && 'disabled'}`}>
          <div className="w-100 mr-10">
            <CustomSlider
              value={element.styles?.margin ? parseInt(element.styles?.margin) : 0}
              onChange={(e, value) =>
                handleStyleChange({
                  state,
                  element,
                  key: 'margin',
                  value: value ? value + 'px' : '',
                })
              }
            />
          </div>
          <div className="pb-10 position-relative">
            <CustomTextField
              value={element.styles?.margin ? parseInt(element.styles?.margin) : ''}
              height={'27px'}
              padding={'8px'}
              fontSize={'12px'}
              borderRadius={'4px'}
              placeholderColor={ColorSchemeCode.unselectTextColor}
              backgroundColor={ColorSchemeCode.widgetNavBackgroundColor}
              inputProps={{ type: 'number' }}
              onChange={(e) =>
                handleStyleChange({
                  state,
                  element,
                  key: 'margin',
                  value: e.target.value ? e.target.value + 'px' : '',
                })
              }
            />
            <div className="Tag">px</div>
          </div>
        </div>
      </div>

      <div className="ml-auto">
        <CustomCheckBox
          label={'Individual Margin'}
          className="color-textfieldColor"
          name="margin"
          fontSize={'12px'}
          value={margin}
          fontWeight={'500'}
          marginTop={'2px'}
          color={ColorSchemeCode.selectButtonColor}
          onChange={handleMarginCheck}
        />
      </div>

      {margin && (
        <>
          <div className="d-flex align-items-center justify-content-between mb-16 ">
            <div class="body2 fw-5 w-35 mr-8 fs-12">Auto</div>
            <div className="horizontalAlignment">
              <div
                onClick={() =>
                  handleStyleChange({
                    state,
                    element,
                    key: 'marginTop',
                    value: element.styles?.marginTop === 'auto' ? '' : 'auto',
                  })
                }
                className={`${element.styles?.marginTop === 'auto' && 'selected'}`}
              >
                <div className="indSpacing">
                  <div className="top active"></div>
                  <div className="right"></div>
                  <div className="bottom"></div>
                  <div className="left"></div>
                </div>
              </div>
              <div
                onClick={() =>
                  handleStyleChange({
                    state,
                    element,
                    key: 'marginBottom',
                    value: element.styles?.marginBottom === 'auto' ? '' : 'auto',
                  })
                }
                className={`${element.styles?.marginBottom === 'auto' && 'selected'}`}
              >
                <div className="indSpacing">
                  <div className="top"></div>
                  <div className="right"></div>
                  <div className="bottom active"></div>
                  <div className="left"></div>
                </div>
              </div>
              <div
                onClick={() =>
                  handleStyleChange({
                    state,
                    element,
                    key: 'marginLeft',
                    value: element.styles?.marginLeft === 'auto' ? '' : 'auto',
                  })
                }
                className={`${element.styles?.marginLeft === 'auto' && 'selected'}`}
              >
                <div className="indSpacing">
                  <div className="top"></div>
                  <div className="right"></div>
                  <div className="bottom"></div>
                  <div className="left active"></div>
                </div>
              </div>
              <div
                onClick={() =>
                  handleStyleChange({
                    state,
                    element,
                    key: 'marginRight',
                    value: element.styles?.marginRight === 'auto' ? '' : 'auto',
                  })
                }
                className={`${element.styles?.marginRight === 'auto' && 'selected'}`}
              >
                <div className="indSpacing">
                  <div className="top"></div>
                  <div className="right active"></div>
                  <div className="bottom"></div>
                  <div className="left"></div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex align-items-center ">
            <div className="col-6 px-0 pr-8 d-flex align-items-center">
              <div className="indSpacing">
                <div className="top active"></div>
                <div className="right"></div>
                <div className="bottom"></div>
                <div className="left"></div>
              </div>
              <CustomTextField
                placeholder={'Top'}
                height={'32px'}
                className={'w-85'}
                borderRadius={'4px'}
                padding={'8px'}
                backgroundColor={ColorSchemeCode.widgetNavBackgroundColor}
                placeholderColor={ColorSchemeCode.unselectTextColor}
                inputProps={{ type: 'number' }}
                value={element.styles?.marginTop ? parseInt(element.styles?.marginTop) : ''}
                onChange={(e) =>
                  handleStyleChange({
                    state,
                    element,
                    key: 'marginTop',
                    value: e.target.value ? e.target.value + 'px' : '',
                  })
                }
              />
            </div>
            <div className="col-6 px-0 pl-8 d-flex align-items-center">
              <div className="indSpacing">
                <div className="top"></div>
                <div className="right"></div>
                <div className="bottom active"></div>
                <div className="left"></div>
              </div>
              <CustomTextField
                placeholder={'Bottom'}
                height={'32px'}
                className={'w-85'}
                padding={'8px'}
                borderRadius={'4px'}
                placeholderColor={ColorSchemeCode.unselectTextColor}
                backgroundColor={ColorSchemeCode.widgetNavBackgroundColor}
                inputProps={{ type: 'number' }}
                value={element.styles?.marginBottom ? parseInt(element.styles?.marginBottom) : ''}
                onChange={(e) =>
                  handleStyleChange({
                    state,
                    element,
                    key: 'marginBottom',
                    value: e.target.value ? e.target.value + 'px' : '',
                  })
                }
              />
            </div>
          </div>

          <div className="d-flex align-items-center mt-18 ">
            <div className="col-6 px-0 pr-8 d-flex align-items-center">
              <div className="indSpacing">
                <div className="top"></div>
                <div className="right"></div>
                <div className="bottom"></div>
                <div className="left active"></div>
              </div>
              <CustomTextField
                placeholder={'Left'}
                height={'32px'}
                backgroundColor={ColorSchemeCode.widgetNavBackgroundColor}
                className={'w-85'}
                placeholderColor={ColorSchemeCode.unselectTextColor}
                padding={'8px'}
                borderRadius={'4px'}
                inputProps={{ type: 'number' }}
                value={element.styles?.marginLeft ? parseInt(element.styles?.marginLeft) : ''}
                onChange={(e) =>
                  handleStyleChange({
                    state,
                    element,
                    key: 'marginLeft',
                    value: e.target.value ? e.target.value + 'px' : '',
                  })
                }
              />
            </div>
            <div className="col-6 px-0 pl-8 d-flex align-items-center">
              <div className="indSpacing">
                <div className="top"></div>
                <div className="right active"></div>
                <div className="bottom"></div>
                <div className="left"></div>
              </div>
              <CustomTextField
                placeholder={'Right'}
                height={'32px'}
                className={'w-85'}
                padding={'8px'}
                placeholderColor={ColorSchemeCode.unselectTextColor}
                backgroundColor={ColorSchemeCode.widgetNavBackgroundColor}
                borderRadius={'4px'}
                inputProps={{ type: 'number' }}
                value={element.styles?.marginRight ? parseInt(element.styles?.marginRight) : ''}
                onChange={(e) =>
                  handleStyleChange({
                    state,
                    element,
                    key: 'marginRight',
                    value: e.target.value ? e.target.value + 'px' : '',
                  })
                }
              />
            </div>
          </div>
        </>
      )}
    </div>
  )
}

const Spacing = ({ state, element }) => {
  const [details, setDetails] = useState(true)

  return (
    <>
      <div onClick={() => setDetails(!details)} className="property-section">
        <div className={`mr-10 ${details ? 'rotate-bottom' : 'rotate-right'}`}>
          <SvgIcons.DropDownTriangleIcon
            width="11px"
            height="11px"
            color={ColorSchemeCode.selectButtonColor}
          />
        </div>
        <div className="fs-12 fw-5 color-selectButtonColor middle">Spacing</div>
      </div>
      {details && (
        <div className="mt-16">
          <Padding state={state} element={element} />
          <div className="divider"></div>
          <Margin state={state} element={element} />
          <div className="property-name-divider mt-16"></div>
        </div>
      )}
    </>
  )
}

const Width = ({ state, element }) => {
  const widthUnitOptions = ['px', 'em', 'rem', 'vw', 'vh', '%', 'auto', 'fit-content']

  const [widthUnit, setWidthUnit] = useState(
    element.styles?.width
      ? element.styles?.width
          .split('')
          .filter((char) => !/[0-9]/.test(char))
          .join('')
      : '%'
  )
  const [widthFocus, setWidthFocus] = useState(false)

  const handleWidthUnit = (option) => {
    if (!element.styles?.width) return setWidthUnit(option)

    if (option === 'fit-content' || option === 'auto') {
      element.styles.width = option
      setWidthUnit(option)
      return state.setState({ ...state.values })
    }

    if (!isNaN(parseFloat(element.styles?.width)))
      element.styles.width = parseFloat(element.styles?.width) + option
    setWidthUnit(option)
    state.setState({ ...state.values })
  }

  return (
    <div className="d-flex align-items-baseline position-relative">
      <div class="body2 fw-5 w-35 mr-8 fs-12">Width</div>
      <div className="w-75 ml-auto">
        <div className="d-flex align-items-baseline dropdown-input-box bg-color-widgetNavBackgroundColor">
          <input
            className={`sizeInputField color-selectButtonColor border-0 w-100 bg-color-widgetNavBackgroundColor ${
              (widthUnit === 'fit-content' || widthUnit === 'auto') && 'disabled'
            }`}
            placeholder={'Auto'}
            fontSize={'12px'}
            height={'37px'}
            value={
              element.styles?.width
                ? !isNaN(parseFloat(element.styles?.width))
                  ? parseFloat(element.styles?.width)
                  : ''
                : ''
            }
            onChange={(e) =>
              handleStyleChange({
                state,
                element,
                key: 'width',
                value: e.target.value ? e.target.value + widthUnit : '',
              })
            }
          />
          <div onClick={(e) => setWidthFocus(true)} className="w-fit cp text-center">
            {widthUnit}
          </div>
        </div>

        {widthFocus && (
          <ClickOutside className={'w-fit ml-auto'} onClick={(e) => setWidthFocus(false)}>
            <div className="predefine-dropdown">
              {widthUnitOptions.map((option) => (
                <div
                  onClick={(e) => handleWidthUnit(option)}
                  className={`${widthUnit === option && 'selected'}`}
                >
                  {option}
                </div>
              ))}
            </div>
          </ClickOutside>
        )}
      </div>
    </div>
  )
}

const Height = ({ state, element }) => {
  const heightUnitOptions = ['px', 'em', 'rem', 'vw', 'vh', '%', 'auto', 'fit-content']

  const [heightUnit, setHeightUnit] = useState(
    element.styles?.height
      ? element.styles?.height
          .split('')
          .filter((char) => !/[0-9]/.test(char))
          .join('')
      : '%'
  )
  const [heightFocus, setHeightFocus] = useState(false)

  const handleHeightUnit = (option) => {
    if (!element.styles?.height) return setHeightUnit(option)

    if (option === 'fit-content' || option === 'auto') {
      element.styles.height = option
      setHeightUnit(option)
      return state.setState({ ...state.values })
    }

    if (!isNaN(parseFloat(element.styles?.height)))
      element.styles.height = parseFloat(element.styles?.height) + option
    setHeightUnit(option)
    state.setState({ ...state.values })
  }

  return (
    <div className="d-flex align-items-baseline position-relative ">
      <div class="body2 fw-5 w-35 mr-8 fs-12">Height</div>
      <div className="w-75 ml-auto">
        <div className="d-flex align-items-baseline dropdown-input-box  bg-color-widgetNavBackgroundColor">
          <input
            className={`sizeInputField border-0 w-100 color-selectButtonColor bg-color-widgetNavBackgroundColor ${
              (heightUnit === 'fit-content' || heightUnit === 'auto') && 'disabled'
            }`}
            placeholder={'Auto'}
            height={'37px'}
            fontSize={'12px'}
            value={
              element.styles?.height
                ? !isNaN(parseFloat(element.styles?.height))
                  ? parseFloat(element.styles?.height)
                  : ''
                : ''
            }
            onChange={(e) =>
              handleStyleChange({
                state,
                element,
                key: 'height',
                value: e.target.value ? e.target.value + heightUnit : '',
              })
            }
          />
          <div onClick={(e) => setHeightFocus(true)} className="w-fit cp text-center">
            {heightUnit}
          </div>
        </div>

        {heightFocus && (
          <ClickOutside className={'w-fit ml-auto'} onClick={(e) => setHeightFocus(false)}>
            <div className="predefine-dropdown">
              {heightUnitOptions.map((option) => (
                <div
                  onClick={(e) => handleHeightUnit(option)}
                  className={`${heightUnit === option && 'selected'}`}
                >
                  {option}
                </div>
              ))}
            </div>
          </ClickOutside>
        )}
      </div>
    </div>
  )
}

const Size = ({ state, element }) => {
  const [details, setDetails] = useState(true)

  return (
    <>
      <div onClick={() => setDetails(!details)} className="property-section">
        <div className={`mr-10 ${details ? 'rotate-bottom' : 'rotate-right'}`}>
          <SvgIcons.DropDownTriangleIcon
            width="11px"
            height="11px"
            color={ColorSchemeCode.selectButtonColor}
          />
        </div>
        <div className="fs-12 fw-5 color-selectButtonColor middle">Size</div>
      </div>
      {details && (
        <div className="mt-16">
          <div className="mb-16">
            <Width state={state} element={element} />
          </div>
          <Height state={state} element={element} />
          <div className="property-name-divider mt-16"></div>
        </div>
      )}
    </>
  )
}

const Direction = ({ state, element }) => {
  return (
    <div id="Direction">
      <div className="d-flex justify-content-between">
        <div className="body2 fw-5 pt-8 w-35 mr-8 fs-12">Direction</div>
        <div className="horizontalAlignment">
          <div
            onClick={() =>
              handleStyleChange({ state, element, key: 'flexDirection', value: 'row' })
            }
            className={`${element.styles?.flexDirection === 'row' && 'selected'}`}
          >
            <div className="rotate-left w-fit mx-auto">
              <SvgIcons.ArrowUpIcon
                width={'15'}
                height={'15'}
                color={
                  element.styles?.flexDirection === 'row'
                    ? ColorSchemeCode.themeColor
                    : ColorSchemeCode.unselectTextColor
                }
              />
            </div>
          </div>
          <div
            onClick={() =>
              handleStyleChange({ state, element, key: 'flexDirection', value: 'column' })
            }
            className={`rotate-top w-fit mx-auto ${
              element.styles?.flexDirection === 'column' && 'selected'
            }`}
          >
            <SvgIcons.ArrowUpIcon
              width={'15'}
              height={'15'}
              color={
                element.styles?.flexDirection === 'column'
                  ? ColorSchemeCode.themeColor
                  : ColorSchemeCode.unselectTextColor
              }
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const Align = ({ state, element }) => {
  return (
    <div id="Align">
      <div className="d-flex justify-content-between">
        <div className="body2 fw-5 pt-8 w-35 mr-8 fs-12">Align</div>
        <div className="horizontalAlignment">
          <div
            onClick={() =>
              handleStyleChange({ state, element, key: 'alignItems', value: 'flex-start' })
            }
            className={`${element.styles?.alignItems === 'flex-start' && 'selected'}`}
          >
            <SvgIcons.AlignLeft
              className={`${element.styles?.flexDirection === 'row' ? 'rotate-right' : ' flip'}`}
              color={
                element.styles?.alignItems === 'flex-start'
                  ? ColorSchemeCode.themeColor
                  : ColorSchemeCode.unselectTextColor
              }
            />
          </div>
          <div
            onClick={() =>
              handleStyleChange({ state, element, key: 'alignItems', value: 'center' })
            }
            className={`${element.styles?.alignItems === 'center' && 'selected'}`}
          >
            <SvgIcons.AlignVerticalCenter
              className={`${element.styles?.flexDirection === 'row' && 'rotate-right'}`}
              color={
                element.styles?.alignItems === 'center'
                  ? ColorSchemeCode.themeColor
                  : ColorSchemeCode.unselectTextColor
              }
            />
          </div>
          <div
            onClick={() =>
              handleStyleChange({ state, element, key: 'alignItems', value: 'flex-end' })
            }
            className={`flip ${element.styles?.alignItems === 'flex-end' && 'selected'}`}
          >
            <SvgIcons.AlignLeft
              className={`${element.styles?.flexDirection === 'row' ? 'rotate-left' : ' flip'}`}
              color={
                element.styles?.alignItems === 'flex-end'
                  ? ColorSchemeCode.themeColor
                  : ColorSchemeCode.unselectTextColor
              }
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const Justify = ({ state, element }) => {
  return (
    <div id="Justify">
      <div className="d-flex justify-content-between">
        <div className="body2 fw-5 pt-8 w-35 mr-8 fs-12">Justify</div>
        <div className="horizontalAlignment">
          <div
            onClick={() =>
              handleStyleChange({ state, element, key: 'justifyContent', value: 'flex-start' })
            }
            className={`rotate-bottom ${
              element.styles?.justifyContent === 'flex-start' && 'selected'
            }`}
          >
            <SvgIcons.JustifyLeftIcon
              className={`${element.styles?.flexDirection === 'column' && 'rotate-left'}`}
              color={
                element.styles?.justifyContent === 'flex-start'
                  ? ColorSchemeCode.themeColor
                  : ColorSchemeCode.unselectTextColor
              }
            />
          </div>
          <div
            onClick={() =>
              handleStyleChange({ state, element, key: 'justifyContent', value: 'center' })
            }
            className={`${element.styles?.justifyContent === 'center' && 'selected'}`}
          >
            <SvgIcons.JustifyCenterIcon
              className={`${element.styles?.flexDirection === 'column' && 'rotate-right'}`}
              color={
                element.styles?.justifyContent === 'center'
                  ? ColorSchemeCode.themeColor
                  : ColorSchemeCode.unselectTextColor
              }
            />
          </div>
          <div
            onClick={() =>
              handleStyleChange({ state, element, key: 'justifyContent', value: 'flex-end' })
            }
            className={`rotate-bottom flip ${
              element.styles?.justifyContent === 'flex-end' && 'selected'
            }`}
          >
            <SvgIcons.JustifyLeftIcon
              className={`${element.styles?.flexDirection === 'column' && 'rotate-right'}`}
              color={
                element.styles?.justifyContent === 'flex-end'
                  ? ColorSchemeCode.themeColor
                  : ColorSchemeCode.unselectTextColor
              }
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const Layout = ({ state, element }) => {
  const [details, setDetails] = useState(true)

  return (
    <>
      <div onClick={() => setDetails(!details)} className="property-section">
        <div className={`mr-10 ${details ? 'rotate-bottom' : 'rotate-right'}`}>
          <SvgIcons.DropDownTriangleIcon
            width="11px"
            height="11px"
            color={ColorSchemeCode.selectButtonColor}
          />
        </div>
        <div className="fs-12 fw-5 color-selectButtonColor middle">Layout</div>
      </div>
      {details && (
        <div className="mt-16">
          <Direction state={state} element={element} />
          <div className="mt-16 mb-16">
            <Align state={state} element={element} />
          </div>
          <div>
            <Justify state={state} element={element} />
          </div>
          <div className="property-name-divider mt-16"></div>
        </div>
      )}
    </>
  )
}

const StepperBackground = ({ state, element }) => {
  const [details, setDetails] = useState(true)

  return (
    <>
      <div onClick={() => setDetails(!details)} className="property-section">
        <div className={`mr-10 ${details ? 'rotate-bottom' : 'rotate-right'}`}>
          <SvgIcons.DropDownTriangleIcon
            width="11px"
            height="11px"
            color={ColorSchemeCode.selectButtonColor}
          />
        </div>
        <div className="fs-12 fw-5 color-selectButtonColor middle">Stepper Background</div>
      </div>
      {details && (
        <div className="mt-16">
          <PickColor
            fieldClass={'w-60 ml-auto'}
            color={element.bgCompleteColor}
            onChange={(hex) => handleChange({ state, element, key: 'bgCompleteColor', value: hex })}
            label={'Complete'}
          />
          <div className="mt-16">
            <PickColor
              fieldClass={'w-60 ml-auto'}
              color={element.bgActiveColor}
              onChange={(hex) => handleChange({ state, element, key: 'bgActiveColor', value: hex })}
              label={'Active'}
            />
          </div>
          <div className="mt-16">
            <PickColor
              fieldClass={'w-60 ml-auto'}
              color={element.bgStepColor}
              onChange={(hex) => handleChange({ state, element, key: 'bgStepColor', value: hex })}
              label={'Step'}
            />
          </div>

          <div className="property-name-divider mt-16"></div>
        </div>
      )}
    </>
  )
}

const StepperTextColor = ({ state, element }) => {
  const [details, setDetails] = useState(true)

  return (
    <>
      <div onClick={() => setDetails(!details)} className="property-section">
        <div className={`mr-10 ${details ? 'rotate-bottom' : 'rotate-right'}`}>
          <SvgIcons.DropDownTriangleIcon
            width="11px"
            height="11px"
            color={ColorSchemeCode.selectButtonColor}
          />
        </div>
        <div className="fs-12 fw-5 color-selectButtonColor middle">Stepper Text</div>
      </div>
      {details && (
        <div className="mt-16">
          <PickColor
            fieldClass={'w-60 ml-auto'}
            color={element.textCompleteColor}
            onChange={(hex) =>
              handleChange({ state, element, key: 'textCompleteColor', value: hex })
            }
            label={'Complete'}
          />
          <div className="mt-16">
            <PickColor
              fieldClass={'w-60 ml-auto'}
              color={element.textActiveColor}
              onChange={(hex) =>
                handleChange({ state, element, key: 'textActiveColor', value: hex })
              }
              label={'Active'}
            />
          </div>
          <div className="mt-16">
            <PickColor
              fieldClass={'w-60 ml-auto'}
              color={element.textStepColor}
              onChange={(hex) => handleChange({ state, element, key: 'textStepColor', value: hex })}
              label={'Step'}
            />
          </div>

          <div className="property-name-divider mt-16"></div>
        </div>
      )}
    </>
  )
}

const StepItem = ({ state, element, step, index }) => {
  const [showConfirm, setShowConfirm] = useState(false)
  const handleDeleteStep = () => {
    if (!showConfirm) return setShowConfirm(true)
    element.steps.splice(index, 1)
    state.setState({ ...state.values })
    setShowConfirm(false)
  }

  const handleChange = (e) => {
    element.steps[index].stepLabel = e.target.value
    state.setState({ ...state.values })
  }

  return (
    <div className="d-flex align-items-baseline position-relative ">
      <div class="body2 fw-5 w-35 mr-8 fs-12">Step {index + 1}</div>
      <div className="w-60 ml-auto">
        <div className="d-flex align-items-baseline dropdown-input-box  bg-color-widgetNavBackgroundColor">
          <input
            className={`sizeInputField border-0 w-100 color-selectButtonColor bg-color-widgetNavBackgroundColor`}
            placeholder={'Step ' + (index + 1)}
            height={'37px'}
            fontSize={'12px'}
            value={step.stepLabel}
            onChange={handleChange}
          />
          <div onClick={handleDeleteStep} className="w-fit cp text-center">
            {showConfirm ? (
              <SvgIcons.IconTick width={13} height={13} color={ColorSchemeCode.selectButtonColor} />
            ) : (
              <SvgIcons.DeleteIcon
                color={ColorSchemeCode.selectButtonColor}
                width={13}
                height={13}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const StepDetails = ({ state, element }) => {
  const [details, setDetails] = useState(true)

  const addStep = (e) => {
    e.stopPropagation()
    element.steps.push({
      type: 'block',
      active: false,
      editable: true,
      stepLabel: 'Step ' + (element.steps.length + 1),
      styles: { padding: '16px' },
      childrens: [
        {
          type: 'block',
          active: false,
          editable: true,
          childrens: [
            {
              type: 'label',
              active: false,
              editable: true,
              innerText: 'Email',
              htmlFor: 'email',
              styles: {
                fontSize: '14px',
                paddingLeft: '6px',
                marginBottom: '3px',
              },
            },
            {
              type: 'input',
              active: false,
              editable: true,
              value: '',
              inputName: 'email',
              id: 'email',
              name: 'email',
              inputType: 'email',
              placeholder: 'Enter your email',
              required: true,
              styles: {
                borderRadius: '8px',
                fontSize: '16px',
                paddingLeft: '16px',
                paddingRight: '16px',
                borderStyle: 'solid',
                borderWidth: '1px',
                borderColor: 'rgba(0,0,0,0.36)',
                paddingTop: '6px',
                paddingBottom: '6px',
              },
            },
          ],
          dropDown: true,
        },
        {
          type: 'block',
          active: false,
          editable: true,
          childrens: [
            {
              type: 'label',
              active: false,
              editable: true,
              innerText: 'Phone',
              htmlFor: 'email',
              styles: {
                fontSize: '14px',
                paddingLeft: '6px',
                marginBottom: '3px',
              },
            },
            {
              type: 'input',
              active: false,
              editable: true,
              value: '',
              inputName: 'phone',
              id: 'email',
              name: 'email',
              inputType: 'text',
              placeholder: 'Enter your phone',
              required: true,
              styles: {
                borderRadius: '8px',
                fontSize: '16px',
                paddingLeft: '16px',
                paddingRight: '16px',
                borderStyle: 'solid',
                borderWidth: '1px',
                borderColor: 'rgba(0,0,0,0.36)',
                paddingTop: '6px',
                paddingBottom: '6px',
              },
            },
          ],
          dropDown: true,
          styles: {
            marginTop: '16px',
          },
        },
        {
          type: 'block',
          active: false,
          editable: true,
          styles: {
            marginTop: '16px',
            marginLeft: '-10px',
            marginRight: '-10px',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          },
          childrens: [
            {
              type: 'block',
              active: false,
              editable: true,
              childrens: [
                {
                  type: 'label',
                  active: false,
                  editable: true,
                  innerText: 'Password',
                  htmlFor: 'email',
                  styles: {
                    fontSize: '14px',
                    paddingLeft: '6px',
                    marginBottom: '3px',
                  },
                },
                {
                  type: 'input',
                  active: false,
                  editable: true,
                  value: '',
                  inputName: 'password',
                  id: 'email',
                  name: 'email',
                  inputType: 'password',
                  placeholder: 'Enter your password',
                  required: true,
                  styles: {
                    borderRadius: '8px',
                    fontSize: '16px',
                    paddingLeft: '16px',
                    paddingRight: '16px',
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    borderColor: 'rgba(0,0,0,0.36)',
                    paddingTop: '6px',
                    paddingBottom: '6px',
                  },
                },
              ],
              dropDown: true,
              styles: {
                marginTop: '',
                paddingLeft: '10px',
                paddingRight: '10px',
                width: '50%',
              },
            },
            {
              type: 'block',
              active: false,
              editable: true,
              childrens: [
                {
                  type: 'label',
                  active: false,
                  editable: true,
                  innerText: 'Confirm Password',
                  htmlFor: 'email',
                  styles: {
                    fontSize: '14px',
                    paddingLeft: '6px',
                    marginBottom: '3px',
                  },
                },
                {
                  type: 'input',
                  active: false,
                  editable: true,
                  value: '',
                  inputName: 'confirmPassword',
                  id: 'email',
                  name: 'email',
                  inputType: 'password',
                  placeholder: 'Confirm your password',
                  required: true,
                  styles: {
                    borderRadius: '8px',
                    fontSize: '16px',
                    paddingLeft: '16px',
                    paddingRight: '16px',
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    borderColor: 'rgba(0,0,0,0.36)',
                    paddingTop: '6px',
                    paddingBottom: '6px',
                  },
                },
              ],
              dropDown: true,
              styles: {
                marginTop: '',
                paddingLeft: '10px',
                paddingRight: '10px',
                width: '50%',
              },
            },
          ],
          dropDown: true,
        },
      ],
    })
    state.setState({ ...state.values })
  }

  return (
    <>
      <div onClick={() => setDetails(!details)} className="property-section">
        <div className={`mr-10 ${details ? 'rotate-bottom' : 'rotate-right'}`}>
          <SvgIcons.DropDownTriangleIcon
            width="11px"
            height="11px"
            color={ColorSchemeCode.selectButtonColor}
          />
        </div>
        <div className="fs-12 fw-5 color-selectButtonColor middle">Steps</div>
        <div onClick={addStep} className="ml-auto">
          <SvgIcons.AddIcon width="15px" height="15px" color={ColorSchemeCode.selectButtonColor} />
        </div>
      </div>
      {details && (
        <div className="mt-16">
          {element.steps.map((step, index) => (
            <div className="mt-16">
              <StepItem state={state} element={element} step={step} index={index} />
            </div>
          ))}

          <div className="property-name-divider mt-16"></div>
        </div>
      )}
    </>
  )
}

const Stepper = ({ state, element }) => {
  return (
    <>
      <StepperBackground state={state} element={element} />
      <StepperTextColor state={state} element={element} />
      <StepDetails state={state} element={element} />
    </>
  )
}

const OptionItem = ({ state, element, option, index }) => {
  const handleDeleteStep = () => {
    element.options.splice(index, 1)
    state.setState({ ...state.values })
  }

  const handleChange = (e) => {
    element.options[index].label = e.target.value
    state.setState({ ...state.values })
  }

  return (
    <div className="d-flex align-items-baseline position-relative ">
      <div class="body2 fw-5 w-35 mr-8 fs-12">
        <div className="d-flex align-items-baseline dropdown-input-box  bg-color-widgetNavBackgroundColor">
          <input
            className={`sizeInputField border-0 w-100 color-selectButtonColor bg-color-widgetNavBackgroundColor`}
            placeholder={'Label ' + (index + 1)}
            height={'37px'}
            fontSize={'12px'}
            value={option.label}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="w-60">
        <div className="d-flex align-items-baseline dropdown-input-box  bg-color-widgetNavBackgroundColor">
          <input
            className={`sizeInputField border-0 w-100 color-selectButtonColor bg-color-widgetNavBackgroundColor`}
            placeholder={'Value ' + (index + 1)}
            height={'37px'}
            fontSize={'12px'}
            value={option.value}
            onChange={handleChange}
          />
        </div>
      </div>
      <div onClick={handleDeleteStep} className="w-fit cp text-center ml-8">
        <SvgIcons.DeleteIcon color={ColorSchemeCode.selectButtonColor} width={13} height={13} />
      </div>
    </div>
  )
}

const SelectOptions = ({ state, element }) => {
  const [details, setDetails] = useState(true)

  const addOption = (e) => {
    e.stopPropagation()
    element.options.push({ label: '', value: '' })
    state.setState({ ...state.values })
  }

  return (
    <>
      <div onClick={() => setDetails(!details)} className="property-section">
        <div className={`mr-10 ${details ? 'rotate-bottom' : 'rotate-right'}`}>
          <SvgIcons.DropDownTriangleIcon
            width="11px"
            height="11px"
            color={ColorSchemeCode.selectButtonColor}
          />
        </div>
        <div className="fs-12 fw-5 color-selectButtonColor middle">Options</div>
        <div onClick={addOption} className="ml-auto">
          <SvgIcons.AddIcon width="15px" height="15px" color={ColorSchemeCode.selectButtonColor} />
        </div>
      </div>
      {details && (
        <div className="mt-16">
          {element.options.map((option, index) => (
            <div className="mt-16">
              <OptionItem state={state} element={element} option={option} index={index} />
            </div>
          ))}

          <div className="property-name-divider mt-16"></div>
        </div>
      )}
    </>
  )
}

const Select = ({ state, element }) => {
  return (
    <>
      <SelectOptions state={state} element={element} />
    </>
  )
}

const BorderStyle = ({ state, element }) => {
  return (
    <div>
      <div className="d-flex align-items-center mb-10 ">
        <div class="body2 fw-5 w-35 mr-8 fs-12">Style</div>
        <div className="w-60 ml-auto">
          <CustomSelect
            options={Options.BorderStyles()}
            height={'10px'}
            borderRadius={'4px'}
            fontSize={'12px'}
            backgroundColor={ColorSchemeCode.widgetNavBackgroundColor}
            value={element.styles?.borderStyle}
            onChange={(e) =>
              handleStyleChange({ state, element, key: 'borderStyle', value: e.target.value })
            }
          />
        </div>
      </div>
    </div>
  )
}

const BorderRadius = ({ state, element }) => {
  const [individual, setIndividual] = useState(false)

  const handleIndividualCheck = () => {
    individual && delete element.styles?.borderRadius
    setIndividual(!individual)
    state.setState({ ...state.values })
  }

  return (
    <div id="BorderRadius">
      <div className="d-flex align-items-center ">
        <div className="body2 fw-5 mr-25 pb-10 fs-12">Radius</div>
        <div className={`d-flex align-items-center w-100 ${individual && 'disabled'}`}>
          <div className="w-100 mr-10">
            <CustomSlider
              value={element.styles?.borderRadius ? parseInt(element.styles?.borderRadius) : 0}
              onChange={(e, value) =>
                handleStyleChange({
                  state,
                  element,
                  key: 'borderRadius',
                  value: value ? value + 'px' : '',
                })
              }
            />
          </div>
          <div className="pb-10 position-relative">
            <CustomTextField
              value={element.styles?.borderRadius ? parseInt(element.styles?.borderRadius) : ''}
              height={'27px'}
              padding={'8px'}
              fontSize={'12px'}
              borderRadius={'4px'}
              placeholderColor={ColorSchemeCode.unselectTextColor}
              backgroundColor={ColorSchemeCode.widgetNavBackgroundColor}
              inputProps={{ type: 'number' }}
              onChange={(e) =>
                handleStyleChange({
                  state,
                  element,
                  key: 'borderRadius',
                  value: e.target.value ? e.target.value + 'px' : '',
                })
              }
            />
            <div className="Tag">px</div>
          </div>
        </div>
      </div>

      <div className="ml-auto">
        <CustomCheckBox
          label={'Individual Corner'}
          fontSize={'12px'}
          marginTop={'2px'}
          className="checkboxLabel color-textfieldColor"
          name="borderRadiusCorners"
          value={individual}
          fontWeight={'500'}
          color={ColorSchemeCode.selectButtonColor}
          onChange={handleIndividualCheck}
        />
      </div>

      {individual && (
        <>
          <div className="d-flex align-items-center">
            <div className="col-6 px-0 pr-8 d-flex align-items-center">
              <div>
                <SvgIcons.RadiusTopLeft />
              </div>
              <CustomTextField
                placeholder={'Top'}
                height={'27px'}
                padding={'8px'}
                className={'w-80'}
                fontSize={'12px'}
                borderRadius={'4px'}
                placeholderColor={ColorSchemeCode.unselectTextColor}
                backgroundColor={ColorSchemeCode.widgetNavBackgroundColor}
                inputProps={{ type: 'number' }}
                value={
                  element.styles?.borderTopLeftRadius
                    ? parseInt(element.styles?.borderTopLeftRadius)
                    : ''
                }
                onChange={(e) =>
                  handleStyleChange({
                    state,
                    element,
                    key: 'borderTopLeftRadius',
                    value: e.target.value ? e.target.value + 'px' : '',
                  })
                }
              />
            </div>
            <div className="col-6 px-0 pl-8 d-flex align-items-center">
              <div>
                <SvgIcons.RaduisTopRight />
              </div>
              <CustomTextField
                placeholder={'Bottom'}
                height={'27px'}
                className={'w-80'}
                padding={'8px'}
                fontSize={'12px'}
                placeholderColor={ColorSchemeCode.unselectTextColor}
                backgroundColor={ColorSchemeCode.widgetNavBackgroundColor}
                borderRadius={'4px'}
                inputProps={{ type: 'number' }}
                value={
                  element.styles?.borderTopRightRadius
                    ? parseInt(element.styles?.borderTopRightRadius)
                    : ''
                }
                onChange={(e) =>
                  handleStyleChange({
                    state,
                    element,
                    key: 'borderTopRightRadius',
                    value: e.target.value ? e.target.value + 'px' : '',
                  })
                }
              />
            </div>
          </div>

          <div className="d-flex align-items-center mt-18 ">
            <div className="col-6 px-0 pr-8 d-flex align-items-center">
              <div>
                <SvgIcons.RadiusBottomLeft />
              </div>
              <CustomTextField
                placeholder={'Left'}
                height={'27px'}
                padding={'8px'}
                className={'w-80'}
                fontSize={'12px'}
                placeholderColor={ColorSchemeCode.unselectTextColor}
                backgroundColor={ColorSchemeCode.widgetNavBackgroundColor}
                borderRadius={'4px'}
                inputProps={{ type: 'number' }}
                value={
                  element.styles?.borderBottomLeftRadius
                    ? parseInt(element.styles?.borderBottomLeftRadius)
                    : ''
                }
                onChange={(e) =>
                  handleStyleChange({
                    state,
                    element,
                    key: 'borderBottomLeftRadius',
                    value: e.target.value ? e.target.value + 'px' : '',
                  })
                }
              />
            </div>
            <div className="col-6 px-0 pl-8 d-flex align-items-center">
              <div>
                <SvgIcons.RadiusBottomRight />
              </div>
              <CustomTextField
                placeholder={'Right'}
                height={'27px'}
                className={'w-80'}
                padding={'8px'}
                fontSize={'12px'}
                borderRadius={'4px'}
                inputProps={{ type: 'number' }}
                placeholderColor={ColorSchemeCode.unselectTextColor}
                backgroundColor={ColorSchemeCode.widgetNavBackgroundColor}
                value={
                  element.styles?.borderBottomRightRadius
                    ? parseInt(element.styles?.borderBottomRightRadius)
                    : ''
                }
                onChange={(e) =>
                  handleStyleChange({
                    state,
                    element,
                    key: 'borderBottomRightRadius',
                    value: e.target.value ? e.target.value + 'px' : '',
                  })
                }
              />
            </div>
          </div>
        </>
      )}
    </div>
  )
}

const BorderWidth = ({ state, element }) => {
  return (
    <div className="BorderWidth">
      <div className="d-flex align-items-center ">
        <div className="body2 fw-5 mr-25 pb-10 fs-12">Width</div>

        <div className="w-100 mr-10">
          <CustomSlider
            value={element.styles?.borderWidth ? parseInt(element.styles?.borderWidth) : 0}
            onChange={(e, value) =>
              handleStyleChange({
                state,
                element,
                key: 'borderWidth',
                value: value ? value + 'px' : '',
              })
            }
          />
        </div>
        <div className="pb-15 position-relative">
          <CustomTextField
            value={element.styles?.borderWidth ? parseInt(element.styles?.borderWidth) : ''}
            height={'27px'}
            padding={'8px'}
            fontSize={'12px'}
            borderRadius={'4px'}
            placeholderColor={ColorSchemeCode.unselectTextColor}
            inputProps={{ type: 'number' }}
            onChange={(e) =>
              handleStyleChange({
                state,
                element,
                key: 'borderWidth',
                value: e.target.value ? e.target.value + 'px' : '',
              })
            }
          />
          <div className="Tag">px</div>
        </div>
      </div>

      <PickColor
        fieldClass={'w-75 ml-auto'}
        color={element.styles?.borderColor}
        onChange={(hex) => handleStyleChange({ state, element, key: 'borderColor', value: hex })}
      />
    </div>
  )
}

const Border = ({ state, element }) => {
  const [details, setDetails] = useState(true)

  return (
    <>
      <div onClick={() => setDetails(!details)} className="property-section">
        <div className={`mr-10 ${details ? 'rotate-bottom' : 'rotate-right'}`}>
          <SvgIcons.DropDownTriangleIcon
            width="11px"
            height="11px"
            color={ColorSchemeCode.selectButtonColor}
          />
        </div>
        <div className="fs-12 fw-5 color-selectButtonColor middle">Borders</div>
      </div>
      {details && (
        <div className="mt-16 mb-16">
          <BorderStyle state={state} element={element} />
          <BorderRadius state={state} element={element} />
          <div className="divider"></div>
          <BorderWidth state={state} element={element} />
          {/* <div className="divider"></div> */}
        </div>
      )}
    </>
  )
}

const CSS = ({ state, element }) => {
  const [details, setDetails] = useState(true)

  return (
    <>
      <div onClick={() => setDetails(!details)} className="property-section">
        <div className={`mr-10 ${details ? 'rotate-bottom' : 'rotate-right'}`}>
          <SvgIcons.DropDownTriangleIcon
            width="11px"
            height="11px"
            color={ColorSchemeCode.selectButtonColor}
          />
        </div>
        <div className="fs-12 fw-5 color-selectButtonColor middle">Custom CSS</div>
      </div>
      {details && (
        <div className="mt-16 mb-16">
          <div className="d-flex align-items-baseline position-relative">
            <div className="d-flex align-items-baseline dropdown-input-box bg-color-widgetNavBackgroundColor w-100 h-100">
              <textarea
                className={`sizeInputField color-selectButtonColor border-0 w-100 bg-color-widgetNavBackgroundColor`}
                placeholder={'background-color: #f0f0f0;'}
                fontSize={'12px'}
                height={'37px'}
                rows={4}
                value={element.css}
                onChange={(e) =>
                  handleChange({ state, element, key: 'css', value: e.target.value })
                }
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const Fonts = ({ state, element }) => {
  return (
    <div id="Fonts">
      <div className="d-flex align-items-center mb-10 ">
        <div class="body2 fw-5 w-35 mr-8 fs-12">Font Family</div>
        <div className="w-60 ml-auto">
          <CustomSelect
            options={Options.FontFamily()}
            fontSize={'12px'}
            height={'10px'}
            borderRadius={'4px'}
            backgroundColor={ColorSchemeCode.widgetNavBackgroundColor}
            value={element.styles?.fontFamily ? element.styles?.fontFamily : 'Poppins'}
            // value           = {element.styles?.fontFamily}
            onChange={(e) =>
              handleStyleChange({ state, element, key: 'fontFamily', value: e.target.value })
            }
          />
        </div>
      </div>

      <div className="d-flex align-items-center mb-10 ">
        <div class="body2 fw-5 w-35 mr-8 fs-12">Weight</div>
        <div className="w-60 ml-auto">
          <CustomSelect
            options={Options.FontWeight()}
            height={'10px'}
            fontSize={'12px'}
            borderRadius={'4px'}
            backgroundColor={ColorSchemeCode.widgetNavBackgroundColor}
            //value           = {element.styles?.fontWeight}
            value={element.styles?.fontWeight ? element.styles?.fontWeight : '400'}
            onChange={(e) =>
              handleStyleChange({ state, element, key: 'fontWeight', value: e.target.value })
            }
          />
        </div>
      </div>

      <div className="d-flex align-items-center mb-16 ">
        <div className="body2 fw-5 mr-35 fs-12">Size</div>
        <div className="w-60 ml-auto">
          <CustomTextField
            placeholder={'Auto'}
            padding={'8px'}
            fontSize={'12px'}
            value={element.styles?.fontSize ? parseInt(element.styles?.fontSize) : '16'}
            height={'32px'}
            backgroundColor={ColorSchemeCode.widgetNavBackgroundColor}
            color={ColorSchemeCode.selectButtonColor}
            borderRadius={'4px'}
            inputProps={{ type: 'number' }}
            onChange={(e) =>
              handleStyleChange({
                state,
                element,
                key: 'fontSize',
                value: e.target.value ? e.target.value + 'px' : '',
              })
            }
          />
        </div>
      </div>

      <PickColor
        fieldClass={'w-60 ml-auto'}
        color={element.styles?.color}
        onChange={(hex) => handleStyleChange({ state, element, key: 'color', value: hex })}
      />

      <div className="d-flex align-items-center mt-15 ">
        <div className="body2 fw-5 mr-35 fs-12">Align</div>
        <div className="d-flex justify-content-between w-60 ml-auto text-alignment bg-color-widgetNavBackgroundColor selectItems">
          <div
            onClick={() => handleStyleChange({ state, element, key: 'textAlign', value: 'left' })}
            className={`cp ${element.styles?.textAlign === 'left' && 'selected'}`}
          >
            <SvgIcons.TextAlign
              color={
                element.styles?.textAlign === 'left'
                  ? ColorSchemeCode.themeColor
                  : ColorSchemeCode.unselectTextColor
              }
            />
          </div>
          <div
            onClick={() => handleStyleChange({ state, element, key: 'textAlign', value: 'center' })}
            className={`cp ${element.styles?.textAlign === 'center' && 'selected'}`}
          >
            <SvgIcons.TextAlignCenter
              color={
                element.styles?.textAlign === 'center'
                  ? ColorSchemeCode.themeColor
                  : ColorSchemeCode.unselectTextColor
              }
            />
          </div>
          <div
            onClick={() => handleStyleChange({ state, element, key: 'textAlign', value: 'right' })}
            className={`flip ${element.styles?.textAlign === 'right' && 'selected'}`}
          >
            <SvgIcons.TextAlign
              color={
                element.styles?.textAlign === 'right'
                  ? ColorSchemeCode.themeColor
                  : ColorSchemeCode.unselectTextColor
              }
            />
          </div>
        </div>
      </div>

      <div className="d-flex align-items-center mt-15 ">
        <div className="body2 fw-5 mr-35 fs-12">Style</div>
        <div className="d-flex justify-content-between w-60 ml-auto text-style bg-color-widgetNavBackgroundColor selectItems">
          <div
            onClick={() =>
              handleStyleChange({
                state,
                element,
                key: 'textDecoration',
                value: element.styles?.textDecoration === 'underline' ? 'none' : 'underline',
              })
            }
            className={`cp ${element.styles?.textDecoration === 'underline' && 'selected'}`}
          >
            <SvgIcons.TextUnderline
              color={
                element.styles?.textDecoration === 'underline'
                  ? ColorSchemeCode.themeColor
                  : ColorSchemeCode.unselectTextColor
              }
            />
          </div>
          <div
            onClick={() =>
              handleStyleChange({
                state,
                element,
                key: 'fontStyle',
                value: element.styles?.fontStyle === 'italic' ? 'normal' : 'italic',
              })
            }
            className={`cp ${element.styles?.fontStyle === 'italic' && 'selected'}`}
          >
            <SvgIcons.TextItalic
              color={
                element.styles?.fontStyle === 'italic'
                  ? ColorSchemeCode.themeColor
                  : ColorSchemeCode.unselectTextColor
              }
            />
          </div>
          <div
            onClick={() =>
              handleStyleChange({
                state,
                element,
                key: 'textDecoration',
                value: element.styles?.textDecoration === 'line-through' ? 'none' : 'line-through',
              })
            }
            className={`cp ${element.styles?.textDecoration === 'line-through' && 'selected'}`}
          >
            <SvgIcons.TextStrikeThrough
              color={
                element.styles?.textDecoration === 'line-through'
                  ? ColorSchemeCode.themeColor
                  : ColorSchemeCode.unselectTextColor
              }
            />
          </div>
          {/* <div><SvgIcons.TextBulletedList /></div> */}
        </div>
      </div>
    </div>
  )
}

const Typography = ({ state, element }) => {
  const [details, setDetails] = useState(true)

  return (
    <>
      <div onClick={() => setDetails(!details)} className="property-section">
        <div className={`mr-10 ${details ? 'rotate-bottom' : 'rotate-right'}`}>
          <SvgIcons.DropDownTriangleIcon
            width="11px"
            height="11px"
            color={ColorSchemeCode.selectButtonColor}
          />
        </div>
        <div className="fs-12 fw-5 color-selectButtonColor middle">Typography</div>
      </div>
      {details && (
        <div className="mt-16">
          <Fonts state={state} element={element} />
          <div className="property-name-divider mt-16"></div>
        </div>
      )}
    </>
  )
}

const Background = ({ state, element }) => {
  const [details, setDetails] = useState(true)

  const handleImageChange = (url) => {
    element.styles.backgroundImage = `url(${url})`
    state.setState({ ...state.values })
  }

  const handleImageDelete = () => {
    delete element.styles?.backgroundImage
    state.setState({ ...state.values })
  }

  return (
    <>
      <div className="property-name-divider"></div>
      <div onClick={() => setDetails(!details)} className="property-section">
        <div className={`mr-10 ${details ? 'rotate-bottom' : 'rotate-right'}`}>
          <SvgIcons.DropDownTriangleIcon
            width="11px"
            height="11px"
            color={ColorSchemeCode.selectButtonColor}
          />
        </div>
        <div className="fs-12 fw-5 color-selectButtonColor middle">Background</div>
      </div>
      {details && (
        <div className="mt-16">
          <div className="mb-15 fw-5">
            <UploadImage
              onChange={handleImageChange}
              fieldClass={'w-75 ml-auto borderRadius-4'}
              imageUrl={
                element.styles?.backgroundImage &&
                element.styles?.backgroundImage.match(/[^(\(]+(?=\))/)[0]
              }
              deleteImage={handleImageDelete}
            />
          </div>
          <div className="divider"></div>
          <div className="mb-16">
            <BackgrounProperties state={state} element={element} />
          </div>
          <div className="divider"></div>
          <div className="mb-16">
            <PickColor
              color={element.styles?.backgroundColor}
              fieldClass={'w-75 ml-auto'}
              onChange={(hex) =>
                handleStyleChange({ state, element, key: 'backgroundColor', value: hex })
              }
            />
          </div>
          <div className="property-name-divider"></div>
        </div>
      )}
    </>
  )
}

const Opacity = ({ state, element }) => {
  return (
    <div className="d-flex align-items-center">
      <div className="body2 fw-5 mr-25 pb-10 fs-12">Opacity</div>
      <div className={`d-flex align-items-center`}>
        <div className="w-100 mr-10">
          <CustomSlider
            value={element.styles?.opacity * 100}
            min={0}
            max={100}
            onChange={(e, value) =>
              handleStyleChange({ state, element, key: 'opacity', value: value / 100 })
            }
          />
        </div>
        <div className="pb-10">
          <CustomTextField
            value={element.styles?.opacity}
            height={'32px'}
            padding={'8px'}
            borderRadius={'4px'}
            placeholderColor={ColorSchemeCode.unselectTextColor}
            inputProps={{ type: 'number', readOnly: true }}
            // onChange     = {(e)=>handleStyleChange({state, element, key:'opacity', value: e.target.value})}
          />
        </div>
      </div>
    </div>
  )
}

const Image = ({ state, element }) => {
  const [details, setDetails] = useState(true)

  const handleImageChange = (url) => {
    element.src = url
    state.setState({ ...state.values })
  }

  const handleImageDelete = () => {
    delete element.src
    state.setState({ ...state.values })
  }

  return (
    <>
      <div onClick={() => setDetails(!details)} className="property-section">
        <div className={`mr-10 ${details ? 'rotate-bottom' : 'rotate-right'}`}>
          <SvgIcons.DropDownTriangleIcon
            width="11px"
            height="11px"
            color={ColorSchemeCode.selectButtonColor}
          />
        </div>
        <div className="w-35 fs-12">Image</div>
      </div>
      {details && (
        <div className="mt-16 ml-8">
          <div className="mb-15">
            <UploadImage
              onChange={handleImageChange}
              fieldClass={'w-75 ml-auto'}
              imageUrl={element.src}
              deleteImage={handleImageDelete}
            />
          </div>
          <div className="mt-16">
            <Opacity state={state} element={element} />
          </div>
          <div className="property-name-divider"></div>
        </div>
      )}
    </>
  )
}

const Form = ({ state, element }) => {
  const [details, setDetails] = useState(true)

  const handleSubmitActions = (e) => {
    element.submitAction = e.target.value
    state.setState({ ...state.values })
  }

  return (
    <>
      <div onClick={() => setDetails(!details)} className="property-section">
        <div className={`mr-10 ${details ? 'rotate-bottom' : 'rotate-right'}`}>
          <SvgIcons.DropDownTriangleIcon
            width="11px"
            height="11px"
            color={ColorSchemeCode.selectButtonColor}
          />
        </div>
        <div>Form</div>
      </div>

      {details && (
        <div className="mt-16 mb-16">
          <div className="d-flex align-items-center mb-10">
            <div class="body2 fw-5 w-35 mr-8 fs-12">Submit Actions</div>
            <div className="w-60 ml-auto">
              <CustomSelect
                options={Options.SubmitActions()}
                height={'10px'}
                fontSize={'12px'}
                borderRadius={'4px'}
                value={element.submitAction}
                onChange={handleSubmitActions}
                backgroundColor={ColorSchemeCode.widgetNavBackgroundColor}
              />
            </div>
          </div>
          <div className="property-name-divider"></div>
        </div>
      )}
    </>
  )
}

const Placeholder = ({ state, element }) => {
  return (
    <div className="d-flex align-items-center mb-10">
      <div class="body2 fw-5 w-35 mr-8 fs-12">Placeholder</div>
      <div className="w-60 ml-auto">
        <CustomTextField
          placeholder={'Auto'}
          height={'32px'}
          padding={'8px'}
          borderRadius={'4px'}
          value={element.placeholder}
          placeholderColor={ColorSchemeCode.unselectTextColor}
          onChange={(e) =>
            handleChange({ state, element, key: 'placeholder', value: e.target.value })
          }
        />
      </div>
    </div>
  )
}

const IdName = ({ state, element }) => {
  return (
    <div className="d-flex align-items-center mb-10">
      <div class="body2 fw-5 w-35 mr-8 fs-12">ID / Name</div>
      <div className="w-60 ml-auto">
        <CustomTextField
          placeholder={'Auto'}
          height={'32px'}
          padding={'8px'}
          borderRadius={'4px'}
          value={element.inputName}
          placeholderColor={ColorSchemeCode.unselectTextColor}
          onChange={(e) =>
            handleChange({ state, element, key: 'inputName', value: e.target.value })
          }
        />
      </div>
    </div>
  )
}

const CustomValueSelect = ({ state, element }) => {
  const [fields, setFields] = useState(false)
  const handleCustomFieldSelect = (selectedField) => {
    setFields(false)
    element.inputName = selectedField.key
    if (selectedField.value) element.value = selectedField.value
    state.setState({ ...state.values })
  }
  return (
    <div className="d-flex align-items-center mb-10">
      <div class="body2 fw-5 w-35 mr-8 fs-12">Custom Values</div>
      <div className="w-60 ml-auto position-relative">
        <ClickOutside onClick={() => setFields(false)}>
          <div onClick={() => setFields(!fields)} className="customfield-block">
            <div
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
              className="body2"
            >
              {element.inputName}
            </div>
            <div>
              <SvgIcons.DropDownTriangleIcon color={'inherit'} width={'12'} />
            </div>
          </div>
          {fields && (
            <CustomFieldsDropDown
              title={'Select Fields'}
              className={'position-absolute mt-0'}
              style={{ width: '260px', right: '0' }}
              selectValue={handleCustomFieldSelect}
            />
          )}
        </ClickOutside>
      </div>
    </div>
  )
}

const RequiredCheck = ({ state, element }) => {
  return (
    <div className="d-flex align-items-center mb-10">
      <div class="body2 fw-5 w-35 mr-8 fs-12">Required</div>
      <div className="w-60 ml-auto">
        <CustomCheckBox
          className="checkboxLabel color-textfieldColor"
          name="required"
          fontSize={'12px'}
          fontWeight={'500'}
          marginTop={'2px'}
          color={ColorSchemeCode.selectButtonColor}
          value={element.required}
          onChange={(e) =>
            handleChange({ state, element, key: 'required', value: !element.required })
          }
        />
      </div>
    </div>
  )
}

const Input = ({ state, element }) => {
  const [details, setDetails] = useState(true)

  const handleTypeChange = (e) => {
    element.inputType = e.target.value
    state.setState({ ...state.values })
  }

  return (
    <>
      <div onClick={() => setDetails(!details)} className="property-section">
        <div className={`mr-10 ${details ? 'rotate-bottom' : 'rotate-right'}`}>
          <SvgIcons.DropDownTriangleIcon
            width="11px"
            height="11px"
            color={ColorSchemeCode.selectButtonColor}
          />
        </div>
        <div>Input</div>
      </div>

      {details && (
        <div className="mt-16 mb-16">
          <Placeholder state={state} element={element} />
          <IdName state={state} element={element} />
          {
            <div className="d-flex align-items-center mb-10">
              <div class="body2 fw-5 w-35 mr-8 fs-12">Input Type</div>
              <div className="w-60 ml-auto">
                <CustomSelect
                  options={Options.InputTypes()}
                  height={'32'}
                  fontSize={'12px'}
                  borderRadius={'4px'}
                  value={element.inputType}
                  onChange={handleTypeChange}
                  placeholderColor={ColorSchemeCode.unselectTextColor}
                  backgroundColor={ColorSchemeCode.widgetNavBackgroundColor}
                />
              </div>
            </div>
          }
          <CustomValueSelect state={state} element={element} />
          <RequiredCheck state={state} element={element} />

          <div className="property-name-divider"></div>
        </div>
      )}
    </>
  )
}

const TextArea = ({ state, element }) => {
  const [details, setDetails] = useState(true)

  return (
    <>
      <div onClick={() => setDetails(!details)} className="property-section">
        <div className={`mr-10 ${details ? 'rotate-bottom' : 'rotate-right'}`}>
          <SvgIcons.DropDownTriangleIcon
            width="11px"
            height="11px"
            color={ColorSchemeCode.selectButtonColor}
          />
        </div>
        <div>Textarea</div>
      </div>

      {details && (
        <div className="mt-16 mb-16">
          <Placeholder state={state} element={element} />
          <IdName state={state} element={element} />
          <CustomValueSelect state={state} element={element} />
          <RequiredCheck state={state} element={element} />
          <div className="property-name-divider"></div>
        </div>
      )}
    </>
  )
}

const Checkbox = ({ state, element, label, keyName }) => {
  console.log(keyName, element[keyName])
  return (
    <div className="d-flex align-items-center mb-10">
      <div class="body2 fw-5 w-35 mr-8 fs-12">{label}</div>
      <div className="w-60 ml-auto">
        <CustomCheckBox
          className="checkboxLabel color-textfieldColor"
          name="required"
          fontSize={'12px'}
          fontWeight={'500'}
          marginTop={'2px'}
          color={ColorSchemeCode.selectButtonColor}
          value={element[keyName]}
          onChange={(e) => handleChange({ state, element, key: keyName, value: !element[keyName] })}
        />
      </div>
    </div>
  )
}

const Video = ({ state, element }) => {
  const [details, setDetails] = useState(true)
  return (
    <>
      <div onClick={() => setDetails(!details)} className="property-section">
        <div className={`mr-10 ${details ? 'rotate-bottom' : 'rotate-right'}`}>
          <SvgIcons.DropDownTriangleIcon
            width="11px"
            height="11px"
            color={ColorSchemeCode.selectButtonColor}
          />
        </div>
        <div>Video</div>
      </div>

      {details && (
        <div className="mt-16 mb-16">
          <Checkbox state={state} element={element} keyName={'controls'} label={'Controls'} />
          <Checkbox state={state} element={element} keyName={'autoPlay'} label={'Auto Play'} />
          <Checkbox state={state} element={element} keyName={'loop'} label={'Loop'} />
          <Checkbox state={state} element={element} keyName={'muted'} label={'Muted'} />
          <Checkbox
            state={state}
            element={element}
            keyName={'playsInline'}
            label={'Plays Inline'}
          />
          <div className="property-name-divider"></div>
        </div>
      )}
    </>
  )
}

const Link = ({ state, element }) => {
  const [details, setDetails] = useState(true)
  const [fields, setFields] = useState(false)

  const handleTypeChange = (e) => {
    element.inputType = e.target.value
    state.setState({ ...state.values })
  }

  const handleCustomFieldSelect = (selectedField) => {
    setFields(false)
    element.inputName = selectedField.key
    if (selectedField.value) element.value = selectedField.value
    state.setState({ ...state.values })
  }

  return (
    <>
      <div onClick={() => setDetails(!details)} className="property-section">
        <div className={`mr-10 ${details ? 'rotate-bottom' : 'rotate-right'}`}>
          <SvgIcons.DropDownTriangleIcon
            width="11px"
            height="11px"
            color={ColorSchemeCode.selectButtonColor}
          />
        </div>
        <div>Input</div>
      </div>

      {details && (
        <div className="mt-16 mb-16">
          <div className="d-flex align-items-center mb-10">
            <div class="body2 fw-5 w-35 mr-8 fs-12">Placeholder</div>
            <div className="w-60 ml-auto">
              <CustomTextField
                placeholder={'Auto'}
                height={'32px'}
                padding={'8px'}
                borderRadius={'4px'}
                value={element.placeholder}
                placeholderColor={ColorSchemeCode.unselectTextColor}
                onChange={(e) =>
                  handleChange({ state, element, key: 'placeholder', value: e.target.value })
                }
              />
            </div>
          </div>

          {
            <div className="d-flex align-items-center mb-10">
              <div class="body2 fw-5 w-35 mr-8 fs-12">Input Type</div>
              <div className="w-60 ml-auto">
                <CustomSelect
                  options={Options.InputTypes()}
                  height={'32'}
                  fontSize={'12px'}
                  borderRadius={'4px'}
                  value={element.inputType}
                  onChange={handleTypeChange}
                  placeholderColor={ColorSchemeCode.unselectTextColor}
                  backgroundColor={ColorSchemeCode.widgetNavBackgroundColor}
                />
              </div>
            </div>
          }

          {
            <div className="d-flex align-items-center mb-10">
              <div class="body2 fw-5 w-35 mr-8 fs-12">Custom Fields</div>
              <div className="w-60 ml-auto position-relative">
                <ClickOutside onClick={() => setFields(false)}>
                  <div onClick={() => setFields(!fields)} className="customfield-block">
                    <div className="body2">{element.inputName}</div>
                    <div>
                      <SvgIcons.DropDownTriangleIcon color={'inherit'} width={'12'} />
                    </div>
                  </div>
                  {fields && (
                    <CustomFieldsDropDown
                      title={'Select Fields'}
                      className={'position-absolute mt-0'}
                      style={{ width: '260px', right: '0' }}
                      selectValue={handleCustomFieldSelect}
                    />
                  )}
                </ClickOutside>
              </div>
            </div>
          }

          {
            <div className="d-flex align-items-center mb-10">
              <div class="body2 fw-5 w-35 mr-8 fs-12">Required</div>
              <div className="w-60 ml-auto">
                <CustomCheckBox
                  className="checkboxLabel color-textfieldColor"
                  name="required"
                  fontSize={'12px'}
                  fontWeight={'500'}
                  marginTop={'2px'}
                  color={ColorSchemeCode.selectButtonColor}
                  value={element.required}
                  onChange={(e) =>
                    handleChange({ state, element, key: 'required', value: !element.required })
                  }
                />
              </div>
            </div>
          }

          <div className="property-name-divider"></div>
        </div>
      )}
    </>
  )
}

const Styles = ({ state, element }) => {
  return (
    <div id="Settings" className="pl-16 pr-16">
      <Layout state={state} element={element} />
      <Size state={state} element={element} />
      <Spacing state={state} element={element} />
      <Typography state={state} element={element} />
      <Background state={state} element={element} />
      <Border state={state} element={element} />
      <CSS state={state} element={element} />
    </div>
  )
}

const Settings = ({ state, element }) => {
  return (
    <div id="Settings" className="pl-16 pr-16">
      {element.type === 'form' && <Form state={state} element={element} />}
      {element.type === 'input' && element.editable !== false && (
        <Input state={state} element={element} />
      )}
      {element.type === 'link' && <Link state={state} element={element} />}
      {element.type === 'image' && <Image state={state} element={element} />}
      {element.type === 'block' && <Layout state={state} element={element} />}
      {element.type === 'stepper' && <Stepper state={state} element={element} />}
      {element.type === 'select' && <Select state={state} element={element} />}
      {element.type === 'textarea' && <TextArea state={state} element={element} />}
      {element.type === 'video' && <Video state={state} element={element} />}
    </div>
  )
}

function RightPane({ state, focused }) {
  const [properties, setProperties] = useState('styles')

  const deleteElement = () => {
    const activeElement = focused.value.element
    console.log('Active Element', activeElement)

    const findParentAndDeleteChild = (parent) => {
      const index = parent.childrens.findIndex((element) => {
        if (JSON.stringify(element) === JSON.stringify(activeElement)) return true
        if (element.type == 'stepper') return findStepperElementAndDeleteChild(element)
        if (element.childrens) return findParentAndDeleteChild(element)
      })
      if (index > -1) parent.childrens.splice(index, 1)
    }

    function findStepperElementAndDeleteChild(parent) {
      parent.steps.find((step) => findParentAndDeleteChild(step))
    }

    findParentAndDeleteChild(state.values.json)

    focused.setFocused({})
    state.setState({ ...state.values })
  }

  const handleElementNameChange = (name) => {
    focused.value.element.name = name
    state.setState({ ...state.values })
  }

  return (
    <div id="RightPane">
      <div className="properties">
        <div
          onClick={() => setProperties('styles')}
          className={`properties-options middle  ${properties === 'styles' && 'active'}`}
        >
          <div className="singleSelectButton">
            <span className="mr-8">
              <SvgIcons.BrushIcon
                height="12px"
                width="12px"
                color={
                  properties === 'styles'
                    ? ColorSchemeCode.cffffff
                    : ColorSchemeCode.unselectTextColor
                }
              />
            </span>
            <span className="topBarItems">Style</span>
          </div>
        </div>
        <div
          onClick={() => setProperties('settings')}
          className={`properties-options middle ${properties === 'settings' && 'active'}`}
        >
          <div className="singleSelectButton">
            <span className="mr-8">
              <SvgIcons.NavSettingIcon
                height="12px"
                width="12px"
                color={
                  properties === 'settings'
                    ? ColorSchemeCode.cffffff
                    : ColorSchemeCode.unselectTextColor
                }
              />
            </span>
            <span className="topBarItems">Settings</span>
          </div>
        </div>
      </div>

      {focused.value.element && (
        <>
          <div className="color-c4F4F4F bg-color-cffffff text-capitalize pl-15 pt-8 pb-8  fw-5 d-flex align-items-center">
            <FitContent
              value={focused.value.element.name || focused.value.element.type}
              onChange={handleElementNameChange}
              icon={{ color: 'inherit', width: '12px', class: 'middle' }}
            />
            {focused.value.element.type !== 'body' && focused.value.element.editable !== false && (
              <div onClick={deleteElement} className="ml-auto mr-12 cp">
                <SvgIcons.DeleteIcon
                  width={'13px'}
                  height={'13px'}
                  color={ColorSchemeCode.c4F4F4F}
                />
              </div>
            )}
          </div>
          {properties === 'styles' && (
            <div className="">
              <Styles state={state} element={focused.value.element} />
            </div>
          )}
          {properties === 'settings' && (
            <div className=" ">
              <Settings state={state} element={focused.value.element} />
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default RightPane
